import React, { Component } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input'
import it from 'react-phone-number-input/locale/it.json'
/*
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import it from 'react-phone-input-2/lang/it.json'
*/


export default class InputPhone extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var classValidation = '';
        var requiredClass = '';

        // Controlla la validità
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }

        // Verifica su required
        if (this.props.required) {
            requiredClass = 'nam_required';
        }

        // Verifica se mostrare l'help
        var showHelp = false;
        if (this.props.helpText != undefined && this.props.helpText !== '') {
            showHelp = true;
        }

        return (
            <div className="form-group row">
                <div className={"col-sm-12"}>
                    <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                    {showHelp && (
                        <div className="form-text clearfix" style={{ fontSize: '0.8em' }}>
                            {this.props.helpText}
                        </div>
                    )}

                    <div className="input-group">
                        <span className={"input-group-text"}>+39</span>
                        <input type="text"
                               name={this.props.name}
                               value={this.props.value||''}
                               onChange={this.props.handleChange}
                               data-testid="input"
                               className={'form-control ' + classValidation + ' ' + requiredClass}
                               readOnly={this.props.readOnly}
                               maxLength={this.props.maxLength}
                               placeholder={this.props.placeHolder} />
                        <div className="invalid-feedback" data-testid="message">
                            {this.props.invalid_message}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

