import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from './common/csrf-loader';
import AlertWarning from './common/AlertWarning';
import {Alert} from "react-bootstrap";


export default class AccountDetailsForm extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef()
        var title = this.props.config.title;
        var user = this.props.config.Email;
        var marketing = this.props.config.marketing;

        this.state = {
            user: user,
            title: title,
            token: token,
            marketing: marketing,
            showMessage: this.props.config.showMessage,
        };
        this.handleChange = this.handleChange.bind(this);


    }
    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {
        // Recuperare nome e valore dall'evento
        const target = event.target;
        const value = target.checked;
        const name = target.name;
        // Assegna il valore del campo
        this.setState({ marketing: value });
    }

    render() {

        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card text-center mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.state.title}</h5>
                            <br></br>
                            <div>
                                <form method="POST">
                                    <input type="hidden" name="_token" value={this.state.token} />

                                    <div className="card-text">

                                        <div className="row py-2">
                                            <div className="col-sm-6 text-left font-weight-bold">{this.props.config.label_account}</div>
                                            <div className="col-sm-6 text-left">{this.props.config.email}</div>
                                        </div>

                                        <div className="row py-2">
                                            <div className="col-sm-6 text-left font-weight-bold">{this.props.config.label_dataCreate}</div>
                                            <div className="col-sm-6 text-left">{this.props.config.dataCreate}</div>
                                        </div>

                                        <div className="row py-2">
                                            <div className="col-sm-6 text-left font-weight-bold">{this.props.config.label_dataLastLogin}</div>
                                            <div className="col-sm-6 text-left">{this.props.config.dataLastLogin}</div>
                                        </div>

                                        <div className="row py-2">
                                            <div className="col-sm-6 text-left font-weight-bold">{this.props.config.label_ipLastLogin}</div>
                                            <div className="col-sm-6 text-left">{this.props.config.ipLastLogin}</div>
                                        </div>
                                        <div className="row py-2">
                                            <div className="col-sm-6 text-left font-weight-bold">{this.props.config.label_marketing}</div>
                                            <div className="col-sm-6 text-left">
                                                <input id="marketing" value="1" name="marketing" onChange={this.handleChange} checked={this.state.marketing} type="checkbox" />

                                            </div>
                                        </div>

                                    </div>
                                    { (this.state.showMessage) && (
                                            <Alert key="alert" variant="success" dismissible={true} onClose={() => this.setState({showMessage:false})}>
                                                Consenso aggiornato
                                            </Alert>
                                        )
                                    }
                                    <div className="form-group">
                                        <button type="submit" name="action" value="sign" className="btn btn-primary btn-block mb-2 mt-5" >
                                            {this.props.config.label_update}
                                        </button>
                                    </div>

                                    <div className="form-group">
                                        <a href={this.props.config.url_privacy} target="_blank" >{this.props.config.label_dwl_privacy}</a>
                                    </div>
                                    <div className="form-group">
                                        <a href={this.props.config.url_home}>{this.props.config.label_home}</a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

if (document.getElementById('form_AD')) {
    ReactDOM.render(<AccountDetailsForm config={config} />, document.getElementById('form_AD'));
}
