import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import { checkValidEmailName } from '../common/email-validation';
import { isValidPassword } from '../common/strength-password';
import PasswordInput from '../common/PasswordInput';
import EmailInput from '../common/EmailInput';
import MessageEmailInUse from './MessageEmailInUse';
import PrivacyField from './PrivacyField';
import ReCAPTCHA from "react-google-recaptcha";


export default class RegisterForm extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        // Gestisce il messaggio di errore generico sollevato dalla validazione server side
        var show_generic_errors = true;
        if (Object.keys(this.props.errors).length === 0) {
            show_generic_errors = false;
        }

        var tokenCaptcha = '';
        if (this.props.captchaKey === '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI') {
            tokenCaptcha = 'fake_for_test';
        }

        this.state = {
            email: this.props.config.email,
            password: '',
            password_confirmation: '',
            privacy: false,
            captchaKey: this.props.captchaKey,
            showSpinnerCheck: false,
            showMessageEmailInUse: false,
            submitDisabled: true,
            popOverMessage: '',
            token: token,
            tokenCaptcha: tokenCaptcha,
            class_password_confirmation: 'is-invalid',
            show_generic_errors: show_generic_errors
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.hideMessageGenericErrors = this.hideMessageGenericErrors.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }

    handleChange(event) {
        // Se il messaggio è visibile nascondilo
        if (this.state.showMessageEmailInUse === true) {
            this.setState({ showMessageEmailInUse: false, });
        }

        // Recuperare nome e valore dall'evento
        const target = event.target;
        const value = target.name === 'privacy' ? target.checked : target.value;
        const name = target.name;

        // setState è ascincrono, quindi devo considerare il valore separatamente
        var myEmail = this.state.email;
        var myPassword = this.state.password;
        var mypassword_confirmation = this.state.password_confirmation;
        var myPrivacy = this.state.privacy;

        // Aggiorno il valore attualmente modificato
        if (name == "email") {
            myEmail = value;
        } else if (name == "password") {
            myPassword = value;
        } else if (name == "password_confirmation") {
            mypassword_confirmation = value;
        } else if (name == "privacy") {
            myPrivacy = value;
        }

        // Assegna il valore del campo
        this.setState({ [name]: value });

        if (!this.isSubmitButtonEnabled(myEmail, myPassword, mypassword_confirmation, myPrivacy, this.state.tokenCaptcha) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }
    }

    /**
     * Ritorna true se i valori email e password sono corretti
     *
     */
    isSubmitButtonEnabled(email, password, password_confirmation, privacy, tokenCaptcha) {
        // Email valida, tokenCaptcha non vuoto e passowrd valide
        if (checkValidEmailName(email)
            && tokenCaptcha != ''
            && privacy
            && isValidPassword(password,
                this.props.config.REGISTER_PAGE_PASSWORD_LENGTH_MIN,
                this.props.config.REGISTER_PAGE_PASSWORD_LENGTH_MAX
            )
            && (password === password_confirmation)) {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit(event) {
        //console.log('handleSubmit ' + this.state.popOverMessage);
        var email = this.state.email;
        var password = this.state.password;

        if (this.isSubmitButtonEnabled(
            this.state.email,
            this.state.password,
            this.state.password_confirmation,
            this.state.privacy,
            this.state.tokenCaptcha)
        ) {
            event.preventDefault();
            // Chiama il servizio per il controllo sulla disponibilità
            this.checkCredentials();
        } else {
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            event.preventDefault();
            return false;
        }
    }

    /**
     * Chiama il servizio per il controllo della disponibilità
     *
     */
    async checkCredentials() {
        // Mosra lo spinner
        this.setState({ showSpinnerCheckEmail: true, });

        try {
            const data = {
                email: this.state.email
            };

            // Chiama il servizio
            const response = await fetch(this.props.config.url_check_email_available, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw Error(response.statusText);
            }

            const json = await response.json();

            // Controlla il risultato
            if (json.result == 0) {
                // Le credenziali non sono valide, mostra il messaggio
                this.setState({ showMessageEmailInUse: true, });
            } else {
                // Invia i dati al server usando il form
                this.myForm.current.submit();
            }

        } catch (error) {
            console.log(error);
        }

        // Nascondi lo spinner
        this.setState({ showSpinnerCheckEmail: false, });
    }

    /**
     * Nasconde il messaggio di avvertimento credenziali non valide.
     * Il metodo è necessario per permettere di gestire il bottone di chiusura dentro il componente MessageEmailInUse
     *
     */
    hideMessage() {
        this.setState({ showMessageEmailInUse: false, });
    }

    hideMessageGenericErrors() {
        this.setState({ show_generic_errors: false, });
    }

    onChangeCaptcha(value) {
        if (value == null) {
            value = '';
        }

        this.setState({ tokenCaptcha: value, });

        if (!this.isSubmitButtonEnabled(
            this.state.email,
            this.state.password,
            this.state.password_confirmation,
            this.state.privacy, value
        ) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {

        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            {
                                (this.props.config.pec != '') ? (
                                    <h5 className="card-title">{this.props.config.crea_nuovo_account_rinnovo} <b>{this.props.config.pec}</b> {this.props.config.crea_nuovo_account_rinnovo_2}</h5>
                                ) : (
                                    <h5 className="card-title">{this.props.config.crea_nuovo_account}</h5>
                                )
                            }

                            {1==2 && <form action={this.props.config.url_register} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                <input type="hidden" name="_token" value={this.state.token} />
                                <input type="hidden" name="_token_captcha" value={this.state.tokenCaptcha} />

                                <MessageEmailInUse
                                    show={this.state.showMessageEmailInUse}
                                    hideMessage={this.hideMessage}
                                    config={this.props.config} />

                                {this.state.show_generic_errors && (
                                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        {
                                            Object.keys(this.props.errors).map(key => (
                                                this.props.errors[key].map(single_error => {
                                                    return <div className="pt-2">{single_error}</div>
                                                })
                                            ))
                                        }
                                        <button type="button" className="close" onClick={this.hideMessageGenericErrors} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )}

                                <EmailInput
                                    handleChange={this.handleChange}
                                    value={this.state.email}
                                    config={this.props.config}
                                    maxLength={this.props.config.MAIL_PEO_LENGTH_MAX}
                                />

                                <PasswordInput
                                    handleChange={this.handleChange}
                                    value={this.state.password}
                                    value2={this.state.password_confirmation}
                                    config={this.props.config}
                                    length_min={this.props.config.REGISTER_PAGE_PASSWORD_LENGTH_MIN}
                                    length_max={this.props.config.REGISTER_PAGE_PASSWORD_LENGTH_MAX}
                                />

                                <PrivacyField
                                    value={this.state.privacy}
                                    handleChange={this.handleChange}
                                    url={this.props.config.url_pdf_privacy}
                                    config={this.props.config}
                                />

                                <ReCAPTCHA
                                    sitekey={this.state.captchaKey}
                                    onChange={this.onChangeCaptcha}
                                    hl={this.props.config.locale}
                                />

                                <div className="form-group py-4">
                                    <button type="submit" id="popover-submit"
                                        className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-animation="true"
                                        data-content={this.state.popOverMessage}
                                        data-trigger="hover"
                                        data-template={this.getPopoverCustomTemplate()}
                                        tabIndex="3">
                                        {this.props.config.registrati}
                                    </button>
                                </div>

                                <div className="text-center">
                                    <div className="mt-3 mb-2">{this.props.config.sei_gia_registrato}</div>
                                    <p className="mb-3"><a className="view_link" href={this.props.config.url_login}>{this.props.config.accedi_adesso}</a></p>
                                </div>
                            </form>}
                            <hr></hr>
                            <p>Registra il tuo account <b><a href={this.props.config.url_auth_servicedesk} target="_blank">MyNamirial</a></b> e potrai accedere a tutti i servizi Namirial con un unico account!</p>
                            <p><u>Non dovrai più memorizzare una password diversa per ogni portale.</u></p>
                            <hr></hr>
                            <a href={this.props.config.url_auth_register} className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg">{this.props.config.registrati_con_mynamirial}</a>
                            <hr></hr>
                            <div className="text-center">
                                <div className="mt-3 mb-2">{this.props.config.sei_gia_registrato}</div>
                                <p className="mb-3"><a className="view_link" href={this.props.config.url_login}>{this.props.config.accedi_adesso}</a></p>
                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

if (document.getElementById('form_register')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<RegisterForm config={config} captchaKey={captchaKey} errors={errors} />, document.getElementById('form_register'));
}
