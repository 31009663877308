import React, { Component } from 'react';
import InputText from '../common/InputText';

/**
 * Presenta una lista di province ricevute via chiamata http a partire da un codice regione
 * in un tag select/option oppure un campo input/text se la risposta al servizio
 * non ritorna alcun valore.
 */
export default class SelectProvince extends Component   {
    constructor(props) {
        super(props);
        this.state = {
            provinces: [],
        };
        this.countryInUse = this.props.valueCountry;
    }

    componentDidMount() {
        // Chiama il servizio rest e assegna i dati allo stato
        this.loadProvinces();
    }

    componentDidUpdate(){
        if(this.props.valueCountry != this.countryInUse){
            this.loadProvinces();
            this.countryInUse = this.props.valueCountry;
        }
    }

    async loadProvinces(){
        try {
            if(this.props.valueCountry == '' || this.props.valueCountry === null){
                this.setState({ provinces: [] });
            } else {
                const response = await fetch('/api/list_provinces/' + this.props.valueCountry);
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const json = await response.json();
                this.setState({ provinces: json });
            }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        if (this.props.readOnly) {
            return (
                <React.Fragment>
                    <input type="hidden" name={this.props.name} value={this.props.value} />
                    <InputText
                        label={this.props.label}
                        value={this.props.value}
                        readOnly={true}
                        required={true}
                    />
                </React.Fragment>                
            )
        }        
        // Controlla la validità
        var classValidation = '';
        // cambia la classe di validazione

        // console.log("controlla valore per " + this.props.name + " messaggio invalido " + this.props.invalid_message);

        if(this.props.invalid_message != undefined && this.props.invalid_message !== ''){
            classValidation = 'is-invalid';
        }

        var requiredClass = '';
        if(this.props.required){
            requiredClass = 'nam_required';
        }

        let arrayOfData = this.state.provinces;
        let options = arrayOfData.map((data) =>
                <option key={data.id}  value={data.id}>
                    {data.name}
                </option>
            );
        if(arrayOfData.length != 0){
            return (
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                        <select
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            className={'custom-select ' + classValidation + ' ' + requiredClass}>
                                <option value=''>{this.props.not_selected}</option>
                                {options}
                        </select>
                        <div className="invalid-feedback">
                            {this.props.invalid_message}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                        <input type="text"
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            className={'form-control ' + classValidation + ' ' + requiredClass}
                            />
                        <div className="invalid-feedback">
                            {this.props.invalid_message}
                        </div>
                    </div>
                </div>
            );
        }

    }
}
