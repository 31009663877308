import React, { Component } from 'react';
import InputText from "../common/InputText";


/**
 * Genera una lista di tag OPTION con i valori presi dal servizio
 *
 */
export default class SelectClasseCom extends Component   {

    constructor(props) {
        super(props);
        this.typeInUse = 0;
        this.state={
            segments:[]
        }
    }

    /**
     * Metodo chiamato dopo il render
     *
     * Carica i prodotti e modifica lo stato
     *
     */
    componentDidMount() {
        // Chiama il servizio rest e assegna i dati allo stato
        this.loadAvailableSegments();
    }
    componentDidUpdate() {
        if (this.props.owner_type != this.typeInUse) {
            this.loadAvailableSegments();
            this.typeInUse = this.props.owner_type;
        }
    }

      /**
       * Carica dal servizio rest i domini disponibili e modifica lo stato del componente per modificare i valori della select
       */
      async loadAvailableSegments(){

        try {
            const response = await fetch(this.props.url_service + '/' + this.props.owner_type + '?convention_id=' + this.props.convention_id);
            if (!response.ok) {
                throw Error(response.statusText);
            }

            const json = await response.json();
            this.setState({ segments: json });
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let classValidation = '';
        let requiredClass = '';

        // Controlla la validità
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }

        // Verifica su required
        if (this.props.required) {
            requiredClass = 'nam_required';
        }
        let arrayOfData = this.state.segments
        if (!this.props.dataModify) {
            let options = arrayOfData.map((data) =>
                    <option key={data.id} value={data.code}  selected={data.name===this.props.default}>
                        {data.name}
                    </option>
            );
            return (
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor="{this.props.name}" className="col-form-label font-weight-bold {classValidation}">{this.props.label}</label>
                        <select
                            name={this.props.name}
                            onChange={this.props.handleChange}
                            className="custom-select">
                            {options}
                        </select>
                        <div className="invalid-feedback" data-testid="message">
                            {this.props.invalid_message}
                        </div>
                    </div>
                </div>
            )
        }
        else if (this.props.dataModify) {
            return (
                <input type="hidden" name={this.props.name} value={this.props.value} />
            )
        }
        else {
            return (
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor="{this.props.name}" className="col-form-label font-weight-bold {classValidation}">{this.props.label}</label>
                        <div id={this.props.name}>
                            <SpinnerLoading show={true}/>
                        </div>
                    </div>
                </div>
            )
        }
    }

} // Fine classe  OptionsDomain
/**
 * Mostra uno spinner boostrap o non mostra niente in base al valore di <i>props.show</i>
 *
 */
class SpinnerLoading extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.show === true) {
            return (
                <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
            );
        } else {
            return null;
        }
    }
} // Fine classe SpinnerCheckEmail

