
let re_minuscola = /[a-z]/;
let re_maiuscola = /[A-Z]/;
let re_numeri = /[0-9]/;

let re_special = /[!#@$%^&*)(+=._-]/i;
let re_special_all = /^[0-9a-zA-Z!#@$%^&\*\)\(+=._-]+$/;

// Regola deve avere un numero
const hasNumber = value => {
    return new RegExp(re_numeri).test(value);
}

// Regola deve avere maiuscola e minuscola
const hasMixed = value => {
    return new RegExp(re_minuscola).test(value) && new RegExp(re_maiuscola).test(value);
}

// Regola contiene almeno un carattere speciale
const hasSpecial = value => {
    return new RegExp(re_special).test(value);
}

const hasOnlyValid = value => {
    return new RegExp(re_special_all).test(value);
}

// Tutte le regole e controllo lunghezza
function isValidPassword(value, min, max) {
    return value.length >= min
        && value.length <= max
        && hasNumber(value)
        && hasMixed(value)
        && hasSpecial(value)
        && hasOnlyValid(value);
}


export { isValidPassword };
