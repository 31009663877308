import React, { Component } from 'react';

export default class SwitchService extends Component {
    constructor(props) {
        super(props);

        this.codice_articolo = this.props.codice_articolo;
        this.service_key = this.props.service_key;
        this.readOnly = this.props.readonly;
    }

    render() {
        // Controlla la validità
        var classValidation = '';

        // cambia la classe di validazione  {"error_type":"required_service","error_message":"Il campo è obbligatorio."}
        let invalid_message = '';
        if ("error_type" in this.props.error_messages) {
            classValidation = 'is-invalid';
            invalid_message = this.props.error_messages.error_message;
        }

        var requiredClass = '';
        if (this.props.required) {
            requiredClass = 'nam_required';
        }

        let fragment_quantita;
        let fragment_prezzo;
        let fragment_total_price;

        if (this.props.tipologia === 'ATTIVABILE') {
            fragment_quantita = (
                <div>{this.props.quantita}</div>
            );
            fragment_prezzo = (
                <div>{this.props.prezzo} &euro;</div>
            );
            let total_price = this.props.service_key in this.props.total_price ? this.props.total_price[this.props.service_key] : 0;
            fragment_total_price = (
                <div>{total_price} &euro;</div>
            );
        } else {
            fragment_quantita = (
                <div>&nbsp;</div>
            );
            fragment_prezzo = (
                <div>&nbsp;</div>
            );
            fragment_total_price = (
                <div>&nbsp;</div>
            );
        }

        return (
            <div className="form-row py-2 align-items-center">
                <div className="col-sm-3 text-right">
                    <div className="custom-control custom-switch">
                        <input
                            id={this.props.service_key}
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            type="checkbox"
                            className={'custom-control-input ' + classValidation + ' ' + requiredClass}
                            checked={this.props.checked}
                            disabled={this.props.disabled}
                        />
                        <label className="custom-control-label col-form-label-sm" htmlFor={this.service_key}></label>
                        <div className="invalid-feedback">
                            {invalid_message}
                        </div>
                    </div>
                </div>
                <div className="col ml-2">
                    <label htmlFor={this.service_key} className="mb-0">{this.props.label}</label>
                    <small id={this.props.name + '_Help'} className="form-text text-muted">{this.props.helpText}</small>
                </div>
                <div className="col-sm-2">
                    {fragment_total_price}
                </div>
            </div>
        );

    }
}
