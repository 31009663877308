import React , { Component } from 'react';


export default class InputText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: this.props.value??"+39",
            showError: true  // Stato per mostrare/nascondere il messaggio di errore
        };
        this.handlePhoneChange = this.handlePhoneChange.bind(this); // Binding di this
    }
    // handlePhoneChange____(event) {
    //     // Rimuovi tutto tranne i numeri, mantenendo il limite di 10 cifre dopo il prefisso
    //     let value = event.target.value.replace(/[^0-9]/g, '');

    //     // Rimuovi +39 se già incluso nell'input, per evitare di duplicarlo
    //     if (value.startsWith("39")) {
    //         value = value.slice(2);  // Rimuovi "39" se presente
    //     }
    //     // Limita la lunghezza delle cifre dopo il prefisso a 10
    //     if (value.length <= 10) {
    //         this.setState({ phone: value });
    //         this.setState({ showError: value.length === 0 }); // Nasconde l'errore quando il numero non è vuoto
    //     }
    // }
    handlePhoneChange = (event) => {
        let value = event.target.value;

        // Assicurati che il prefisso sia sempre "+39 "
        if (!value.startsWith("+39")) {
            value = "+39";
        }

        // Rimuovi tutto tranne i numeri dopo il prefisso e limita la lunghezza a 10 cifre
        const numberOnly = value.slice(3).replace(/[^0-9]/g, ''); // estrae solo le cifre dopo +39
        if (numberOnly.length <= 10) {
            this.setState({ phone: `+39${numberOnly}` });
            this.setState({ showError: value.length === 0 }); // Nasconde l'errore quando il numero non è vuoto
        }
    };

    render() {
        var classValidation = '';
        var requiredClass = '';
        var fieldType = 'text';
        var forceUpper = '';

        // Controlla la validità
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }
        if (this.props.forceUpper) {
            forceUpper = 'to-upper';
        }

        // Verifica su required
        if (this.props.required) {
            requiredClass = 'nam_required';
        }
        if (this.props.fieldType != undefined  && this.props.fieldType !=='') {
            fieldType=this.props.fieldType
        }

        // Verifica se mostrare l'help
        var showHelp = false;
        if (this.props.helpText != undefined && this.props.helpText !== '') {
            showHelp = true;
        }

        // Verifica se nascondere anche la classe di invalid value
        if (this.state.showError === false) {
            classValidation = '';
        }

        // // Verifica se nascondere anche la classe di invalid value
        // if (this.state.phone != undefined && this.state.phone !== '' && this.state.phone.startsWith('+39')) {
        //     this.setState({ phone: this.state.phone.substring(3)});
        // }


        return (
            <div className="form-group row">
                <div className="col-sm-12">
                    <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                    <input type={fieldType}
                        name={this.props.name}
                        // value={this.props.value||''}
                        // value={this.state.phone ? `+39${this.state.phone}` : "+39"}
                        value={this.state.phone}
                        onChange={this.handlePhoneChange}
                        data-testid="input"
                        className={'form-control ' + classValidation + ' ' + requiredClass + ' ' + forceUpper}
                        readOnly={this.props.readOnly}
                        maxLength={this.props.maxLength}
                        placeholder={this.props.placeHolder}
                    />
                    {showHelp && (
                        <div className="form-text" style={{ fontSize: '0.8em' }}>
                            {this.props.helpText}
                        </div>
                    )}
                    {/* <div className="invalid-feedback" data-testid="message">
                        {this.props.invalid_message}
                    </div> */}

                    {/* Condizione per mostrare il messaggio di errore */}
                    {this.state.showError && (
                    <div className="invalid-feedback" data-testid="message">
                        {this.props.invalid_message}
                    </div>
                    )}
                </div>
            </div>
        );
    }
}

