import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { formPost } from './common/form-post-utility';
import { csrfToken } from './common/csrf-loader';
import LoadUrlDescription from './common/LoadUrlDescription';

export default class ListProductsInLanding extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            inLoading: true,
        }
    }

    /**
     * Metodo chiamato dopo il render
     *
     * Carica i prodotti e modifica lo stato
     *
     */
    componentDidMount() {
        /* fetch API in action */
        fetch(this.props.config.products_in_landing_page)
            .then(response => {
                return response.json();
            })
            .then(products => {
                //Fetched product is stored in the state
                this.setState({
                    products: products,
                    inLoading: false,
                });
            });
    }

    renderProducts() {

        // Dove memorizzare il cotenuto da renderizzare
        let content = [];

        // Calcola il numero di linee
        let numProductsPerLine = 3;

        if (this.state.inLoading === true) {
            // Mostra lo spinner
            return (
                <div className="text-center">
                    <div className="spinner-border" role="status"></div>
                    <div>{this.props.config.caricamento_prodotti}</div>
                </div>
            );
        } else if (this.state.products.length == 0) {
            // Se non ci sono prodotti mostrare un messaggio
            return <div>{this.props.config.nessun_prodotto_trovato}</div>;
        } else {
            // Visualizzazione prodotti
            return (
                <div className="row justify-content-center" key='1'>
                    {this.state.products.map(prodottoInLinea => {
                        return (
                            <div className="col-lg landing_product_container" key={prodottoInLinea.id}>
                                <ProductLanding url_select_product={this.props.config.url_select_product} product={prodottoInLinea} config={this.props.config} />
                            </div>
                        );
                    })}
                </div>
            );
        }



    } // Fine render products

    render() {
        return (
            <React.Fragment>
                {this.renderProducts()}
            </React.Fragment>
        );
    }

}

class ProductLanding extends Component {

    constructor(props) {
        super(props);
    }


    render() {


        return (
            <React.Fragment>
                <div className="landing_product_title">{this.props.product.name}</div>
                <div className="landing_product_body">
                    {/* Aggiunta del componente descrizione */}
                    <LoadUrlDescription url={this.props.product.url_description} />
                </div>
                <div className={`landing_product_button ${this.props.config.cid ? "landing_product_button_" + this.props.config.cid : ""}`}>
                    {/* Aggiunta del componente bottone */}
                    <BuyProductButton
                        url_select_product={this.props.url_select_product}
                        id_product={this.props.product.id}
                        base_name = {this.props.product.base_name}
                        config={this.props.config} />
                </div>

            </React.Fragment>
        );
    }

}

class BuyProductButton extends Component {

    /**
     * Propos deve contenere l'attributo url (props.url)
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.selectProduct = this.selectProduct.bind(this);
        this.product_url = "";
    }


    selectProduct(event) {
        // Recupera il token per l'invio della form
        const token = csrfToken();
        formPost(this.props.url_select_product,
            {
                'id_product': this.props.id_product,
                'id_convenzione': this.props.config.cid,
                'codice_attivazione_convenzione': this.props.config.cc,
                '_token': token
            }
        );
    }

    render() {
        // Preparato per un'evenutale selezione del prodotto in GET
        // Da decidere in base alle politiche SEO
        // let urlBuy = "/select/" + this.props.id_product ;

        // verifico se nella collection dei prodotti base con coupon esiste il product_id
        let coupon_name = "";
        let result = [];
        if(this.props.config.products_with_coupon) {
            let products_coupon = this.props.config.products_with_coupon;
            if (products_coupon.length > 0) {
                const result = products_coupon.find(({ product_id }) => product_id === this.props.id_product);
                if (result) coupon_name = result.coupon_name;
            }
        }
        if (result && coupon_name && this.props.base_name) {
            this.product_url = "/product/"+coupon_name+"/"+this.props.base_name.toLowerCase()
        }

        return (
            <React.Fragment>
                {/*
                    se il prodotto ha un coupon di base associato, uso quello per comporre l'indirizzo di pagina specifico
                    In caso contrario, mantengo la navigazione standard
                 */}
                {"" != this.product_url ?
                    <a href={this.product_url} className="btn btn-primary btn-lg" role="button" aria-pressed="true">{config.acquista}</a>
                :
                    <a href="#" onClick={this.selectProduct} className="btn btn-primary btn-lg" role="button" aria-pressed="true">{config.acquista}</a>
                }

            </React.Fragment>
        );
    }

}

// Crea un componente a sostituzione di un div
if (document.getElementById('list_products')) {
    ReactDOM.render(<ListProductsInLanding config={config} />, document.getElementById('list_products'));
}
