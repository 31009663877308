import React, { Component } from 'react';
import SelectDate from '../common/SelectDate';
import InputText from '../common/InputText';
import InputPhone from '../common/InputPhone';
import SelectCitySayt from "../common/SelectCitySayt";

export default class DetailsRdc extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OWNERTYPE_PRIVATECITIZEN: "PrivateCitizen",
            OWNERTYPE_FREELANCE: "Freelance",
            OWNERTYPE_BUSINESS: "Business",
            OWNERTYPE_NONCOMMERCIALENTITIES: "NonCommercialEntities",
            OWNERTYPE_PA: "PA",
        };

        // console.log("formType: DetailsRdc");
    }

    render() {

        return (
            <React.Fragment>
                {
                    (this.props.clientTypeId === this.state.OWNERTYPE_BUSINESS
                        || this.props.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                        || this.props.clientTypeId === this.state.OWNERTYPE_PA
                    )
                    && (

                        <React.Fragment>

                            {/* I dati del RdC sono richiesti se diversi da quelli del titolare della casella */}
                            {(this.props.detailsCons.sameAsOwner == 0) && (
                                <React.Fragment>

                                <div className="form-group">
                                    <InputText
                                        value={this.props.detailsRdC.denomination}
                                        name="detailsRdC_denomination"
                                        handleChange={this.props.handleChange}
                                        label={this.props.config.dettaglio_ragione_sociale}
                                        maxLength={this.props.config.BUSINESSNAME_MAX_LENGTH}
                                        invalid_message={this.props.errorMessages.detailsRdC_denomination}
                                        required={true}
                                        readOnly={this.props.dataModify}
                                    />
                                </div>

                                <div className="form-group">
                                    <InputText
                                        value={this.props.detailsRdC.vatNumber}
                                        name="detailsRdC_vatNumber"
                                        handleChange={this.props.handleChange}
                                        label={this.props.config.dettaglio_partita_iva}
                                        maxLength={this.props.config.VATNUMBER_MAX_LENGTH}
                                        invalid_message={this.props.errorMessages.detailsRdC_vatNumber}
                                        required={true}
                                        readOnly={this.props.dataModify}
                                    />
                                </div>

                                <h5>{this.props.config.rappresentante_legale}</h5>

                                <div className="form-group">
                                    <InputText
                                        value={this.props.detailsRdC.name}
                                        name="detailsRdC_name"
                                        handleChange={this.props.handleChange}
                                        label={this.props.config.dettaglio_nome}
                                        invalid_message={this.props.errorMessages.detailsRdC_name}
                                        required={true}
                                        readOnly={this.props.dataModify}
                                    />
                                </div>

                                <div className="form-group">
                                    <InputText
                                        value={this.props.detailsRdC.surname}
                                        name="detailsRdC_surname"
                                        handleChange={this.props.handleChange}
                                        label={this.props.config.dettaglio_cognome}
                                        invalid_message={this.props.errorMessages.detailsRdC_surname}
                                        required={true}
                                        readOnly={this.props.dataModify}
                                    />
                                </div>

                                <div className="form-group">
                                    <InputText
                                        value={this.props.detailsRdC.taxCode}
                                        name="detailsRdC_taxCode"
                                        handleChange={this.props.handleChange}
                                        label={this.props.config.dettaglio_codice_fiscale}
                                        maxLength={this.props.config.TAXCODE_MAX_LENGTH}
                                        invalid_message={this.props.errorMessages.detailsRdC_taxCode}
                                        required={true}
                                        readOnly={this.props.dataModify}
                                    />
                                </div>

                                <SelectCitySayt
                                    value={this.props.detailsRdC.city}
                                    name="detailsRdC_city"
                                    label={this.props.config.dettaglio_luogo_residenza}
                                    valueProvince={this.props.detailsRdC.province}
                                    nameProvince="detailsRdC_province"
                                    labelProvince={this.props.config.provincia}
                                    handleChange={this.props.handleChange}
                                    not_selected={this.props.config.selezionare_citta}
                                    invalid_message={this.props.errorMessages.detailsRdC_city}
                                    required={true}
                                    hide_province={true}
                                />

                                <InputText
                                    value={this.props.detailsRdC.zip}
                                    name="detailsRdC_zip"
                                    handleChange={this.props.handleChange}
                                    label={this.props.config.dettaglio_zip_residenza}
                                    invalid_message={this.props.errorMessages.detailsRdC_zip}
                                    maxLength={this.props.config.ZIP_MAX_LENGTH}
                                    required={true}
                                />

                                <InputText
                                    value={this.props.detailsRdC.street}
                                    name="detailsRdC_street"
                                    handleChange={this.props.handleChange}
                                    label={this.props.config.dettaglio_indirizzo_residenza}
                                    invalid_message={this.props.errorMessages.detailsRdC_street}
                                    maxLength={this.props.config.STREET_MAX_LENGTH}
                                    required={true}
                                />

                                <div className="form-group">
                                    <InputText
                                        value={this.props.detailsRdC.email}
                                        name="detailsRdC_email"
                                        handleChange={this.props.handleChange}
                                        label={this.props.config.dettaglio_email}
                                        maxLength={this.props.config.PEO_MAX_LENGTH}
                                        invalid_message={this.props.errorMessages.detailsRdC_email}
                                    />

                                    <InputPhone
                                        value={this.props.detailsPec.mobileNumber || '+39'}
                                        name="detailsPec_mobileNumber"
                                        handleChange={this.props.handleChange}
                                        label={this.props.config.dettaglio_cellulare}
                                        placeHolder={this.props.config.phone_number_no_prefix}
                                        invalid_message={this.props.errorMessages.detailsPec_mobileNumber}
                                        maxLength={this.props.config.CELL_MAX_LENGTH}
                                        helpText={this.props.config.dettaglio_cellulare_help}
                                        required={true}
                                        onlyMobile={true}
                                    />
                                </div>

                                {/** Pec da attivare, si compila in automatico server side
                                <div className="form-group">
                                    <InputText
                                        value={this.props.detailsRdC.pec}
                                        name="detailsRdC_pec"
                                        handleChange={this.props.handleChange}
                                        label='Indirizzo PEC'
                                        readOnly="true"
                                        maxLength={this.props.config.PEC_MAX_LENGTH}
                                        invalid_message={this.props.errorMessages.detailsRdC_pec}
                                    />
                                </div>
                                */}

                                </React.Fragment>
                            )}

                            {/* I dati relativi a data e luogo di nascita del RdC sono richiesti sempre */}
                            <SelectDate
                                name="detailsRdC_birthDate"
                                selected={this.props.detailsRdC.birthDate}
                                onChange={this.props.handleDataNascitaRdcChange}
                                label={this.props.config.dettaglio_data_nascita}
                                invalid_message={this.props.errorMessages.detailsRdC_birthDate}
                                required={true}
                                readOnly={true}
                            />

                            <SelectCitySayt
                                name="detailsRdC_birthCity"
                                value={this.props.detailsRdC.birthCity}
                                label={this.props.config.dettaglio_luogo_nascita}
                                valueProvince={this.props.detailsRdC.birthProvince}
                                nameProvince="detailsRdC_birthProvince"
                                labelProvince={this.props.config.dettaglio_provincia_nascita}
                                handleChange={this.props.handleChange}
                                not_selected={this.props.config.selezionare_citta}
                                invalid_message={this.props.errorMessages.detailsCons_birthCity}
                                required={true}
                                hide_province={true}
                            />

                            <input type={"hidden"} name={"detailsRdC_birthZip"} value={this.props.detailsRdC.birthZip} />

                        </React.Fragment>
                    )
                }

            </React.Fragment>
        );
    }
}
