import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import InputText from '../common/InputText';
import InputTextMobile from '../common/InputTextMobile';
import Document from './Document';
import DetailsPecPrivateFreelance from './DetailsPecPrivateFreelance';
import DetailsPecBusiness from './DetailsPecBusiness';
import DetailsCons from './DetailsCons';
import DetailsRdc from './DetailsRdc';
import OwnerTypeField from './OwnerTypeField';
import SelectRdcType from '../common/SelectRdcType';
import { buildMessagesFromStructure } from '../common/validation_buildErrorMessages';
import CodiceFiscale from "codice-fiscale-js";

export default class FormOwner extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        // Crea l'elenco degli errori
        // Controllo degli errori ricevuti dal server e assegnamo i messaggi di errore
        //var errorMessages = buildMessages(this.props.errors, this.props.config);

        // Crea l'elenco degli errori
        var errorMessages = buildMessagesFromStructure(this.props.errors);

        if (this.props.detailsCons) {
            if (this.props.detailsCons.birthDate !== null && this.props.detailsCons.birthDate != '') {
                const dateParts = (this.props.detailsCons.birthDate + "").split("/");
                this.props.detailsCons.birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
        }
        if (this.props.detailsRdC) {
            if (this.props.detailsRdC.birthDate !== null && this.props.detailsRdC.birthDate != '') {
                const dateParts = (this.props.detailsRdC.birthDate + "").split("/");
                this.props.detailsRdC.birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
        }

        if (this.props.documentPec) {
            if (this.props.documentPec.issueDate != null && this.props.documentPec.issueDate != '') {
                const dateParts = (this.props.documentPec.issueDate + "").split("/");
                this.props.documentPec.issueDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
            if (this.props.documentPec.expiryDate != null && this.props.documentPec.expiryDate != '') {
                const dateParts = (this.props.documentPec.expiryDate + "").split("/");
                this.props.documentPec.expiryDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
        }

        // Indica se il dato è in modifica o creazione
        var dataModify = false;
        if (this.props.detailsPec.idOwner != 0) {
            dataModify = true;
        }
        var spidmail=false
        if (this.props.config.spidmail) {
            spidmail=true
        }
        // console.log("Prehandle",this.props.detailsPec)
        this.handleTaxCode(this.props.detailsPec.taxCode, this.props.detailsPec)
        this.state = {
            detailsPec: this.props.detailsPec,
            detailsCons: this.props.detailsCons ? this.props.detailsCons : {},
            detailsRdC: this.props.detailsRdC ? this.props.detailsRdC : {},
            document: this.props.documentPec ? this.props.documentPec : {},
            token: token,
            errorMessages: errorMessages,
            dataModify: dataModify,
            spidmail: spidmail,

            OWNERTYPE_PRIVATECITIZEN: "PrivateCitizen",
            OWNERTYPE_FREELANCE: "Freelance",
            OWNERTYPE_BUSINESS: "Business",
            OWNERTYPE_NONCOMMERCIALENTITIES: "NonCommercialEntities",
            OWNERTYPE_PA: "PA",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);

        // detailsPec
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
        // detailsCons
        this.handleBirthDateConsChange = this.handleBirthDateConsChange.bind(this);
        // detailsRdC
        this.handleBirthDateRdcChange = this.handleBirthDateRdcChange.bind(this);

        this.handleIssueDateChange = this.handleIssueDatedChange.bind(this);
        this.handleExpiryDateChange = this.handleExpiryDateChange.bind(this);
        let event={
            target: {
                name: 'detailsPec_taxCode',
                value: this.props.detailsPec.taxCode
            }

        }
    }

    /**
     * Assegna il valore del componente per la data di nascita in detailsPec
     *
     * @param {*} date
     */
    handleBirthDateChange(date) {
        const { detailsPec, errorMessages } = { ...this.state };
        detailsPec.birthDate = date;
        errorMessages.detailsPec_birthDate = '';
        this.setState({ detailsPec: detailsPec, errorMessages: errorMessages });
    }

    handleBirthDateConsChange(date) {
        const { detailsCons, errorMessages } = { ...this.state };
        detailsCons.birthDate = date;
        errorMessages.detailsCons_birthDate = '';
        this.setState({ detailsCons: detailsCons, errorMessages: errorMessages });
    }

    handleBirthDateRdcChange(date) {
        const { detailsRdC, errorMessages } = { ...this.state };
        detailsRdC.birthDate = date;
        errorMessages.detailsRdC_birthDate = '';
        this.setState({ detailsRdC: detailsRdC, errorMessages: errorMessages });
    }

    handleIssueDatedChange(date) {
        const { document, errorMessages } = { ...this.state };
        document.issueDate = date;
        errorMessages.document_issueDate = '';
        this.setState({ document: document, errorMessages: errorMessages });
    }

    handleExpiryDateChange(date) {
        const { document, errorMessages } = { ...this.state };
        document.expiryDate = date;
        errorMessages.document_expiryDate = '';
        this.setState({ document: document, errorMessages: errorMessages });
    }
    handleTaxCode(value,details) {
        try {
            const cf = new CodiceFiscale(value)
            details['taxCode']=value.toString().toUpperCase()
            details['gender'] = cf.gender
            details['birthCity'] = cf.birthplace.nome
            details['birthDate'] = cf.birthday
            details['birthProvince'] = cf.birthplace.prov || 'EE'
            details['birthRegion'] = 99
            details['birthZip'] = 99999
        }
        catch (e) {
            console.log(e.toString())
        }
    }
    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {

        // Recuper nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;

        //console.log("cambiato valore per " + name);

        // Assegna i valori per i sotto oggetti.
        // React non permette l'assegnamento a propietà di sotto oggetti
        // si deve clonare l'oggetto, modificare il valore e assegnare l'intero oggetto
        if (name.startsWith("detailsCons")) {
            // Assegna i valori all'oggetto detailsCons
            var varName = name.substring(12);

            const { detailsCons, errorMessages } = { ...this.state };
            detailsCons[varName] = value;
            errorMessages[name] = '';

            if (varName === 'birthRegion') {
                detailsCons['birthProvince'] = '';
                detailsCons['birthCity'] = '';
            } else if (varName === 'birthProvince') {
                detailsCons['birthCity'] = '';
            }

            this.setState({ detailsCons: detailsCons, errorMessages: errorMessages });

        } else if (name.startsWith("detailsPec")) {
            // Assegna i valori all'oggetto detailsPec
            var varName = name.substring(11);

            // Assegna il valore
            const { detailsPec, errorMessages } = { ...this.state };
            detailsPec[varName] = value;
            errorMessages[name] = '';
            if (varName==='taxCode' && value.length===16)  {
                this.handleTaxCode(value,detailsPec)
            }


            // Se sto inserendo il codice fiscale e sono arrivato alla 16 cifra (Ma se ho codice straniero? uso onBlur) allora carica
            /* if (varName === 'taxCode') { if (value.length == 16) { console.log("CHIAMA IL SERVIZIO GetInformationCli"); } }*/

            this.setState({ detailsPec: detailsPec, errorMessages: errorMessages });
        } else if (name.startsWith("detailsRdC")) {
            var varName = name.substring(11);

            //console.log("cambiato valore per " + varName + ' ' + value);

            const { detailsRdC, errorMessages } = { ...this.state };
            detailsRdC[varName] = value;
            errorMessages[name] = '';

            if (varName === 'birthRegion') {
                detailsRdC['birthProvince'] = '';
                detailsRdC['birthCity'] = '';
            } else if (varName === 'birthProvince') {
                detailsRdC['birthCity'] = '';
            }

            if (varName === 'region') {
                detailsRdC['province'] = '';
                detailsRdC['city'] = '';
            } else if (varName === 'province') {
                detailsRdC['city'] = '';
            }

            this.setState({ detailsRdC: detailsRdC, errorMessages: errorMessages });

        } else if (name.startsWith("document")) {
            var varName = name.substring(9);

            const { document, errorMessages } = { ...this.state };
            document[varName] = value;
            errorMessages[name] = '';


            this.setState({ detailsRdC: document, errorMessages: errorMessages });
        } else {
            this.setState({ [name]: value });
        }
    }

    /**
     *
     *
     * @param {*} event
     */
    handleFocus(event) {
        //console.log("preso il focus");
    }

    handleSubmit(event) {
        // Applica la validazione
        //this.validationName();
        //event.preventDefault();

        // Invia la form
        this.myForm.current.submit();
    }

    render() {
        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <div className="container">
                                <h5 className="card-title">{this.props.config.dati_titolare_casella_pec}</h5>

                                <div className="alert alert-success py-2" role="alert">
                                    <p className="pt-2">
                                        Inserisci con attenzione i dati dell'intestatario della casella, verranno riportati nel contratto di attivazione.
                                    </p>
                                </div>

                                <form action={this.props.config.url_owner_save} method="post" className="" onSubmit={this.handleSubmit} ref={this.myForm}>
                                    <input type="hidden" name="_token" value={this.state.token} />

                                    <OwnerTypeField
                                        dataModify={this.state.dataModify}
                                        value={this.state.detailsPec.clientTypeId}
                                        handleChange={this.handleChange}
                                        label={this.props.config.forma_giuridica}
                                        config={this.props.config} />

                                    {/* Titolare della casella non privato */}
                                    {
                                        (this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_FREELANCE
                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_BUSINESS
                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_PA)
                                        && (
                                            <React.Fragment>
                                                <DetailsPecBusiness
                                                    detailsPec={this.state.detailsPec}
                                                    handleChange={this.handleChange}
                                                    errorMessages={this.state.errorMessages}
                                                    config={this.props.config}
                                                />
                                            </React.Fragment>
                                        )
                                    }

                                    {/* Titolare della casella privato o libero professionista */}
                                    {
                                        (this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_PRIVATECITIZEN
                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_FREELANCE)
                                        && (
                                            <React.Fragment>
                                                <DetailsPecPrivateFreelance
                                                    detailsPec={this.state.detailsPec}
                                                    handleChange={this.handleChange}
                                                    handleBirthDateChange={this.handleBirthDateChange}
                                                    errorMessages={this.state.errorMessages}
                                                    config={this.props.config}
                                                    spidmail={this.state.spidmail}
                                                />
                                            </React.Fragment>
                                        )
                                    }

                                    {/* Responsabile della conservazione per utenti non privati */}
                                    {
                                        (this.props.config.conservationActive === true
                                            &&
                                            (this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_FREELANCE
                                                || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_BUSINESS
                                                || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                                                || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_PA)
                                        ) ?
                                            (
                                                <React.Fragment>

                                                    <h4>{this.props.config.dettaglio_extd_responsabile_della_conservazione}</h4>


                                                    <SelectRdcType
                                                        value={this.state.detailsCons.sameAsOwner}
                                                        handleChange={this.handleChange}
                                                        label={this.props.config.dettaglio_extd_rdc_il_responsabile_della_conservazione}
                                                        invalid_message={this.state.errorMessages.detailsCons_sameAsOwner}
                                                        required={true}
                                                        config={this.props.config}
                                                    />

                                                    {/* I dati Cons del form sono sempre richiesti in caso di conservazione */}
                                                    <DetailsCons
                                                        detailsCons={this.state.detailsCons}
                                                        clientTypeId={this.state.detailsPec.clientTypeId}
                                                        onChange={this.handleChange}
                                                        handleChange={this.handleChange}
                                                        errorMessages={this.state.errorMessages}
                                                        config={this.props.config}
                                                        handleBirthDateChange={this.handleBirthDateConsChange}
                                                        dataModify={this.state.dataModify}
                                                    />

                                                    {/* I dati del RdC sono richiesti se diversi da quelli del titolare della casella
                                                        si include comunque il modulo perchè anche se i dati sono uguali è necessario
                                                        integrare i dati di nascita */}
                                                    {
                                                        (this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_BUSINESS
                                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_PA)
                                                        && (
                                                            <React.Fragment>

                                                                <DetailsRdc
                                                                    detailsRdC={this.state.detailsRdC}
                                                                    detailsCons={this.state.detailsCons}
                                                                    clientTypeId={this.state.detailsPec.clientTypeId}
                                                                    onChange={this.handleChange}
                                                                    handleChange={this.handleChange}
                                                                    errorMessages={this.state.errorMessages}
                                                                    config={this.props.config}
                                                                    handleDataNascitaRdcChange={this.handleBirthDateRdcChange}
                                                                    dataModify={this.state.dataModify}
                                                                />

                                                            </React.Fragment>
                                                        )
                                                    }

                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <input type="hidden" name="detailsCons_sameAsOwner" value='1' />
                                                </React.Fragment>
                                            )
                                    }

                                    <div className="text-center">
                                        <button type="submit" name="action" value="sign" className="btn btn-primary btn-block mb-2 mt-5" >
                                            {this.props.config.avanti}
                                        </button>
                                    </div>

                                    {/* Mostra il link di selezione di altre anagrafiche di fatturazione */}
                                    {
                                        (this.props.config.hide_select_another_invoice_data == null || this.props.config.hide_select_another_invoice_data == false)
                                        && (
                                            <div className="mt-3 mb-3">
                                                <a href={this.props.config.url_select_page}>&laquo; {this.props.config.seleziona_altra_anagrafica}</a>
                                            </div>
                                        )
                                    }

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe FormCreatePec


if (document.getElementById('form_owner')) {
    var props = {
        config: config,
        errors: errors,
        detailsPec: detailsPec,
        detailsCons: detailsCons,
        detailsRdC: detailsRdC,
        documentPec: documentPec
    };
    var formOwner = React.createElement(FormOwner, props);
    ReactDOM.render(formOwner, document.getElementById('form_owner'))
}
