import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';


export default class FormSelectBuyer extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        this.state = {
            token: token,
            buyer: '',
            buyers: this.props.buyers,
            submitDisabled: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {
        // Recupero nome e valore dall'evento
        const target = event.target;
        const value = target.value;

        if (value == '') {
            this.setState({
                buyer: value,
                submitDisabled: true,
            });

        } else {
            this.setState({
                buyer: value,
                submitDisabled: false,
            });
        }
    }

    handleSubmit(event) {
        this.myForm.current.submit();
    }

    render() {

        return (

            <div className="card text-center mx-auto">
                <div className="card-body">
                    <div className="container">
                        <h4 className="card-title py-2">{this.props.config.dati_di_fatturazione}</h4>

                        <form action={this.props.config.url_buyer_selected} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                            <input type="hidden" name="_token" value={this.state.token} />

                            {this.state.buyers.length == 0 ? (
                                <h5 className="card-subtitle text-muted mt-5 mb-5">Nessuna anagrafica presente</h5>
                            ) : (
                                <React.Fragment>
                                    <h5 className="card-subtitle text-muted mt-3 mb-4">{this.props.config.seleziona_anagrafica_fatturazione}</h5>
                                    <div className="form-group">
                                        <OptionsBuyers
                                            url_buyer_modify={this.props.config.url_buyer_modify}
                                            buyers={this.state.buyers}
                                            value={this.state.buyer}
                                            config={this.props.config}
                                            handleChange={this.handleChange} />
                                    </div>

                                    <div className="form-group mt-4">
                                        <button type="submit" name="action" value="old" className="btn btn-primary" disabled={this.state.submitDisabled}>
                                            {this.props.config.usa_questa_anagrafica}
                                        </button>
                                    </div>

                                    <div className="form-group mt-3 mb-3 text-center">
                                        oppure
                                    </div>
                                </React.Fragment>
                            )}

                            <div className="form-group">
                                <button type="submit" name="action" value="new" className="btn btn-primary">
                                    {this.props.config.inserisci_nuova_anagrafica}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }

} // fine classe FormBuyProduct

class OptionsBuyers extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let arrayOfData = this.props.buyers;

        let options = arrayOfData.map((data) => {
            return (
                <div className="form-check text-left mb-2" key={data.id}>
                    <input className="form-check-input"
                        type="radio"
                        name="buyer"
                        id={'client' + data.id}
                        checked={this.props.value == data.id}
                        onChange={this.props.handleChange}
                        value={data.id} />
                    <label className="form-check-label" htmlFor={'client' + data.id}>
                        {data.code} - {data.name}
                    </label>

                    {/*
                    <a href={this.props.url_buyer_modify + "/" + data.id} className="ml-2">
                        <button type="button" class="btn btn-outline-secondary float-right py-0">{this.props.config.modifica_anagrafica}</button>
                    </a>
                    */}

                </div>
            );
        }
        );
        return options;
    }

} // Fine classe  OptionsDomain


if (document.getElementById('form_select_buyer')) {
    ReactDOM.render(<FormSelectBuyer buyers={buyers} config={config} />, document.getElementById('form_select_buyer'));
}
