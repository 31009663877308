import React, { Component } from 'react';

export default class SelectRdcType extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // Controlla la validità
        var classValidation = '';
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }

        var requiredClass = '';
        if(this.props.required){
            requiredClass = 'nam_required';
        }

        return (
            <div className="form-group row">
                <label htmlFor={this.props.name} className="col-sm-3 col-form-label">{this.props.label}</label>
                <div className="col-sm-9">
                    <select
                        name="detailsCons_sameAsOwner"
                        value={this.props.value}
                        onChange={this.props.handleChange}
                        className={'custom-select ' + classValidation + ' ' + requiredClass}>
                        <option key="1" value="1">{this.props.config.dettaglio_extd_rdc_il_responsabile_e_il_titolare}</option>
                        <option key="0" value="0">{this.props.config.dettaglio_extd_rdc_il_responsabile_e_altra_persona}</option>
                    </select>

                    <div className={classValidation}></div>
                    <div className="invalid-feedback">
                        {this.props.invalid_message}
                    </div>
                </div>
            </div>
        );
    }
}
