import React, { Component } from 'react';
import InputText from '../common/InputText';


export default class SelectGender extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // Controlla la validità
        var classValidation = '';
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }

        var requiredClass = '';
        if(this.props.required){
            requiredClass = 'nam_required';
        }
        if (this.props.readOnly) {
            return (
                <React.Fragment>
                    <input type="hidden" name={this.props.name} value={this.props.value} />
                <InputText
                    label={this.props.label}
                    value={this.props.value=="M"?this.props.config.gender_male:this.props.config.gender_female}
                    readOnly={true}
                    required={true}
                />
                </React.Fragment>                
            )
        }
        return (
            <div className="form-group row">
                <div className="col-sm-12">
                    <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                    <select
                        name="detailsPec_gender"
                        value={this.props.value||''}
                        onChange={this.props.handleChange}
                        className={'custom-select ' + classValidation + ' ' + requiredClass}>
                        <option value=''>{this.props.not_selected}</option>
                        <option key="M" value="M">{this.props.config.gender_male}</option>
                        <option key="F" value="F">{this.props.config.gender_female}</option>
                    </select>

                    <div className={classValidation}></div>
                    <div className="invalid-feedback">
                        {this.props.invalid_message}
                    </div>
                </div>
            </div>
        );
    }
}
