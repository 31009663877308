import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import AlertSuccess from '../common/AlertSuccess';
import AlertWarning from '../common/AlertWarning';

export default class FormVerifyPecCode extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        this.state = {
            status: status,
            submitDisabled: true,
            token: token,
            confirmation_code: '',
            popOverMessage: '',
            showMessageResendCodeKo: false,
            alertWarningMessage: this.props.config.errore_spedizione_codice_conferma,
            showMessageResendCodeOk: false,
            alertSuccessMessage: this.props.config.codice_inviato_con_successo,
            showAlreadyImportedPecWarningMessage: this.props.config.showAlreadyImportedPecWarningMessage,
            alreadyImportedPecMessage: this.props.config.alreadyImportedPecMessage,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleChange(event) {

        // Recuperare nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // Assegna il valore del campo
        this.setState({ confirmation_code: value });

        if (!this.isSubmitButtonEnabled(value) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }

    }

    handleClick(event) {

        // Recuperare nome e valore dall'evento
        const target = event.target;
        const name = target.name;

        // Chiamata asincrona per rispedire il codice
        if(name == 'resend'){
            event.preventDefault();
            this.sendNewConfirmationCode();
        }

    }

    /**
     * Chiama il servizio per la spedizione di un nuovo codice di controllo
     */
    async sendNewConfirmationCode() {
        try {
            this.setState({ showSpinnerCheck: true, });
            const data = {
                email: this.props.email,
            };

            // console.log('Async ' + this.props.config.url_resend_verify_code);

            // Chiama il servizio
            const response = await fetch(this.props.config.url_resend_verify_code, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            });

            // console.log('Async ' + JSON.stringify(response));

            if (!response.ok) {
                throw Error(response.statusText);
            }

            const json = await response.json();

            // Controlla il risultato
            if (json.result == 0) {
                // Response KO del servizio
                this.setState({ showMessageResendCodeKo: true, alertWarningMessage: json.result.error});
            } else {
                // Response OK del servizio
                this.setState({ showMessageResendCodeOk: true, });
            }

        } catch (error) {
            console.log(error);
        }

        // Nascondi lo spinner
        this.setState({ showSpinnerCheck: false, });
    }

    /**
     * Ritorna true se la lunghezza del codice di conferma è corretto
     *
     * @param {*} confirmation_code
     */
    isSubmitButtonEnabled(confirmation_code) {
        if (confirmation_code.length == this.props.config.EMAIL_VERIFICATION_TOKEN_LENGTH) {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit(event) {
        if (this.isSubmitButtonEnabled(this.state.confirmation_code) === true ) {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: "",
            }));
            this.myForm.current.submit();
        } else {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            return false;
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {
        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.props.config.title_conferma_account}</h5>
                            <h5 className="card-title">{this.props.email}</h5>

                            <AlertWarning message={this.state.alreadyImportedPecMessage} show={this.state.showAlreadyImportedPecWarningMessage} />

                            <p className="pb-3">{this.props.config.conferma_registrazione}</p>

                            <form action={this.props.config.url_verify_code} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                <input type="hidden" name="_token" value={this.state.token} />

                                <div className="row justify-content-md-center">
                                    <div className="form-group col-md-auto">
                                        <input type="text"
                                            name="confirmation_code"
                                            value={this.state.confirmation_code}
                                            onChange={this.handleChange}
                                            maxLength={this.props.config.EMAIL_VERIFICATION_TOKEN_LENGTH}
                                            className="form-control mb-2 mr-sm-2 verify-code " placeholder={this.props.config.codice} />
                                    </div>
                                </div>

                                {this.state.status === 'code_no_match' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.codice_non_corretto}
                                    </div>
                                )}

                                {this.state.status === 'code_not_found' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.codice_non_trovato}
                                    </div>
                                )}

                                <p className="pt-2">{this.props.config.conferma_registrazione_raccomandazioni}</p>

                                <div className="form-group py-4">
                                    <button type="submit" id="popover-submit"
                                        className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-animation="true"
                                        data-content={this.state.popOverMessage}
                                        data-trigger="hover"
                                        data-template={this.getPopoverCustomTemplate()}
                                        tabIndex="3">
                                        {this.props.config.conferma}
                                    </button>
                                </div>

                            </form>

                            <p className="pb-3">{this.props.config.codice_non_ricevuto}</p>

                            {/*
                            <AlertWarning customMessage={this.state.alertWarningMessage} show={this.state.showMessageResendCodeKo} hideClick={this.hideMessage} />
                            <AlertSuccess customMessage={this.state.alertSuccessMessage} show={this.state.showMessageResendCodeOk} hideClick={this.hideMessage} />

                            <p className="pb-3">
                                <a href="#" className="view_link" name="resend" onClick={this.handleClick}>{this.props.config.invia_nuovo_codice}</a><br/>
                                {this.props.config.attenzione_vecchio_link_non_piu_valido}
                            </p>
                            */}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

if (document.getElementById('form_verify_import')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<FormVerifyPecCode config={config} email={pec} />, document.getElementById('form_verify_import'));
}
