import React, { Component } from 'react';

export default class SelectCos extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var classValidation = '';
        var invalid_message = '';
        if ("error_type" in this.props.error_messages) {
            classValidation = 'is-invalid';
            invalid_message = this.props.error_messages.error_message;
        }

        var keys = Object.keys(this.props.cos);
        //console.log('SELECT COS KEYS -> ' + ' ' + JSON.stringify(keys));

        if (keys.length > 0) {
            let options = Object.entries(this.props.cos).map(([k, v]) => (
                <option key={k} value={v.item_id}>{v.descrizione_estesa}</option>
            ));

            let service_prices = 0;
            for (const [k, v] of Object.entries(this.props.cos)) {
                service_prices = k in this.props.service_prices ? this.props.service_prices[k] : 0;

                if (this.props.service_prices[k] !== 0) {
                    break;
                }
            }

            let fragment_service_prices = (
                <div>{service_prices} &euro;</div>
            );

            return (
                <div className="my-1 p-2 bg-light">
                    <div className="form-row py-2 align-items-center">
                        <div className="col-sm-10">
                            <select
                                id={this.props.service_key}
                                name={this.props.name}
                                value={this.props.selectedValue}
                                onChange={this.props.handleChange}
                                data-testid="input"
                                className={'custom-select ' + classValidation}
                                readOnly={this.props.readOnly}
                                disabled={this.props.disabled}>
                                <option key='0' value='0'>{this.props.config.servizio_pacchetti_disponibili}</option>
                                {options}
                            </select>
                            <label htmlFor={this.props.name} className="mb-0">{this.props.descrizione_estesa}</label>
                            <small id="spazio_casella_Help" className="form-text text-muted">{this.props.config.servizio_scegli_pacchetto}</small>
                            <div className="invalid-feedback">
                                {invalid_message}
                            </div>
                        </div>
                        <div className="col-sm-2">
                            {fragment_service_prices}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}
