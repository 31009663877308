import React, { Component } from 'react'
import InputText from '../common/InputText';
import AsyncSelect from 'react-select/async';

export default class SelectCitySayt extends Component {
	constructor(props) {
        super(props);
		let defaultLabel = this.props.valueProvince||''
		let defaultValue = this.props.value||''
        this.state = {
            cities: [],
			lastPrefix:'',
			isLoading: false,
			value:this.props.value,
			valueProvince:this.props.valueProvince,
			label:defaultLabel,

			options:[{value:defaultValue,label:defaultLabel}]
        };
        this.selectChanged = this.selectChanged.bind(this);
    }
    /**
     * Chiamata al servzio '/api/sayt_cities/' e caricamento dei dati nello stato
     *
     */
 	loadCities = async (prefix) => {
		let data=[]
        // console.log("Invoked api")
		if (prefix.length == 2 && prefix != this.state.lastPrefix) {
			this.setState({isLoading: true,label:prefix,lastPrefix:prefix });
		    const response = await fetch(
			`/api/sayt_cities/${prefix}`
		      //`https://www.anapioficeandfire.com/api/houses?region=${regionName}&page=${page}&pageSize=10`
		    );
		    const json = await response.json();
			data=json.map((data) =>  ({value: data.name, label: data.province_id}))
			this.setState({
				isLoading: false,
				cities: data
			});
		}
		else {
			data=this.state.cities.filter(v => v.value.startsWith(prefix.toUpperCase()))
		}
		return data;
    };
	selectChanged(data,action) {
        // console.log("selectChanged",data.action)
		if (data) {
			let parts=data.value.split('@',2)
			this.setState({
				value:data.value,
				valueProvince:data.label
			})
		}
		else {
			this.setState({
				value:'',
				valueProvince:''
			})

		}
	}
    render() {
        // console.log("Rendering select")
        if (this.props.readOnly) {
            return (
                <React.Fragment>
                    <input type="hidden" name={this.props.name} value={this.props.value} />
                    <input type="hidden" name={this.props.nameProvince} value={this.props.valueProvince} />
                    <input type="hidden" name={this.props.nameRegion} value="99" />
                    <InputText
                        label={this.props.labelProvince}
                        value={this.props.valueProvince}
                        readOnly={true}
                        required={true}
                    />
                    <InputText
                        label={this.props.label}
                        value={this.props.value}
                        readOnly={true}
                        required={true}
                    />
                </React.Fragment>
            )
        }
        // Controlla la validità
        var classValidation = '';
        // cambia la classe di validazione

        // console.log("controlla valore per " + this.props.name + " messaggio invalido " + this.props.invalid_message);

        if(this.props.invalid_message != undefined && this.props.invalid_message !== ''){
            classValidation = 'is-invalid';
        }

        var requiredClass = '';
        if(this.props.required){
            requiredClass = 'nam_required';
        }

        return (
				<React.Fragment>
	            <div className="form-group row">
	                <input type="hidden" name={this.props.nameProvince} value={this.state.valueProvince} />
	                <input type="hidden" name={this.props.nameRegion} value="99" />
	                <div className="col-sm-12">
	                    <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
						<AsyncSelect
							cacheOptions={true}
							placeholder={"Inserisci i primi due caratteri del nome città"}
							name={this.props.name}
							inputvalue={this.state.label}
							defaultValue={{key:"prova",label:this.state.label,value:this.state.value,option:[this.state.valueProvince]}}
							defaultOptions={this.state.options}
							loadOptions={this.loadCities}
							className={classValidation + ' ' + requiredClass}
							isLoading={this.state.isLoading}
							//isClearable={true}
							onChange={this.selectChanged}
							onInputChange={this.inputChanged}
							getOptionLabel={(option) => `${option.value?option.value + ' (' +option.label+')':"Inserire i primi due caratteri della città"}`}
							/>
	                    <div className="invalid-feedback">
	                        {this.props.invalid_message}
	                    </div>
	                </div>
	            </div>
                    { !this.props.hide_province &&
                        (
                        <InputText
                            label={this.props.labelProvince}
                            value={this.state.valueProvince}
                            readOnly={true}
                            required={true}
                        />
                        )
                    }
			</React.Fragment>
        );
	}
}


