/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Home page - elenco prodotti
require('./components/ListProductsInLanding');

// Landing page convenzioni
require('./components/LandingPageConvenzioni');
require('./components/conventions/Conventions');

// Static product pages
require('./components/StaticProductPage');

// Scelta pec
require('./components/choose_email/FormChooseEmail');

// Login
require('./components/LoginForm');

// changepassword
require('./components/ChangePasswordForm');

// accountdetails
require('./components/AccountDetailsForm');

// Form di registrazione
require('./components/register/RegisterForm');
// Verifica email
require('./components/register/VerifyForm');

// Richiesta reset password
require('./components/password_reset/ResetPasswordEmail');
// Nuova password
require('./components/password_reset/ResetPasswordForm');

// Form scelta anagrafica fatturazione
require('./components/billing/FormSelectBuyer');
require('./components/billing/FormSelectBuyerAsync');
// Form fatturazione
require('./components/billing/FormBilling');

// Form scelta titolare
require('./components/pec/FormSelectOwner');
// Form creazione pec
require('./components/create_pec/FormOwner');
// Form creazione pec per spidmail
require('./components/create_pec/FormOwnerEsp');

// Form upselling
require('./components/upselling/FormUpselling');

// Form attivazione conservazione
require('./components/pec/FormSelectRdc');
require('./components/pec/FormRdC');

// Form import account PEC
require('./components/import_pec/FormImportPecSelect');
require('./components/import_pec/FormVerifyPecCode');
require('./components/import_pec/FormImportPecConfirm');
// -- massive
require('./components/import_pec/FormImportPecSelectMassive');

