import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import DetailsPecSpidmail from './DetailsPecSpidmail';
import { buildMessagesFromStructure } from '../common/validation_buildErrorMessages';
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import md5 from "md5";

/**
 * Mostra uno spinner boostrap o non mostra niente in base al valore di <i>props.sho</i>
 *
 */
class SpinnerCheckEmail extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.show === true) {
            return (
                <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
            );
        } else {
            return null;
        }
    }
}
export default class FormOwnerEsp extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        // Crea l'elenco degli errori
        var errorMessages = buildMessagesFromStructure(this.props.errors);

        // Trasforma le date da stringa a Date
        if (this.props.detailsPec.birthDate !== null && this.props.detailsPec.birthDate != '') {
            const dateParts = (this.props.detailsPec.birthDate + "").split("/");
            this.props.detailsPec.birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        }

        this.state = {
            detailsPec: this.props.detailsPec,
            token: token,
            errorMessages: errorMessages,
            modalError: '',
            inLoading: false,
            proceeding: false,
            showModal: this.props.showModal
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleModal = this.handleModal.bind(this);

    }

    register() {
        /* fetch API in action */
        let formData = new FormData();

        formData.append('email', this.props.detailsPec.email)
        formData.append('password', this.state.code)

        this.setState({ inLoading: true })
        fetch('/api/spidmail/register',
            {
                method: "POST",
                body: formData
            }
        ).then(response => { return response.json(); }
        ).then(answer => {
            if (answer.result === '1') {
                this.setState({
                    check: md5(this.state.code),
                    proceeding: true
                })
                return this.myForm.current.submit();
            }

            this.setState({
                modalError: (answer.result === '1' ? '' : this.props.config.errore_password),
                inLoading: false,
            });
        });
    }

    login() {
        /* fetch API in action */
        let formData = new FormData();
        formData.append('email', this.props.detailsPec.email)
        formData.append('password', this.state.code)

        this.setState({ inLoading: true })
        fetch('/api/check_credentials',
            {
                method: "POST",
                body: formData
            }
        ).then(response => { return response.json(); }
        ).then(answer => {
            if (answer.result === '1') {
                this.setState({
                    check: this.state.code,
                    proceeding: true

                })
                return this.myForm.current.submit();
            }

            this.setState({
                modalError: (answer.result === '1' ? '' : this.props.config.errore_password),
                inLoading: false,
            });
        });
    }

    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {
        // Recupera nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // Assegna i valori per i sotto oggetti.
        // React non permette l'assegnamento a propietà di sotto oggetti
        // si deve clonare l'oggetto, modificare il valore e assegnare l'intero oggetto
        if (name.startsWith("detailsPec")) {
            // Assegna i valori all'oggetto detailsPec
            var varName = name.substring(11);

            // Assegna il valore
            const { detailsPec, errorMessages } = { ...this.state };
            errorMessages[name] = '';
            if (varName == "mobileNumber") {
                if (value.startsWith('+')) {
                    detailsPec[varName] = value.substring(1);
                } else {
                    detailsPec[varName] = value;
                }
            } else {
                detailsPec[varName] = value;
            }

            this.setState({ detailsPec: detailsPec, errorMessages: errorMessages });
        } else {
            this.setState({ [name]: value });
        }
    }

    /**
     *
     *
     * @param {*} event
     */
    handleClose(event) {
        this.setState({ showModal: false })
    }

    handleModal(event) {
        event.preventDefault();
        if (this.props.askPassword) {
            this.login();
        } else {
            this.register();
        }
    }

    handleSubmit(event) {
        // Applica la validazione
        event.preventDefault();
        if (this.state.proceeding) {
            return;
        }

        // Invia la form
        this.setState({ proceeding: true })
        this.myForm.current.submit();
    }

    render() {
        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <div className="container">
                                <h5 className="card-title">{this.props.config.dati_titolare_casella_pec}</h5>

                                <form method="post" className="" onSubmit={this.handleSubmit} ref={this.myForm}>
                                    <input type="hidden" name="_token" value={this.state.token} />
                                    <input type="hidden" name="_check" value={this.state.check} />

                                    <React.Fragment>
                                        <DetailsPecSpidmail
                                            detailsPec={this.state.detailsPec}
                                            handleChange={this.handleChange}
                                            errorMessages={this.state.errorMessages}
                                            config={this.props.config}
                                            emailReadOnly={this.props.auth}
                                        />
                                    </React.Fragment>

                                    <div className="text-center">
                                        <button type="submit" name="action" value="sign" className="btn btn-primary btn-block mb-2 mt-5" >
                                            <SpinnerCheckEmail show={this.state.proceeding} />&nbsp;{this.props.config.avanti}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.showModal &&
                    <Modal show={true}
                        onHide={this.handleClose}
                        animation={true}
                        centered={true}
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.props.askPassword &&
                                    <>
                                        Esiste già un account per la email {this.props.detailsPec.email}.
                                    </>
                                }
                                {this.props.askCode &&
                                    <>
                                        Ti abbiamo inviato un codice per verificare l'indirizzo e-mail {this.props.detailsPec.email}.
                                    </>
                                }
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form onSubmit={this.handleModal}>
                                {this.props.askPassword &&
                                    <Form.Group className="mb-3">
                                        <Form.Label>{this.props.config.dettaglio_password}</Form.Label>
                                        <Form.Control
                                            name="code"
                                            type="password"
                                            placeholder="Password"
                                            onChange={this.handleChange}
                                            isInvalid={!!this.state.modalError}
                                        />
                                        {!!this.state.modalError &&
                                            <Form.Text className="text-danger">{this.state.modalError}</Form.Text>
                                        }
                                    </Form.Group>
                                }
                                {this.props.askCode &&
                                    <Form.Group className="mb-3" >
                                        <Form.Label>{this.props.config.dettaglio_code}</Form.Label>
                                        <Form.Control
                                            name="code"
                                            type="text"
                                            placeholder="codice"
                                            onChange={this.handleChange}
                                            isInvalid={!!this.state.modalError}
                                        />
                                        {!!this.state.modalError &&
                                            <Form.Text className="text-danger">{this.state.modalError}</Form.Text>
                                        }
                                        <Form.Text>
                                            <p><b>Non trovi il codice di verifica?</b><br />
                                                Il codice è all’interno di una mail con oggetto “Conferma la tua e-mail” e mittente <i>noreply.gestionepec@namirial.com</i>. Se non trovi la comunicazione, verifica di aver scritto correttamente il tuo indirizzo e-mail.
                                            </p>
                                        </Form.Text>
                                    </Form.Group>
                                }
                            </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            <Container>
                                <Row>
                                    <Col>
                                        <button
                                            className="btn btn-outline-dark btn-sm"
                                            onClick={this.handleClose}>
                                            Correggi l'indirizzo email
                                        </button>
                                    </Col>
                                    <Col>
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={this.handleModal}>
                                            <SpinnerCheckEmail show={this.state.inLoading} />&nbsp;{this.props.config.avanti}
                                        </button>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </Modal>
                }
            </React.Fragment>
        );
    }

} // fine classe FormCreatePec

if (document.getElementById('form_owner_esp')) {
    var props = {
        config: config,
        errors: errors,
        detailsPec: detailsPec,
        askPassword: askPassword,
        askCode: askCode,
        showModal: showModal,
        auth: auth
    };
    var formOwner = React.createElement(FormOwnerEsp, props);
    ReactDOM.render(formOwner, document.getElementById('form_owner_esp'))
}
