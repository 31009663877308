import React, { Component } from 'react';
import SelectSize from '../common/SelectSize';
import SwitchService from '../common/SwitchService';

export default class Servizio extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const codice_articolo = this.props.servizio.codice_articolo;
        let servizio = this.props.servizio;
        const handleChange = this.props.handleChange;
        const error_messages = this.props.error_messages;
        let checked = this.props.checked;
        let disabled = this.props.disabled;
        const required = this.props.required;
        const total_price = this.props.total_price;
        const config = this.props.config;

        //console.log(codice_articolo + ' -> ' + ' ' + JSON.stringify(this.props));

        return (function () {
            let element = "";
            let label = "";
            if (servizio.pubblico == 1) {
                switch (codice_articolo) {
                    case 'SERVIZIO_ARCHIVIO':
                    case 'SERVIZIO_CONSERVAZIONE':
                    case 'SERVIZIO_SMS':
                    case 'SERVIZIO_REPORT':
                    case 'SERVIZIO_MULTIUTENZA':
                    case 'SERVIZIO_EMAILSCAN':
                        label = config['servizio_' + codice_articolo + '_descrizione_breve'];
                        if (servizio.dimensione_base != '' && servizio.dimensione_base != null) {
                            label += ' ' + servizio.dimensione_base + ' GB'
                        }
                        if (servizio.quantita_pre_upgrade > 0) {
                            servizio.tipologia = 'BASE';
                            disabled = true;
                            checked = true;
                            servizio.quantita = 1;
                            //console.log(codice_articolo + ' -> ' + ' ' + JSON.stringify(servizio));
                        }

                        element = <SwitchService
                            service_key={servizio.item_id}
                            name={codice_articolo}
                            value={checked}
                            label={label}
                            handleChange={handleChange}
                            error_messages={error_messages}
                            dimensione_base={servizio.dimensione_base}
                            codice_articolo={servizio.codice_articolo}
                            tipologia={servizio.tipologia}
                            quantita={servizio.quantita}
                            prezzo={servizio.price_prezzo}
                            total_price={total_price}
                            checked={checked}
                            disabled={disabled}
                            required={required}
                            helpText={undefined != config['help_switch_' + codice_articolo] ? config['help_switch_' + codice_articolo] : ''}
                        />;
                        break;

                    case 'SPAZIO_AGGIUNTIVO_CASELLA':
                    case 'SPAZIO_AGGIUNTIVO_ARCHIVIO':
                    case 'SPAZIO_AGGIUNTIVO_CONSERVAZIONE':
                        element = <SelectSize
                            service_key={servizio.item_id}
                            name={codice_articolo}
                            value={servizio.quantita}
                            label={config['servizio_' + codice_articolo + '_descrizione_breve']}
                            handleChange={handleChange}
                            error_messages={error_messages}
                            //invalid_message={this.state.errorMessages.detailsPec_name}
                            dimensione_base={servizio.dimensione_base}
                            dimensione_min={servizio.dimensione_min}
                            dimensione_max={servizio.dimensione_max}
                            dimensione_selected={servizio.dimensione_selected}
                            dimensione_granularity={servizio.dimensione_granularity}
                            tipologia={servizio.tipologia}
                            quantita={servizio.quantita}
                            prezzo={servizio.price_prezzo}
                            total_price={total_price}
                            disabled={disabled}
                            required={true}
                            config={config}
                            helpText={undefined != config['help_select_' + codice_articolo] ? config['help_select_' + codice_articolo] : ''}
                        />;
                        break;
                    default:
                        element = "";
                }
            }

            return (
                <React.Fragment>
                    <div className="my-1 p-2 bg-light">
                        {element}
                    </div>
                </React.Fragment>
            );
        })()
    }
}
