import React, { Component } from 'react';

export default class SelectSize extends Component {
    constructor(props) {
        super(props);

        this.value = this.props.value;
        this.dimensione_base = this.props.dimensione_base_casella;
        this.dimensione_min = this.props.dimensione_min_casella;
        this.dimensione_max = this.props.dimensione_max_casella;
        this.dimensione_selected = this.props.dimensione_selected_casella;
        this.dimensione_granularity = this.props.dimensione_granularity_casella;
        this.readOnly = this.props.readonly;
        this.config = this.props.config;
    }

    render() {
        // console.log(JSON.stringify(this.props.error_messages));

        // Controlla la validità
        var classValidation = '';

        // cambia la classe di validazione {"error_type":"required_service","error_message":"Il campo è obbligatorio."}
        let invalid_message = '';
        if ("error_type" in this.props.error_messages) {
            classValidation = 'is-invalid';
            invalid_message = this.props.error_messages.error_message;
        }

        let arrayOfData = [];
        for (let i = this.props.dimensione_min; i <= this.props.dimensione_max; this.props.dimensione_granularity * i++) {
            arrayOfData.push(i);
        }

        if (arrayOfData.length > 0) {
            let options = arrayOfData.map((data) =>
                <option key={data} value={data}>{data} GB</option>
            );

            let fragment_quantita;
            let fragment_prezzo;
            let fragment_total_price;

            if (this.props.tipologia === 'ATTIVABILE') {
                fragment_quantita = (
                    <div>{this.props.quantita}</div>
                );
                fragment_prezzo = (
                    <div>{this.props.prezzo} &euro; / GB</div>
                );
                let total_price = this.props.service_key in this.props.total_price ? this.props.total_price[this.props.service_key] : 0;
                fragment_total_price = (
                    <div>{total_price} &euro;</div>
                );
            } else {
                fragment_quantita = (
                    <div></div>
                );
                fragment_prezzo = (
                    <div>({this.config.servizio_incluso})</div>
                );
                fragment_total_price = (
                    <div>({this.config.servizio_incluso})</div>
                );
            }

            return (
                <div className="form-row py-2 align-items-center">
                    <div className="col-sm-3">
                        <select
                            id={this.props.service_key}
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            data-testid="input"
                            className={'custom-select ' + classValidation}
                            readOnly={this.props.readOnly}>
                            disabled={this.props.disabled}
                            {options}
                        </select>
                        <div className="invalid-feedback">
                            {invalid_message}
                        </div>
                    </div>
                    <div className="col ml-2">
                        <label htmlFor={this.props.name} className="mb-0">{this.props.label}</label>
                        <small id={this.props.name + '_Help'} className="form-text text-muted">{this.props.helpText}</small>
                    </div>
                    <div className="col-sm-2">
                        {fragment_total_price}
                    </div>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}
