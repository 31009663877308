import React, { Component } from 'react';


/**
 * Genera una lista di tag OPTION con i valori presi dal servizio
 *
 */
export default class OptionsDomain extends Component {

    constructor(props) {
        super(props);
        this.state = {
            domains: [],
        };
    }

    /**
     * Metodo chiamato dopo il render
     *
     * Carica i prodotti e modifica lo stato
     *
     */
    componentDidMount() {
        // Chiama il servizio rest e assegna i dati allo stato
        this.loadAvailableDomains();
    }

    /**
     * Carica dal servizio rest i domini disponibili e modifica lo stato del componente per modificare i valori della select
     */
    async loadAvailableDomains() {
        try {
            let $loadUrl = this.props.url_service + '/' + this.props.id_convenzione;
            if (this.props.id_prodotto != 0) {
                $loadUrl += '/' + this.props.id_prodotto;
            }
            const response = await fetch($loadUrl);
            if (!response.ok) {
                throw Error(response.statusText);
            }

            const json = await response.json();
            this.setState({ domains: json });
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        let arrayOfData = this.state.domains;
        let options = arrayOfData.map((data,index ) =>
                    <option key={data.id} value={data.id} selected={index === 0}>
                        {data.name}
                    </option>
        );
        return options;
    }

} // Fine classe  OptionsDomain
