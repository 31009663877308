import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import PasswordInput from '../common/PasswordInput';
import { checkValidEmailName } from '../common/email-validation';
import { isValidPassword } from '../common/strength-password';

export default class ResetPasswordForm extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef()

        var invalid_message = '';
        var classValidation = '';

        // console.log("valore di status " + status);

        if (this.props.status === 'invalid_user') {
            classValidation = 'is-invalid';
            invalid_message = this.props.config.errore_indirizzo_non_trovato;
        } else if (this.props.status === 'invalid_email') {
            classValidation = 'is-invalid';
            invalid_message = this.props.config.errore_email_non_valida;
        }

        this.state = {
            email: this.props.email,
            password: '',
            password_confirmation: '',
            status: this.props.status,
            showSpinnerCheck: false,
            showMessageCredentialsNotValid: false,
            submitDisabled: true,
            popOverMessage: '',
            token: token,
            token_reset: this.props.token_reset,
            config: this.props.config,
            invalid_message: invalid_message,
            classValidation: classValidation,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {

        // Recuperare nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // setState è ascincrono, quindi devo considerare il valore separatamente
        var myEmail = this.state.email;
        var myPassword = this.state.password;
        var mypassword_confirmation = this.state.password_confirmation;

        if (name == "email") {
            // Modifica al campo email
            myEmail = value;
        } else if (name == "password") {
            myPassword = value;
        } else if (name == "password_confirmation") {
            mypassword_confirmation = value;
        }

        // Assegna il valore del campo
        this.setState({ [name]: value });

        if (!this.isSubmitButtonEnabled(myEmail, myPassword, mypassword_confirmation)) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }

        //this.setState({email: event.target.value});
    }

    /**
     * Ritorna true se i valori email e password sono corretti
     *
     * @param {*} myEmail
     * @param {*} myPassword
     */
    isSubmitButtonEnabled(email, password, password_confirmation) {
        if (checkValidEmailName(email) && isValidPassword(password,
            this.state.config.REGISTER_PAGE_PASSWORD_LENGTH_MIN,
            this.state.config.REGISTER_PAGE_PASSWORD_LENGTH_MAX
        ) && (password === password_confirmation)) {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit(event) {
        if (this.isSubmitButtonEnabled(this.state.email, this.state.password, this.state.password_confirmation)) {
            event.preventDefault();
            this.myForm.current.submit();
        } else {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            return false;
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {

        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.props.config.imposta_nuova_password}</h5>
                            <h5 className="card-title">{this.state.email}</h5>
                            <div>

                                {this.state.status === 'passwords.token' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.link_scaduto_o_non_valido}
                                        <br />
                                        {this.props.config.chiedere_nuovo_reset}
                                    </div>
                                )}

                                {this.state.status === 'change.fail' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.errore_salvataggio_password}
                                    </div>
                                )}

                                <form action="/password/reset" method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                    <input type="hidden" name="_token" value={this.state.token} />
                                    <input type="hidden" name="token" value={this.state.token_reset} />
                                    <input type="hidden" name="email" value={this.state.email} />

                                    <div className="form-group">
                                        <div className="invalid-feedback text-left" data-testid="message">
                                            {this.state.invalid_message}
                                        </div>
                                    </div>

                                    <PasswordInput
                                        handleChange={this.handleChange}
                                        value={this.state.password}
                                        value2={this.state.password_confirmation}
                                        length_min={this.state.config.REGISTER_PAGE_PASSWORD_LENGTH_MIN}
                                        length_max={this.state.config.REGISTER_PAGE_PASSWORD_LENGTH_MAX}
                                        config={this.props.config}
                                    />

                                    <div className="form-group py-4">
                                        <button type="submit" id="popover-submit"
                                            className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-animation="true"
                                            data-content={this.state.popOverMessage}
                                            data-trigger="hover"
                                            data-template={this.getPopoverCustomTemplate()}
                                            tabIndex="3">
                                            {this.props.config.reset_password_button}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

if (document.getElementById('form_reset_password')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<ResetPasswordForm config={config} status={status} token_reset={token_reset} email={email} config={config} />, document.getElementById('form_reset_password'));
}
