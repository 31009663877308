import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from './common/csrf-loader';
import AlertWarning from './common/AlertWarning';
import { isValidPassword } from './common/strength-password';


export default class ChangePasswordForm extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.mychForm = React.createRef()
        var title = this.props.config.title;
        var user = this.props.config.Email;

        this.state = {
            user: user,
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
            title: title,
            msgPassword: '',
            showmsgPassword: false,
            submitDisabled: true,
            popOverMessage: '',
            token: token,
            showSpinner: false,
            showForm: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideMessage = this.hideMessage.bind(this);

    }

    handleChange(event) {
        // Se il messaggio è visibile nascondilo
        if (this.state.showmsgPassword === true) {
            this.setState({ showmsgPassword: false, });
        }

        // Recupero nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // setState è ascincrono, quindi devo considerare il valore separatamente
        var oldPassword = this.state.oldPassword;
        var newPassword = this.state.newPassword;
        var repeatPassword = this.state.repeatPassword;

        if (name == "oldPassword") {
            oldPassword = value;
        } else if (name == "newPassword") {
            newPassword = value;
        } else if (name == "repeatPassword") {
            repeatPassword = value;
        }

        this.setState({ [name]: value });

        if (!this.isSubmitButtonEnabled(oldPassword, newPassword, repeatPassword) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }

    }

    /**
    * Ritorna true se i valori password  sono corretti
    *
    * @param {*} oldPassword
    * @param {*} newPassword
    * @param {*} repeatPassword
    */
    isSubmitButtonEnabled(oldPassword, newPassword, repeatPassword) {

        const isValidOldPassword = oldPassword.length >= this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MIN
                                && oldPassword.length <= this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MAX;
        const isValidNewPassword = isValidPassword(newPassword, this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MIN, this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MAX);
        const isValidRepeatPassword = isValidPassword(repeatPassword, this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MIN, this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MAX);

        if (isValidOldPassword && isValidNewPassword && isValidRepeatPassword && newPassword === repeatPassword){
            return true;
        } else {
            return false;
        }
    }

    /**
     * Chiama il servizio per il controllo della disponibilità
     *
     */
    async checkCredentials() {
        this.setState({ showSpinner: true, });
        try {
            const data = {
                email: this.state.user,
                password: this.state.oldPassword,
            };

            // Chiama il servizio
            const response = await fetch(this.props.config.url_check_credentials, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw Error(response.statusText);
            }

            const json = await response.json();

            // Controlla il risultato
            if (json.result == 0) {
                // Le credenziali non sono valide, mostra il messaggio
                this.setState({ msgPassword: this.props.config.credenziali_errate_verifica });
                this.setState({ showmsgPassword: true, });
            } else {
                // Invia i dati al server usando il form
                this.mychForm.current.submit();
            }


        } catch (error) {
            console.log(error);
        }
        // Nascondi lo spinner
        this.setState({ showSpinner: false, });

    }

    handleSubmit(event) {
        //console.log('handleSubmit ' + this.state.popOverMessage);

        if (this.isSubmitButtonEnabled( this.state.oldPassword, this.state.newPassword, this.state.repeatPassword)) {
            event.preventDefault();
            // Chiama il servizio per la verifica delle credenziali
            this.checkCredentials();
        } else {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            return false;
        }

    }

    /**
     * Nasconde il messaggio di avvertimento credenziali non valide.
     * Il metodo è necessario per permettere di gestire il bottone di chiusura dentro il componente MessageCredentialsNotValid
     *
     */
    hideMessage() {
        this.setState({ showmsgPassword: false, });
    }

    getClassValidOldPassword(value){
        // restituisce la classe di validazione
        if (value === '') {
            return '';
        } else if ( value.length >= this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MIN
                    && value.length <= this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MAX ){
            return 'is-valid';
        } else {
            return 'is-invalid';
        }
    }

    getClassValidPassword(value){
        // restituisce la classe di validazione
        if (value === '') {
            return '';
        } else if (isValidPassword(value, this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MIN, this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MAX)) {
            return 'is-valid';
        } else {
            return 'is-invalid';
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {

        var oldPassword_class_validation = this.getClassValidOldPassword(this.state.oldPassword);
        var newPassword_class_validation = this.getClassValidPassword(this.state.newPassword);
        var repeatPassword_class_validation = this.getClassValidPassword(this.state.repeatPassword);

        if (this.state.newPassword !== this.state.repeatPassword){
            repeatPassword_class_validation = 'is-invalid';
        }

        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.state.title}</h5>
                            <div>
                                <p>{this.props.config.introtext}</p>
                                <div className="form-group py-4">
                                    <a href={this.props.config.url_mynamirial_change_password} target='_blank' id="popover-submit"
                                        className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-animation="true"
                                        data-content={this.state.popOverMessage}
                                        data-trigger="hover"
                                        data-template={this.getPopoverCustomTemplate()}
                                        tabIndex="3">
                                        {this.props.config.buttonsaveMyNamirial}
                                    </a>
                                </div>

                                {/* Nascondo la form di cambio pwd su Shop, non più utilizzata */}
                                {this.state.showForm &&
                                <form action={this.props.config.url_chPassword} method="post" onSubmit={this.handleSubmit} ref={this.mychForm}>
                                    <input type="hidden" name="_token" value={this.state.token} />

                                    <div className="form-group">
                                        <label htmlFor="oldPassword" className="col-form-label font-weight-bold">{this.props.config.ph_password_attuale}</label>
                                        <input type="password"
                                            name="oldPassword"
                                            onBlur={this.handleChange}
                                            onChange={this.handleChange}
                                            className={"form-control mb-2 mr-sm-2 " + oldPassword_class_validation}
                                            maxLength={this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MAX}/>
                                        <div className="invalid-feedback">
                                            {this.props.config.password_non_valida}
                                        </div>
                                        <div className="valid-feedback">
                                            {this.props.config.password_valida}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="newPassword" className="col-form-label font-weight-bold">{this.props.config.ph_password_nuova}</label>
                                        <input type="password"
                                            name="newPassword"
                                            onBlur={this.handleChange}
                                            onChange={this.handleChange}
                                            className={"form-control mb-2 mr-sm-2 " + newPassword_class_validation}
                                            maxLength={this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MAX}/>
                                        <div className="invalid-feedback">
                                            {this.props.config.password_rules}
                                        </div>
                                        <div className="valid-feedback">
                                            {this.props.config.password_valida}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="repeatPassword" className="col-form-label font-weight-bold">{this.props.config.ph_password_conferma}</label>
                                        <input type="password"
                                            name="repeatPassword"
                                            onBlur={this.handleChange}
                                            onChange={this.handleChange}
                                            className={"form-control mb-2 mr-sm-2 " + repeatPassword_class_validation}
                                            maxLength={this.props.config.CHANGEPASSWORD_PAGE_PASSWORD_LENGTH_MAX}/>
                                            <div className="invalid-feedback">
                                                {this.props.config.password_non_coincidenti}
                                            </div>
                                    </div>

                                    <AlertWarning message={this.state.msgPassword} show={this.state.showmsgPassword} hideClick={this.hideMessage} />

                                    <div className="form-group py-4">
                                        <button type="submit" id="popover-submit"
                                            className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-animation="true"
                                            data-content={this.state.popOverMessage}
                                            data-trigger="hover"
                                            data-template={this.getPopoverCustomTemplate()}
                                            tabIndex="3">
                                            <Spinner show={this.state.showSpinner} />&nbsp;
                                            {this.props.config.buttonsave}
                                        </button>
                                    </div>
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

/**
 * Mostra uno spinner boostrap o non mostra niente in base al valore di <i>props.sho</i>
 *
 */
class Spinner extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.show === true) {
            return (
                <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
            );
        } else {
            return null;
        }
    }
} // Fine classe Spinner

if (document.getElementById('form_chPwd')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<ChangePasswordForm config={config} />, document.getElementById('form_chPwd'));
}
