import React, { Component } from 'react';

export default class PecInfos extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        if (this.props.info !== null){
            var expirationDate = '';

            if (null !== this.props.info.expirationDate){
                expirationDate = new Date(this.props.info.expirationDate).toLocaleDateString("it-IT");
            }

            const keys = Object.keys(this.props.info.services);

            return (
                <React.Fragment>

                    <div>
                        <Attribute2Rows label={this.props.config.label_casella_pec} value={this.props.info.mailbox}/>
                        <Attribute2Rows label={this.props.config.label_cos} value={this.props.config['label_' + this.props.info.cos.type]}/>
                        <Attribute2Rows label={this.props.config.label_dimensione_base} value={this.props.info.size.base + ' GB'} />

                        {this.props.info.size.real != this.props.info.size.base && (
                            <Attribute2Rows label={this.props.config.label_dimensione_reale + ' (' + this.props.config.label_servizio_aggiuntivo_casella + ')'} value={this.props.info.size.real + ' GB'} />
                        )}

                        {/* <Attribute2Rows label={this.props.config.label_data_di_scadenza} value={expirationDate}/> */}

                        <div className="row">
                            <div className="col-sm-12 label font-weight-bold">{this.props.config.label_servizi_attivi_su_casella}</div>
                        </div>
                        <div className="row">
                            <React.Fragment>
                                {keys.map((service_name) => (
                                    <ServiceProperties
                                        key={service_name.toString()}
                                        label={service_name.toString()}
                                        service_properties={this.props.info.services[service_name]}
                                        config={this.props.config}
                                    />
                                ))}
                            </React.Fragment>
                        </div>

                    </div>

                </React.Fragment>
            );
        } else {
            return null;
        }
    }

}

class ServiceProperties extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        // Servizio base/aggiuntivo
        var label_servizio = this.props.config.label_servizio_aggiuntivo_casella;
        if (null !== this.props.service_properties && this.props.service_properties.activatedByCOS){
            label_servizio = this.props.config.label_servizio_base_casella;
        }

        // Eventual quota servizio
        var quota_servizio = '';
        if (null !== this.props.service_properties && this.props.service_properties.hasOwnProperty('properties')) {

            // Quota
            if (null !== this.props.service_properties.properties && this.props.service_properties.properties.hasOwnProperty('quota')) {
                quota_servizio = this.props.config.label_dimensione_base_servizio + ' ' + this.props.service_properties.properties.quota.base + ' GB ';
                if (this.props.service_properties.properties.quota.base !== this.props.service_properties.properties.quota.real) {
                    quota_servizio += '. ' + this.props.config.label_dimensione_estesa_servizio + ' ' + this.props.service_properties.properties.quota.real + ' GB';
                }
            }

        }

        return (
            <React.Fragment>

                <div className="col-sm-1 pr-0 text-right">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-circle pb-1" viewBox="0 0 20 20">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                    </svg>
                </div>
                <div className="col-sm-11 pl-1">
                    <div className="row">
                        <div className="col-sm-12 label"><strong>{this.props.label}</strong> ({label_servizio})</div>
                    </div>

                    {quota_servizio != '' && (
                        <div className="row">
                            <div className="col-sm-12"><small>{quota_servizio}</small></div>
                        </div>
                    )}

                </div>

            </React.Fragment>
        )

    }
}

class Attribute2Rows extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return(
            <React.Fragment>

                <div className="row">
                    <div className="col-sm-12 label font-weight-bold">{this.props.label}</div>
                </div>
                <div className="row">
                    <div className="col-sm-12">{this.props.value}</div>
                </div>

            </React.Fragment>
        )

    }
}
