/**
 * Costruisce un elenco di coppie chiave messaggio per gli errori definiti in errors
 *
 * @param {*} errors
 * @param {*} config
 */
function buildMessagesFromStructure(errors) {
    var errorMessages = {};

    for (const [formField, validationErrors] of Object.entries(errors)) {
        for (const [validationErrorKey, validationError] of Object.entries(validationErrors)) {

            if (typeof errorMessages[formField] !== 'undefined') {
                errorMessages[formField] += ', ' + validationError.charAt(0).toLowerCase() + validationError.slice(1);
            } else {
                errorMessages[formField] = validationError;
            }

        }
    }

    return errorMessages;
}

export { buildMessagesFromStructure };
