import React, { Component } from 'react';
import { checkValidEmailName } from './email-validation';


export default class EmailInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        // Controlla la validità
        var classValidation = '';

        var regexp_mail = '';
        if (this.props.config.REGEX_MAIL_JS != null) {
            regexp_mail = new RegExp(this.props.config.REGEX_MAIL_JS, 'i');
        }

        var length_min = 5;
        if (this.props.config.MAIL_PEO_LENGTH_MIN != null) {
            length_min = this.props.config.MAIL_PEO_LENGTH_MIN;
        }

        var length_max = 180;
        if (this.props.config.MAIL_PEO_LENGTH_MAX != null) {
            length_max = this.props.config.MAIL_PEO_LENGTH_MAX;
        }

        var isValidEmailName = checkValidEmailName(this.props.value, regexp_mail);

        // cambia la classe di validazione
        if (this.props.value === '') {
            // Non mostrare nessun messaggio
            classValidation = '';
        } else if (isValidEmailName
            && this.props.value.length >= length_min
            && this.props.value.length <= length_max
        ) {
            classValidation = 'is-valid';
        } else {
            classValidation = 'is-invalid';
        }

        return (
            <div className="form-group">
                <label htmlFor="email" className="col-form-label font-weight-bold">{this.props.config.indirizzo_email}</label>
                <input type="text"
                    name="email"
                    value={this.props.value || null}
                    onChange={this.props.handleChange}
                    data-testid="email"
                    className={'form-control mb-2 mr-sm-2 ' + classValidation}
                    readOnly={this.props.readOnly}
                    maxLength={this.props.maxLength}
                //placeholder={this.props.config.indirizzo_email}
                />
                <small id="passwordHelpBlock" className="form-text text-muted">
                    {this.props.config.indirizzo_email_help}
                </small>
                <div className="invalid-feedback text-left">
                    {this.props.config.indirizzo_email_non_valido}
                </div>
                <div className="valid-feedback text-left">
                    {this.props.config.indirizzo_email_valido}
                </div>
            </div>
        );
    }
}
