import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';


export default class FormSelectBuyerAsync extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        this.state = {
            token: token,
            buyer: '',
            buyers: this.props.buyers,
            submitDisabled: true,
            data:[],
            loading: true,
            counter: 0,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        await Promise.all(this.props.buyers.map((buyer) => {
                return this.fetchData(buyer);
            }
        ))

        this.setState({
            loading: false,
        });
    }

    fetchData = async (buyer) => {
        try {
            const response = await fetch('/order/get_buyer/'+buyer.id+"/"+buyer.cf); // Replace with your route
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            this.setState((currentStatus) => {
                return {
                    data: currentStatus.data.concat(result),
                }
            });

        } catch (error) {
            // provo a richiamare il servizio usando lo stesso buyer
            this.fetchData(buyer);
            // print a console dell'errore
            console.error('Error:', error);

        }
    };


    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {
        // Recupero nome e valore dall'evento
        const target = event.target;
        const value = target.value;

        if (value == '') {
            this.setState({
                buyer: value,
                submitDisabled: true,
            });

        } else {
            this.setState({
                buyer: value,
                submitDisabled: false,
            });
        }
    }

    handleSubmit(event) {
        this.myForm.current.submit();
    }

    render() {

        const { data, loading } = this.state;

        // if (loading) {
        //     return <div>Loading...</div>;
        // }

        return (

            <div className="card text-center mx-auto">
                <div className="card-body">
                    <div className="container">
                        <h4 className="card-title text-uppercase py-2">{this.props.config.dati_di_fatturazione}</h4>

                        <form action={this.props.config.url_buyer_selected} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                            <input type="hidden" name="_token" value={this.state.token} />

                            {data.length == 0 && !loading ? (
                                <h5 className="card-subtitle text-muted mt-5 mb-5 text-uppercase">Nessuna anagrafica presente</h5>
                            ) : (
                                <React.Fragment>
                                    <h5 className="card-subtitle text-muted mt-3 mb-4 text-uppercase">{this.props.config.seleziona_anagrafica_fatturazione}</h5>

                                    <div className="form-group">
                                        <OptionsBuyers
                                            url_buyer_modify={this.props.config.url_buyer_modify}
                                            buyers={this.state.buyers}
                                            value={this.state.data}
                                            config={this.props.config}
                                            handleChange={this.handleChange} />

                                            {this.state.loading && <div>
                                                <div className="spinner-border m-3" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>}
                                    </div>

                                    <div className="form-group mt-4">
                                        <button type="submit" name="action" value="old" className="btn btn-primary text-uppercase" disabled={this.state.submitDisabled}>
                                            {this.props.config.usa_questa_anagrafica}
                                        </button>
                                    </div>

                                    <div className="form-group mt-3 mb-3 text-center">
                                        oppure
                                    </div>
                                </React.Fragment>
                            )}

                            {/* PECSW-18243:anagrafica fatturazione non editabile vuota                 */}
                            {/* <div className="form-group">
                                <button
                                    type="submit"
                                    name="action"
                                    value="new"
                                    disabled = {this.state.loading}
                                    className="btn btn-primary text-uppercase">
                                    {this.props.config.inserisci_nuova_anagrafica}
                                </button>
                            </div> */}
                            <div className="form-group">
                                <button
                                    type="submit"
                                    name="action"
                                    value="new"
                                    disabled = {this.state.loading}
                                    className="btn btn-primary text-uppercase">
                                    {this.props.config.inserisci_nuova_anagrafica}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }

} // fine classe FormBuyProduct

class OptionsBuyers extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let arrayOfData = this.props.value;

        let options = arrayOfData.map((data,index) => {
            return (
                <div className="form-check text-left mb-2" key={data.id}>

                    <input className="form-check-input"
                        type="radio"
                        name="buyer"
                        id={'client' + data.id}
                        // checked={this.props.value == data.id}
                        onChange={this.props.handleChange}
                        value={data.id} />
                    <label className="form-check-label" htmlFor={'client' + data.id}>
                    {data.code} - {data.name}
                    </label>

                    {/*
                    <a href={this.props.url_buyer_modify + "/" + data.id} className="ml-2">
                        <button type="button" class="btn btn-outline-secondary float-right py-0">{this.props.config.modifica_anagrafica}</button>
                    </a>
                    */}

                </div>
            );
        });
        return options;
    }

} // Fine classe  OptionsDomain


if (document.getElementById('form_select_buyer_async')) {
    ReactDOM.render(<FormSelectBuyerAsync buyers={buyers} config={config} />, document.getElementById('form_select_buyer_async'));
}
