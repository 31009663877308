import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SelectRdcType from '../common/SelectRdcType';
import { csrfToken } from '../common/csrf-loader';

export default class FormSelectRdc extends Component {

    constructor(props) {
        super(props);

        const token = csrfToken();

        this.myForm = React.createRef();
        this.state = {
            token: token,
            owner: this.props.owner,
            detailsCons_sameAsOwner: 1,
            errorMessages: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {
        const target = event.target;
        const value = target.value;

        if (value == '1') {
            this.setState({
                detailsCons_sameAsOwner: 1,
            });
        } else {
            this.setState({
                detailsCons_sameAsOwner: 0,
            });
        }
    }

    handleSubmit(event) {
        this.myForm.current.submit();
    }

    render() {

        return (

            <React.Fragment>
                <h5 className="card-title mt-4">DATI RESPONSABILE DELLA CONSERVAZIONE</h5>

                <form action={this.props.config.url_rdc_select} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                    <input type="hidden" name="_token" value={this.state.token} />

                    <div className="form-group">
                        <SelectRdcType
                            value={this.state.sameAsOwner}
                            handleChange={this.handleChange}
                            label="Il responsabile della conservazione"
                            invalid_message={this.state.errorMessages.detailsCons_sameAsOwner}
                            required={true}
                            config={this.props.config}
                        />
                    </div>

                    <div className="form-group mt-3 text-center">
                        <button type="submit" name="sameAsOwner" value={this.state.sameAsOwner} className="btn btn-primary btn-lg">
                            ATTIVA LA CONSERVAZIONE
                        </button>
                    </div>
                </form>
            </React.Fragment>
        );
    }

} // fine classe FormSelectRdC

if (document.getElementById('form_select_rdc')) {
    ReactDOM.render(<FormSelectRdc owner={owner} config={config} />, document.getElementById('form_select_rdc'));
}
