import React, { Component } from 'react';
import Select from 'react-select';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import AlertWarning from '../common/AlertWarning';
import OptionsDomain from './OptionsDomain';
import LoadUrlDescription from '../common/LoadUrlDescription';
import SwitchChoice from '../common/SwitchComponent';

export default class FormChooseEmail extends Component {

    constructor(props) {
        super(props);
        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        this.email_verified = false;
        this.error_displayed = true;
        this.lastEmail = this.props.config.suggestion || '';
        // -- EMAILSCAN
        // this.toggleIsVisible = false;
        // this.validStripeCoupons =['PROFESSIONALEMAILSCAN', 'BUSINESSEMAILSCAN', 'ENTERPRISEEMAILSCAN'];
        this.productName = "";
        this.loadurlRef = React.createRef();

        this.state = {
            token: token,
            product: '',
            inLoading: true,
            email: this.lastEmail,
            domain: 'notselected',
            emailValid: false,
            errorOnInvalidSubmit: '',
            labelSubmitButton: this.props.config.verifica_disponibilita,
            labelPlaceholder: this.props.config.nome_casella_pec,
            classPlaceholder: '',
            showSpinnerCheckEmail: false,
            showMessageCheckEmail: false,
            showTechnicalError: false,
            showValidationError: false,
            spidmail: !!this.props.config.spidmail,
            extraAlert: '',
            domains: [
                {value: "",label:"Seleziona un dominio"}
            ],
            defaultDomain: "",
            // -- EMAILSCAN
            switchedOn: true,
            couponOffSuffix: "OFF",
            showSpinnerLoadProductDesc: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.hideValidationMessage = this.hideValidationMessage.bind(this);
        this.loadAvailableDomains = this.loadAvailableDomains.bind(this);
    }

    /**
     * Carica i prodotti e modifica lo stato dopo il render
     */
    componentDidMount() {

        /* fetch API in action */
        console.table(this.props.config)
        let qs=''
        if (this.props.config.stripe_coupon) {
            qs= '?stripe_coupon=' + this.props.config.stripe_coupon;
        }
        console.log("URL con coupon", this.props.config.url_load_product + '/' + this.props.locale + '/' + this.props.idProduct+qs)
        fetch(this.props.config.url_load_product + '/' + this.props.locale + '/' + this.props.idProduct+qs)
            .then(response => {
                return response.json();
            })
            .then(product => {
                //Fetched product is stored in the state
                let ready = false ; // = this.checkEmailValid(this.myForm.current.email.value, this.myForm.current.domain.value)
                console.log("fetched product",product)
                this.setState({
                    domain: this.myForm.current.domain.value,
                    product: product,
                    inLoading: false,
                    errorOnInvalidSubmit: ready ? '' : this.props.config.completa_il_form_per_proseguire,
                });
            });


        this.loadAvailableDomains()

        //-- verifico se mostrare il toogle per attivazione Service EMAILSCAN
        // if (this.validStripeCoupons.includes(this.props.config.stripe_coupon)) this.toggleIsVisible=true;
        const pageUrl = window.location.href;
        this.productName = pageUrl.split("/").pop();
        console.log(this.productName);

    }


    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {
        // Se il messaggio è visibile nascondilo
        if (this.state.showTechnicalError === true) {
            this.setState({ showTechnicalError: false, });
        }
        this.email_not_available = false;

        // Se l'email era verificata bisogna ripetere la verifica
        if (this.email_verified === true) {
            this.email_verified = false;
            this.setState({ labelSubmitButton: this.props.config.verifica_disponibilita });
        }

        // Recupero nome e valore dall'evento
        const target = event.target;
        var value = target.value;
        const name = target.name;

        // setState è ascincrono, quindi devo considerare il valore separatamente
        var myEmail = this.state.email;
        var myDomain = this.state.domain;
        if (name == "email") {
            myEmail = value;
            if (myEmail.length > 0)
                value = value.toLowerCase();
        } else {
            myDomain = value.toLowerCase();
        }

        const isValidEmail = this.checkEmailValid(myEmail, myDomain);
        const popoverMessage = isValidEmail ? '' : this.props.config.completa_il_form_per_proseguire;

        this.setState({
            [name]: value,
            emailValid: isValidEmail,
            errorOnInvalidSubmit: popoverMessage
        });
    }

    /**
     * Handle focus event
     *
     * @param {*} event
     */
    handleFocus(event) {
        // Se sto mostrando il messaggio di errore lo cancello e riuso in caso la vecchia email

        // Se il messaggio è visibile nascondilo
        if (this.error_displayed === true) {

            const isValidEmail = this.checkEmailValid(this.lastEmail, this.state.domain);
            const popoverMessage = isValidEmail ? '' : this.props.config.completa_il_form_per_proseguire;

            // riassegna il valore della vecchia email
            this.setState({
                classPlaceholder: '',
                labelPlaceholder: this.props.config.nome_casella_pec,
                email: this.lastEmail,
                emailValid: false,
                labelSubmitButton: this.props.config.verifica_disponibilita,
                errorOnInvalidSubmit: popoverMessage,
            });

            this.error_displayed = false;
        }
    }

    /**
     * Verifica se i campi localpart e dominio sono compilati
     *
     * @param {*} localpart
     * @param {*} domain
     * @returns
     */
    checkEmailValid(localpart, domain) {
        if (this.checkValidLocalpartName(localpart) && this.domainIsSelected(domain)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Valida la localpart
     *
     * @param {*} email
     * @returns
     */
    checkValidLocalpartName(localpart) {
        // Verifica lunghezza minima e massima
        let extraAlert = "";

        // Verifica regexp provisioning ^[a-zA-Z0-9_.\-]{1,200}$ e primo/ultimo carattere
        if (localpart.length < this.props.config.LOCALPART_LENGTH_MIN || localpart.length > this.props.config.LOCALPART_LENGTH_MAX) {
            extraAlert = extraAlert + "\n" + this.props.config.invalid_mail_length;
        }
        else {
            const regex = new RegExp(this.props.config.LOCALPART_VALIDATION_REGEXP);
            if (!regex.test(localpart)) {
                {
                    ['.', '_', '-'].forEach(function (value) {
                        if (localpart.endsWith(value)) {
                            extraAlert = extraAlert + "\nIl nome non puo' terminare con '" + value + "'"
                        }
                    })
                }
                {
                    ['.', '_', '-'].forEach(function (value) {
                        if (localpart.startsWith(value)) {
                            extraAlert = extraAlert + "\nIl nome non puo' iniziare con '" + value + "'"
                        }
                    })
                }
                {
                    ['..', '._', '.-', '_.', '__', '_-', '-.', '-_', '--'].forEach(function (value) {
                        if (localpart.includes(value)) {
                            extraAlert = extraAlert + "\nIl nome non puo' contenere '" + value + "'"
                        }
                    })
                }
                if (extraAlert == '') {
                    extraAlert = "\nIl nome contiene caratteri non ammessi"
                }
            }
        }
        if (extraAlert == '') {
            this.setState({
                showValidationError: false,
                errorOnInvalidSubmit: '',
                extraAlert: '',
            });
            return true;
        }
        else {
            this.setState({
                showValidationError: true,
                errorOnInvalidSubmit: this.props.config.completa_il_form_per_proseguire,
                extraAlert: extraAlert.substring(1)
            });
            return false

        }
    }

    /**
     * Verifica se uno dei domini è selezionato
     *
     * @param {*} domain
     * @returns
     */
    domainIsSelected(domain) {
        return domain != "notselected";
    }

    /**
     * Handle submit event
     *
     * @param {*} event
     * @returns
     */
    handleSubmit(event) {
        var email = this.state.email;
        var domain = this.state.domain;

        if (this.checkEmailValid(email, domain)) {
            if (this.email_verified === false) {
                event.preventDefault();
                this.checkEmailAvailable();
            } else {
                this.setState({
                    showSpinnerCheckEmail: true,
                    labelSubmitButton: "Attendi per favore"
                })
                this.myForm.current.submit();
            }
        } else {
            event.preventDefault();
            return false;
        }
    }
    async loadAvailableDomains() {
        try {
            //url_service={this.props.config.url_available_domains}
            //id_convenzione={this.props.config.id_convenzione}
            //id_prodotto={this.props.config.id_prodotto}
            let $loadUrl = this.props.config.url_available_domains + '/' + this.props.config.id_convenzione;
            if (this.props.config.id_prodotto != 0) {
                $loadUrl += '/' + this.props.config.id_prodotto;
            }
            const response = await fetch($loadUrl);
            if (!response.ok) {
                throw Error(response.statusText);
            }

            const json = await response.json();
            let arrayOfData = json;
            let options = arrayOfData.map((data,index ) =>
                <option key={data.id} value={data.id} selected={index === 0}>
                    {data.name}
                </option>
            );
            this.setState({ domains: options,json:json });
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * Chiama il servizio per il controllo della disponibilità
     *
     */
    async checkEmailAvailable() {
        // Mostra lo spinner
        this.setState({ showSpinnerCheckEmail: true, });

        try {
            // Chiama il servizio
            const response = await fetch(this.props.config.url_check_pec_domain + '/' + this.state.email + '/' + this.state.domain);

            if (!response.ok) {
                throw Error(response.statusText);
            }

            const json = await response.json();

            if (json.result == 0) {
                // Errore tecnico, qualcosa è andato storto con il servizio
                this.setState({ showTechnicalError: true, });
            } else if (!json.available) {

                // Salva il valore dell'ultima email
                this.lastEmail = this.state.email;

                // Cambia il place holder mettendo il messaggio di errore
                this.setState({
                    labelSubmitButton: this.props.config.verifica_disponibilita,
                    emailValid: false,
                });

                this.error_displayed = true;
                this.email_verified = false;
                this.email_not_available = true;
            } else {
                this.setState({
                    labelSubmitButton: this.props.config.prosegui,
                });
                this.email_verified = true;
                this.email_not_available = false;
            }

        } catch (error) {
            console.log(error);
        }

        // Nascondi lo spinner
        this.setState({ showSpinnerCheckEmail: false, });
    }

    hideMessage() {
        this.setState({ showTechnicalError: false, });
    }

    hideValidationMessage() {
        this.setState({ showValidationError: false, });
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    // --- EMAILSCAN

    /**
     * Recupera URL della pagina da mostrare come HTML statico
     *
     */
    async getProductDescBody(stripe_coupon) {
        let qs=''
        if (stripe_coupon) {
            qs= '?stripe_coupon=' + stripe_coupon;
        }
        await fetch(this.props.config.url_load_product + '/' + this.props.locale + '/' + this.props.idProduct+qs)
            .then(response => {
                return response.json();
            })
            .then(product => {
                //Fetched product is stored in the state
                let ready = false ; // = this.checkEmailValid(this.myForm.current.email.value, this.myForm.current.domain.value)

                this.setState({
                    domain: this.myForm.current.domain.value,
                    product: product,
                    inLoading: false,
                    errorOnInvalidSubmit: ready ? '' : this.props.config.completa_il_form_per_proseguire,
                });
            });
    }

    toggleSwitch = () => {
        this.setState(prevState => ({
          switchedOn: !prevState.switchedOn
        }));

        if(!this.state.switchedOn) {
            this.prooductNameSuffix="";
        } else {
            this.prooductNameSuffix=this.state.couponOffSuffix;
        }

        //this.fetchData();
        // aggiorno sessione e carico HTML del prodotto
        this.handleUpdateSession();
    };

    handleUpdateSession = async () => {

        // mostro lo spinner
        this.setState({ showSpinnerLoadProductDesc: true, });

        try {
            const response = await fetch('/product/update/'+this.props.config.stripe_coupon+this.prooductNameSuffix+"/"+this.productName, {
                method: 'GET',
            });
            if (response.ok) {
                //console.log('Session variable updated successfully');
                // ottengo URL del prodotto/coupon da mosteare
                this.getProductDescBody(this.props.config.stripe_coupon+this.prooductNameSuffix);
                this.loadurlRef.componentDidMount;
                this.setState(curState=>({
                    product: {
                        ...curState.product,
                        // url_description:"http://namirialmpservice.test:8888/static/it/professional_PROFESSIONALEMAILSCANOFF.html"
                    },
                }));

            } else {
                console.error('Failed to update session variable');
            }
        } catch (error) {
            console.error('Error:', error);
        }
        // nascondo lo spinner
        this.setState({ showSpinnerLoadProductDesc: false, });
    };
    // ----



    render() {
        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.props.config.compra_una_casella}</h5>

                            {!this.state.inLoading ?
                                (
                                    <React.Fragment>
                                        {!this.state.spidmail && (
                                            <h3>{this.state.product.name}</h3>
                                        )}
                                        <div className='mb-3'>
                                            {/* Aggiunta del componente descrizione */}
                                            <LoadUrlDescription
                                                url={this.state.product.url_description}
                                                ref={this.loadurlRef}
                                                message={isNamirial && this.props.config.namirial_spid_help || ""} />

                                            {/* {(this.props.config.stripe_coupon && this.toggleIsVisible) && (
                                                <>
                                                <div className='text-center'><SpinnerCheckEmail show={this.state.showSpinnerLoadProductDesc} /></div>
                                                <SwitchChoice
                                                    switch_label="Aggiungi la protezione della tua PEC con EmailScan"
                                                    switch_description="incluso nel prezzo per il primo anno, potrai disabilitarlo in qualsiasi momento"
                                                    switch_modal_target="#emailscan_description"
                                                    switch_modal_text="cos'è"
                                                    product_name={this.productName}
                                                    stripe_coupon={this.props.config.stripe_coupon}
                                                    switchedOn={this.state.switchedOn}
                                                    on_change={this.toggleSwitch}
                                                ></SwitchChoice>
                                                </>
                                            )} */}

                                        </div>
                                    </React.Fragment>



                                ) : (
                                    <React.Fragment>
                                        <div className="card-body text-center mx-auto">
                                            <div className="spinner-border m-3" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }

                            <span className={this.state.classPlaceholder}>
                                <form action={this.props.config.url_pec_selection} method="post" className="form-inline form_flex" onSubmit={this.handleSubmit} ref={this.myForm}>
                                    <input type="hidden" name="_token" value={this.state.token} />

                                    <div className="d-flex">
                                        <label htmlFor="email" className="col-form-label font-weight-bold">{this.props.config.scegli_indirizzo_pec}</label>
                                    </div>

                                    <div className="d-flex">
                                        <input type="text"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            onFocus={this.handleFocus}
                                            className="form-control mb-2 mr-sm-2 flex-grow-1"
                                            maxLength={this.props.config.LOCALPART_LENGTH_MAX}
                                            placeholder={this.state.labelPlaceholder}
                                            tabIndex="1" />

                                        <div className="input-group-text at_value mr-sm-2">@</div>

                                        <select
                                            name="domain"
                                            value={this.state.domain}
                                            onChange={this.handleChange}
                                            onFocus={this.handleFocus}
                                            className="custom-select fix_domain_select"
                                            tabIndex="2" >
                                            {/*
                                            {!isSpidmail && <option key="0" value="notselected">{this.props.config.domini_disponibili}</option>}
                                            */}
                                            <option key="0" value="notselected">{this.props.config.domini_disponibili}</option>
                                            {/*{this.state.domains}*/}
                                            {
                                                this.state.json &&
                                                this.state.json.map((data,index ) =>
                                                    <option key={data.id} value={data.id}>
                                                    {data.name}
                                                    </option>
                                                )
                                            }
                                        </select>
                                    </div>

                                    <div className="invalid-feedback text-left">
                                        {this.props.config.indirizzo_email_non_valido}
                                    </div>

                                    <AlertWarning message={this.props.config.problemi_tecnici_in_verifica} show={this.state.showTechnicalError} hideClick={this.hideMessage} />

                                    <AlertWarning message={this.props.config.invalid_mail} extraAlert={this.state.extraAlert} show={this.state.showValidationError} hideClick={this.hideValidationMessage} />

                                    <div className={
                                        this.email_verified ? "choose_email_submit_container w-100 text-center alert alert-success" :
                                            (this.email_not_available ? "choose_email_submit_container w-100 text-center alert alert-danger" : "choose_email_submit_container w-100 text-center")
                                    }>

                                        {this.email_verified && (
                                            <p className="text-center py-1" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                </svg>&nbsp;
                                                La casella <b>{this.state.email}@{this.state.domain}</b> è disponibile<br />
                                                Prosegui per richiedere l'attivazione
                                            </p>
                                        )}
                                        {this.email_not_available && (
                                            <p className="text-center py-1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                                                </svg>&nbsp;
                                                La casella <b>{this.state.email}@{this.state.domain}</b> non è disponibile
                                            </p>
                                        )}

                                        <button type="submit" id="verifica" className="btn btn-primary btn-lg"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-animation="true"
                                            data-content={this.state.errorOnInvalidSubmit}
                                            data-trigger="hover"
                                            data-template={this.getPopoverCustomTemplate()}
                                            tabIndex="3">
                                            <SpinnerCheckEmail show={this.state.showSpinnerCheckEmail} />&nbsp;{this.state.labelSubmitButton}
                                        </button>
                                        {this.email_verified && (
                                            <p className="text-center py-1" >
                                                Nel prossimo passaggio finalizzeremo la richiesta di attivazione: per firmarla dovrai inserire il codice che ti invieremo via SMS.
                                            </p>
                                        )}
                                    </div>
                                </form>
                            </span>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe FormBuyProduct


/**
 * Mostra uno spinner boostrap o non mostra niente in base al valore di <i>props.show</i>
 *
 */
class SpinnerCheckEmail extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.show === true) {
            return (
                <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
            );
        } else {
            return null;
        }
    }
} // Fine classe SpinnerCheckEmail

if (document.getElementById('form_choose_email')) {
    $(function () {
        $('[data-toggle="popover"]').popover()
    })

    ReactDOM.render(<FormChooseEmail config={config} idProduct={product_id} locale={locale} />, document.getElementById('form_choose_email'));
}
