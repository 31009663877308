import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import InputText from '../common/InputText';
import InputTextMobile from '../common/InputTextMobile';
import SelectCity from '../common/SelectCity';
import SelectProvince from '../common/SelectProvince';
import SelectRegion from '../common/SelectRegion';
import ClientTypeField from './ClientTypeField';

import { buildMessagesFromStructure } from '../common/validation_buildErrorMessages';
import SelectClasseCom from "./SelectClasseCom";
import SelectCitySayt from "../common/SelectCitySayt";
import CodiceFiscale from "codice-fiscale-js";
const pvt="PrivateCitizen"
const freelance = "Freelance";


export default class FormBilling extends Component {
    constructor(props) {
        super(props);
        // Recupera il token per l'invio della form
        const token = csrfToken();

        this.myForm = React.createRef();

        // Crea l'elenco degli errori
        var errorMessages = buildMessagesFromStructure(this.props.errors);

        // Indica se il dato è in modifica o creazione
        var dataModify = false;

        if (this.props.billingData.idBuyer != 0) {
            dataModify = true;
        }

        var cognome_old = this.props.billingData['ownerType'] == pvt ? this.props.billingData['surname'] : '';
        var ragione_sociale_old = this.props.billingData['ownerType'] != pvt ? this.props.billingData['surname'] : '';
        var ownerType_old = this.props.billingData['ownerType'];

        this.state = {
            billingData: this.props.billingData,
            token: token,
            errorMessages: errorMessages,
            dataModify: dataModify,
            cognome_old: '' + cognome_old,
            ragione_sociale_old: '' + ragione_sociale_old,
            ownerType_old: ownerType_old,
            comModify: !(this.props.billingData.classeCom == '' || this.props.billingData.classeCom == undefined)

        };

        this.handleChange = this.handleChange.bind(this);
        console.log(this.state.billingData)
    }
    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {

        // Recuper nome e valore dall'evento
        const target = event.target;
        const name = target.name;
        const value = target.value;

        // Assegna i valori per i sotto oggetti.
        // React non permette l'asseggnamento a propietà di sotto oggetti
        // si deve clonare l'oggetto, modificare il valore e assegnare l'intero oggetto
        if (name.startsWith("billingData")) {
            // Assegna i valori all'oggetto billingData
            var varName = name.substring(12);

            // Assegna il valore
            const { billingData, errorMessages } = { ...this.state };
            billingData[varName] = value;
            // Elimina anche l'eventuale messaggio di errore
            errorMessages[name] = '';

            if (name === 'billingData_ownerType') {
                billingData['clientType']=this.props.config.owner2Client[value];
                if (value == pvt) {
                    if (this.state.ownerType_old != pvt) {
                        billingData['surname'] = this.state.cognome_old;
                    }
                } else {
                    if (this.state.ownerType_old == pvt) {
                        billingData['surname'] = this.state.ragione_sociale_old;
                    }
                }

                this.state.ownerType_old = value;
            }

            if (name === 'billingData_surname') {
                if (this.state.ownerType_old == pvt) {
                    this.state.cognome_old = billingData['surname'];
                } else {
                    this.state.ragione_sociale_old = billingData['surname'];
                }
            }


            // Se viene cambiata billingData_feCodDest o billingData_fePecDest resetta
            // l'eventuale errore anche sull'altro campo, se vuoto
            if (name === 'billingData_feCodDest') {
                if (typeof billingData['billingData_fePecDest'] === 'undefined') {
                    errorMessages['billingData_fePecDest'] = '';
                }
            }
            if (name === 'billingData_fePecDest') {
                if (typeof billingData['billingData_feCodDest'] === 'undefined') {
                    errorMessages['billingData_feCodDest'] = '';
                }
            }
            if (name ==='billingData_classeCom') {
                billingData['classeComText']=target.options[target.selectedIndex].innerText
            }
            if (name ==='billingData_taxCode' && value.length===16) {
                billingData['taxCode']=value.toString().toUpperCase();
                try {
                    const cf = new CodiceFiscale(value)
                    billingData['gender'] = cf.gender
                    billingData['birthCity'] = cf.birthplace.nome
                    billingData['birthDate'] = cf.birthday
                    billingData['birthProvince'] = cf.birthplace.prov || 'EE'
                    billingData['birthRegion'] = 99
                    billingData['birthZip'] = 99999
                }
                catch (e) {
                    console.log(e.toString())
                }
            }

            this.setState({
                billingData: billingData,
                errorMessages: errorMessages,

            });
        }

    }
    render() {
         return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">

                            <h5 className="card-title">{this.props.config.dati_di_fatturazione}</h5>

                            <div className="alert alert-warning py-2" role="alert">
                                <p className="m-0">
                                    Se hai necessità di fatturare con <strong>split payment</strong> contatta la
                                    nostra <a target="_blank" className="view_link" href={this.props.config.url_assistenza}>assistenza</a> prima di effettuare l'ordine!
                                </p>
                            </div>

                            {this.props.pec_to_buy &&
                                <div className="alert alert-success py-2" role="alert">
                                    <p className="m-0">
                                        <b>Tipologia casella</b>: {this.props.itemPublicDetails.descrizione.estesa}
                                    </p>
                                    <p className="m-0">
                                        <b>Casella</b>: {this.props.pec_to_buy}
                                    </p>

                                    {this.props.itemPublicDetails.stripe_coupon.length == 0 ? (
                                        <p className="m-0">
                                            <b>Prezzo</b>: {this.props.itemPublicDetails.prezzo.prezzo} € / anno + Iva
                                        </p>
                                    ) : (
                                        <div>
                                            <p className="m-0">
                                                <b>Prezzo</b>: <del>{this.props.itemPublicDetails.prezzo.prezzo} € / anno + Iva </del>&nbsp;
                                                {(this.props.itemPublicDetails.prezzo.prezzo - this.props.itemPublicDetails.stripe_coupon.amount_off).toFixed(2)} € / anno + Iva (per il primo anno)
                                            </p>
                                        </div>
                                    )}
                                </div>
                            }

                            <form action={this.props.config.url_buyer_save} method="post" className="" onSubmit={this.handleSubmit} ref={this.myForm}>
                                <input type="hidden" name="_token" value={this.state.token} />
                                <input type="hidden" name="billingData_idBuyer" value={this.state.billingData.idBuyer} />
                                <input type="hidden" name="billingData_clientType" value={this.state.billingData.clientType} />
                                <input type="hidden" name="billingData_classeComText" value={this.state.billingData.classeComText} />

                                <ClientTypeField
                                    default={this.props.billingData.ownerType}
                                    label={this.props.config.tipologia_acquirente}
                                    dataModify={this.state.dataModify}
                                    value={this.state.billingData.ownerType}
                                    handleChange={this.handleChange}
                                    config={this.props.config}
                                    classeCom={this.props.billingData.classeCom}
                                    invalid_message={this.state.errorMessages.billingData_ownerType}
                                />

                                <SelectClasseCom
                                    dataModify={this.state.dataModify && this.state.comModify}
                                    default={this.state.billingData.classeComText}

                                    label={this.props.config.classecom_label}
                                    name={"billingData_classeCom"}
                                    value={this.state.billingData.classeCom}
                                    handleChange={this.handleChange}
                                    url_service={this.props.config.url_classecom}
                                    owner_type={this.state.billingData.ownerType}
                                    invalid_message={this.state.errorMessages.billingData_classeCom}
                                    convention_id={this.props.itemPublicDetails.prezzo.id_convenzione}
                                />

                                {this.state.billingData.ownerType === pvt && (
                                    <InputText
                                        value={this.state.billingData.name}
                                        name="billingData_name"
                                        handleChange={this.handleChange}
                                        label={this.props.config.nome}
                                        invalid_message={this.state.errorMessages.billingData_name}
                                        maxLength={this.props.config.NAME_LENGTH_MAX}
                                        readOnly={this.state.dataModify}
                                        required={true}
                                    />
                                )}
                                <InputText
                                    value={this.state.billingData.surname}
                                    name="billingData_surname"
                                    handleChange={this.handleChange}
                                    label={(this.state.billingData.ownerType === pvt) ? this.props.config.cognome : this.props.config.ragione_sociale}
                                    invalid_message={this.state.errorMessages.billingData_surname}
                                    maxLength={this.props.config.SURNAME_LENGTH_MAX}
                                    readOnly={this.state.dataModify}
                                    required={true}
                                    helpText={this.state.billingData.ownerType === freelance && 'es. Nome Cognome del titolare della Ditta Individuale'}
                                />

                                {this.state.billingData.ownerType != pvt && (
                                    <InputText
                                        value={this.state.billingData.piva}
                                        name="billingData_piva"
                                        handleChange={this.handleChange}
                                        label={this.props.config.partita_iva}
                                        invalid_message={this.state.errorMessages.billingData_piva}
                                        maxLength={this.props.config.PIVA_LENGTH_MAX}
                                        readOnly={this.state.dataModify}
                                        required={true}
                                    />
                                )}

                                <InputText
                                    value={this.state.billingData.taxCode}
                                    name="billingData_taxCode"
                                    handleChange={this.handleChange}
                                    label={
                                        (this.state.billingData.ownerType != pvt && this.state.billingData.ownerType != freelance)  ? this.props.config.codice_fiscale_aziendale : this.props.config.codice_fiscale}
                                    invalid_message={this.state.errorMessages.billingData_taxCode}
                                    maxLength={this.props.config.CF_LENGTH_MAX}
                                    readOnly={this.state.dataModify}
                                    required={true}
                                    forceUpper={true}
                                />

                                <InputText
                                    value={this.state.billingData.street}
                                    name="billingData_street"
                                    handleChange={this.handleChange}
                                    label={this.props.config.indirizzo}
                                    invalid_message={this.state.errorMessages.billingData_street}
                                    maxLength={this.props.config.STREET_LENGTH_MAX}
                                    required={true}
                                />

                                <SelectCitySayt
                                    name="billingData_city"
                                    value={this.state.billingData.city || ''}
                                    label={this.props.config.dettaglio_city}
                                    nameProvince="billingData_province"
                                    valueProvince={this.state.billingData.province || ''}
                                    labelProvince={this.props.config.provincia}
                                    nameRegion="billingData_region"
                                    not_selected={this.props.config.selezionare_citta}
                                    invalid_message={this.state.errorMessages.billingData_city}
                                    required={true}
                                    hide_province={true}
                                />
                                {/*
                                <SelectRegion
                                    value={this.state.billingData.region}
                                    valueCountry='IT'
                                    name="billingData_region"
                                    handleChange={this.handleChange}
                                    label={this.props.config.regione}
                                    not_selected={this.props.config.selezionare_regione}
                                    invalid_message={this.state.errorMessages.billingData_region}
                                    required={true}
                                />
                                <SelectProvince
                                    value={this.state.billingData.province}
                                    valueCountry={this.state.billingData.region}
                                    name="billingData_province"
                                    handleChange={this.handleChange}
                                    label={this.props.config.provincia}
                                    not_selected={this.props.config.selezionare_provincia}
                                    invalid_message={this.state.errorMessages.billingData_province}
                                    required={true}
                                />

                                <SelectCity
                                    valueProvince={this.state.billingData.province}
                                    value={this.state.billingData.city}
                                    name="billingData_city"
                                    handleChange={this.handleChange}
                                    label={this.props.config.citta}
                                    not_selected={this.props.config.selezionare_citta}
                                    invalid_message={this.state.errorMessages.billingData_city}
                                    required={true}
                                />
                                */}

                                <InputText
                                    value={this.state.billingData.zip}
                                    name="billingData_zip"
                                    handleChange={this.handleChange}
                                    label={this.props.config.cap}
                                    invalid_message={this.state.errorMessages.billingData_zip}
                                    maxLength={this.props.config.ZIP_LENGTH_MAX}
                                    required={true}
                                />

                                <InputText
                                    value={this.state.billingData.email}
                                    name="billingData_email"
                                    handleChange={this.handleChange}
                                    label={this.props.config.email}
                                    invalid_message={this.state.errorMessages.billingData_email}
                                    maxLength={this.props.config.EMAIL_LENGTH_MAX}
                                    required={true}
                                />

                                <InputTextMobile
                                    value={this.state.billingData.cell||'+39'}
                                    name="billingData_cell"
                                    handleChange={this.handleChange}
                                    label={this.props.config.cellulare}
                                    // placeHolder="Inserisci il numero senza prefisso"
                                    placeHolder={this.props.config.phone_number_no_prefix}
                                    invalid_message={this.state.errorMessages.billingData_cell}
                                    maxLength={this.props.config.CELL_LENGTH_MAX}
                                    required={true}
                                />

                                {this.state.billingData.ownerType != 'persona_fisica' && (
                                    <React.Fragment>
                                        <h4>{this.props.config.fattura_elettronica}</h4>

                                        <InputText
                                            value={this.state.billingData.feCodDest}
                                            name="billingData_feCodDest"
                                            handleChange={this.handleChange}
                                            label={this.props.config.codice_destinatario}
                                            invalid_message={this.state.errorMessages.billingData_feCodDest}
                                            maxLength={this.props.config.FECODDEST_LENGTH_MAX}
                                            helpText={'Se valorizzato con 0000000 la fattura elettronica è inviata alla PEC di notifica o, se non fornita, sul Cassetto Fiscale'}
                                        />

                                        <InputText
                                            value={this.state.billingData.fePecDest}
                                            name="billingData_fePecDest"
                                            handleChange={this.handleChange}
                                            label={this.props.config.pec_notifica}
                                            invalid_message={this.state.errorMessages.billingData_fePecDest}
                                            maxLength={this.props.config.FEPECDEST_LENGTH_MAX}
                                        />
                                    </React.Fragment>
                                )}

                                <div className="form-group text-center">
                                    <button type="submit" className="btn btn-primary btn-block mb-2 mt-3">
                                        {this.props.config.avanti}
                                    </button>
                                </div>

                                {this.props.hasBuyers && (
                                    <div className="mt-3 mb-3">
                                        <a href={this.props.config.url_buyer_select}>&laquo; {this.props.config.seleziona_altra_anagrafica_link}</a>
                                    </div>
                                )}

                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe FormBilling

if (document.getElementById('form_billing')) {
    var props = {
        config: config,
        billingData: billingData,
        errors: errors,
        hasBuyers: hasBuyers,
        pec_to_buy: pec_to_buy,
        itemPublicDetails: itemPublicDetails
    };
    var formBilling = React.createElement(FormBilling, props);
    ReactDOM.render(formBilling, document.getElementById('form_billing'))
}
