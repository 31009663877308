import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import InputText from '../common/InputText';
import ReCAPTCHA from "react-google-recaptcha";
import { validate } from 'validate.js';
import { buildMessagesFromStructure } from '../common/validation_buildErrorMessages';

export default class Conventions extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        var errorMessages = buildMessagesFromStructure(this.props.errors);

        // Gestisce il messaggio di errore del captcha
        var captcha_not_valid = false;
        if (typeof errorMessages.captcha_not_valid !== 'undefined') {
            captcha_not_valid = true;
        }

        var tokenCaptcha = '';
        if (this.props.captchaKey === '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI') {
            tokenCaptcha = 'fake_for_test';
        }

        this.state = {
            code: this.props.code,
            submitDisabled: true,
            popOverMessage: '',
            token: token,
            errorMessages: errorMessages,
            tokenCaptcha: tokenCaptcha,
            captcha_not_valid: captcha_not_valid,
            captchaKey: this.props.captchaKey,
        };

        this.hideMessageCaptcha = this.hideMessageCaptcha.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }

    handleChange(event) {

        // Nasconde i messaggi di errore
        /*
        if (this.state.showMessageConventionCodeNotValid === true) {
            this.setState({ showMessageConventionCodeNotValid: false, });
        }*/

        // Recupera nome e valore dall'evento
        const target = event.target;
        const value = target.name === 'privacy' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            code: value.toUpperCase(),
            status: ''
        });

        if (!this.isSubmitButtonEnabled(value, this.state.tokenCaptcha) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }
    }

    hideMessageCaptcha() {
        this.setState({ captcha_not_valid: false, });
    }

    onChangeCaptcha(value) {
        if (value == null) {
            value = '';
        }

        this.setState({ tokenCaptcha: value, });

        if (!this.isSubmitButtonEnabled(this.state.code, value) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }

    }

    checkValidConventionCode(code) {
        var regexp = new RegExp(this.props.config.CODICE_CONVENZIONE_REGEX);
        var constraints = {
            conventionCode: {
                //presence: true,
                format: {
                    pattern: regexp,
                    message: function (value, attribute, validatorOptions, attributes, globalOptions) {
                        return validate.format("^%{num} non è un codice convenzione valido", {
                            num: value
                        });
                    }
                },
            },
        };

        // Ritorna undefined se il codice convenzione è valido, altrimenti un messaggio di errore
        // {"conventionCode":["JJJj non è un codice convenzione valido"]}
        const result = validate({ conventionCode: code }, constraints);
        //console.log(JSON.stringify(result));

        return !(result !== undefined);
    }

    /**
     * Ritorna true se i valori code e password sono corretti
     *
     * @param {*} code
     * @param {*} tokenCaptcha
     */
    isSubmitButtonEnabled(code, tokenCaptcha) {
        if (this.checkValidConventionCode(code) && tokenCaptcha != '') {
            return true;
        } else {
            return false;
        }
    }

    handleOnMouseOver(event) {
        if (this.isSubmitButtonEnabled(this.state.code, this.state.tokenCaptcha)) {
            this.setState((state) => ({
                popOverMessage: '',
            }));
        } else {
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
        }
    }

    handleSubmit(event) {
        if (this.isSubmitButtonEnabled(this.state.code, this.state.tokenCaptcha)) {
            event.preventDefault();
            this.myForm.current.submit();
        } else {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            return false;
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {

        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.props.config.titolo_convenzioni}</h5>

                            {this.state.captcha_not_valid && (
                                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                    {this.props.config.verifica_captcha_ko}
                                    <button type="button" className="close" onClick={this.hideMessageCaptcha} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            )}

                            <form action={this.props.config.url_verify_code} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                <input type="hidden" name="_token" value={this.state.token} />
                                <input type="hidden" name="_token_captcha" value={this.state.tokenCaptcha} />

                                <InputText
                                    name="codice_convenzione"
                                    handleChange={this.handleChange}
                                    value={this.state.code}
                                    config={this.props.config}
                                    maxLength={this.props.config.CODICE_CONVENZIONE_LENGTH_MAX}
                                    invalid_message={this.state.errorMessages.codice_convenzione}
                                    required={true} />

                                <ReCAPTCHA
                                    sitekey={this.state.captchaKey}
                                    onChange={this.onChangeCaptcha}
                                    hl={this.props.config.locale}
                                />

                                <div className="form-group py-4">
                                    <button type="submit" id="popover-submit"
                                        className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-animation="true"
                                        data-content={this.state.popOverMessage}
                                        data-trigger="hover"
                                        data-template={this.getPopoverCustomTemplate()}
                                        tabIndex="3">
                                        {this.props.config.attiva_convenzione}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

if (document.getElementById('form_convenzioni-generiche')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<Conventions code={code} captchaKey={captchaKey} errors={errors} config={config} />, document.getElementById('form_convenzioni-generiche'));
}
