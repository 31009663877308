import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { formPost } from './common/form-post-utility';
import { csrfToken } from './common/csrf-loader';
import LoadUrlDescription from './common/LoadUrlDescription';

export default class StaticProductPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            //product: this.props.product,
            inLoading: true,
        }
    }

    /**
     * Metodo chiamato dopo il render
     *
     * Carica i prodotti e modifica lo stato
     *
     */
    componentDidMount() { }

    renderProducts() {
        if (this.props.product.length == 0) {

            // Se non ci sono prodotti mostrare un messaggio
            return <div>{this.props.config.nessun_prodotto_trovato}</div>;

        } else {

            return (
                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h3 className="mb-4 card-title">{this.props.config.title}</h3>

                            <div className="row" key='1'>
                                <div className="col-lg landing_product_container" key={this.props.product.id}>
                                    <StaticProduct url_select_product={this.props.config.url_select_product} product={this.props.product} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            );

        }

    } // Fine render product

    render() {
        return (
            <React.Fragment>
                { this.renderProducts()}
            </React.Fragment>
        );
    }

}

class StaticProduct extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="landing_product_body">
                    {/* Aggiunta del componente descrizione */}
                    <LoadUrlDescription url={this.props.product.url_description} />
                </div>
            </React.Fragment>
        );
    }

}

// Crea un componente a sostiutuzione di un div
if (document.getElementById('form_static_product')) {
    ReactDOM.render(<StaticProductPage config={config} product={product} />, document.getElementById('form_static_product'));
}
