import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import { checkValidEmailName } from '../common/email-validation';
import EmailInput from '../common/EmailInput';
import AlertWarning from '../common/AlertWarning';
import PrivacyField from './PrivacyField';
import { buildMessagesFromStructure } from '../common/validation_buildErrorMessages';

export default class FormImportPecSelect extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        var errorMessages = buildMessagesFromStructure(this.props.errors);
        var showNotImportableMessageError = false;
        var notImportableMessage = '';

        if (typeof errorMessages.email_is_not_importable !== 'undefined'){
            showNotImportableMessageError = true;
            notImportableMessage = errorMessages.email_is_not_importable;
        }

        if (typeof errorMessages.email !== 'undefined'){
            showNotImportableMessageError = true;
            notImportableMessage = errorMessages.email;
        }

        this.state = {
            email: this.props.email,
            privacy: true,
            submitDisabled: true,
            popOverMessage: '',
            token: token,
            errorMessages: errorMessages,
            notImportableMessage: notImportableMessage,
            showNotImportableMessageError: showNotImportableMessageError,
        };

        this.hideNotImportableMessage = this.hideNotImportableMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    hideNotImportableMessage() {
        this.setState({ showNotImportableMessageError: false, });
    }

    handleChange(event) {

        // Nasconde i messaggi di errore
        if (this.state.showNotImportableMessageError === true) {
            this.setState({ showNotImportableMessageError: false, });
        }

        // Recupera nome e valore dall'evento
        const target = event.target;
        const value = target.name === 'privacy' ? target.checked : target.value;
        const name = target.name;

        // setState è ascincrono, quindi devo considerare il valore separatamente
        var myEmail = this.state.email;
        var myPrivacy = this.state.privacy;

        // Aggiorno il valore attualmente modificato
        if (name == "email") {
            myEmail = value;
        } else if (name == "privacy") {
            myPrivacy = value;
        }

        // Assegna il valore del campo
        this.setState({ [name]: value });

        if (!this.isSubmitButtonEnabled(myEmail, myPrivacy) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }
    }

    /**
     * Ritorna true se i valori email e privacy sono corretti
     */
    isSubmitButtonEnabled(email, privacy) {
        // Email valida, privacy checked
        if (checkValidEmailName(email) && privacy ) {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit(event) {
        //console.log('handleSubmit ' + this.state.popOverMessage);
        var email = this.state.email;

        if (!this.isSubmitButtonEnabled(this.state.email, this.state.privacy) ) {
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            event.preventDefault();
            return false;
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {

        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">

                            <h5 className="card-title">{this.props.config.label_title_importa_account}</h5>

                            {/* Descrizione flusso di import */}
                            <div>
                                <div className="py-1 text-justify">{this.props.config.descrizione_import_1}</div>
                                <div className="py-1 pb-3 text-justify">{this.props.config.descrizione_import_2}</div>
                            </div>

                            <form action={this.props.config.url_register_import} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                <input type="hidden" name="_token" value={this.state.token} />

                                <EmailInput
                                    handleChange={this.handleChange}
                                    value={this.state.email}
                                    config={this.props.config} />

                                {/*
                                <PrivacyField
                                    value={this.state.privacy}
                                    handleChange={this.handleChange}
                                    url={this.props.config.url_pdf_privacy}
                                    config={this.props.config}
                                />
                                */}

                                <AlertWarning message={this.state.notImportableMessage} show={this.state.showNotImportableMessageError} hideClick={this.hideNotImportableMessage} />

                                <div className="form-group py-4">
                                    <button type="submit" id="popover-submit"
                                        className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-animation="true"
                                        data-content={this.state.popOverMessage}
                                        data-trigger="hover"
                                        data-template={this.getPopoverCustomTemplate()}
                                        tabIndex="3">
                                        {this.props.config.label_importa_la_pec}
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe FormImportPecSelect

if (document.getElementById('form_import')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<FormImportPecSelect config={config} locale={locale} email={email} errors={errors} />, document.getElementById('form_import'));
}

