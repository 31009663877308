import React, { Component } from 'react';

export default class SwitchChoice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>

        <div className="custom-control custom-switch">
            <input
                type="checkbox"
                className="custom-control-input"
                id="serviceSwitch"
                checked={this.props.switchedOn}
                // checked={this.state.switchedOn}
                // onChange={this.toggleSwitch}
                onChange={this.props.on_change}
            />
            <label className={`custom-control-label slider ${this.props.switchedOn ? 'on' : 'off'}`} htmlFor="serviceSwitch">
                {this.props.switch_label}
                {"" != this.props.switch_modal_target && (
                    <> (<a href="#" data-toggle="modal" data-target={this.props.switch_modal_target}>{this.props.switch_modal_text}</a>)</>
                )}
                {"" != this.props.switch_description && (<em><br/>{this.props.switch_description}</em>)}
            </label>
        </div>
        {/* <p>The switch is {this.props.switchedOn ? 'on' : 'off'}</p> */}
      </div>
    );
  }
}


