import React, { Component } from 'react';
import InputText from '../common/InputText';
import { data } from 'jquery';

/**
 * Mostra un elenco di regioni recuperate da un servizio a partire dal codice del paese
 * in un tag select/option oppure mostra un campo input/text se il servizio non torna
 * alcun valore
 *
 */
export default class SelectRegion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            provinces: [],
        };

        this.countryInUse = this.props.valueCountry || 'IT';
    }

    componentDidMount() {
        // Chiama il servizio rest e assegna i dati allo stato
        this.loadProvinces();
    }

    componentDidUpdate() {
        if (this.props.valueCountry != this.countryInUse) {
            this.loadProvinces();
            this.countryInUse = this.props.valueCountry;
        }
    }

    async loadProvinces() {
        try {
            let country = 'IT';
            // if (this.props.valueCountry == '') {
            //     this.setState({ provinces: [] });
            // } else {
            // const response = await fetch('/api/list_regions/' + this.props.valueCountry);
            const response = await fetch('/api/list_regions/' + country);
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const json = await response.json();
            this.setState({ provinces: json });
            // }
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        // Controlla la validità
        var classValidation = '';
        // cambia la classe di validazione
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }

        var requiredClass = '';
        if (this.props.required) {
            requiredClass = 'nam_required';
        }

        let arrayOfData = this.state.provinces;
        let options = arrayOfData.map((data) =>
            <option key={data.id} value={data.id}>
                {data.name}
            </option>
        );

        if (this.props.readOnly) {
            let value = "";
            arrayOfData.forEach((data) => {
                if (data.id == this.props.value) {
                    value = data.name
                }
            })

            return (
                <React.Fragment>
                    <input type="hidden" name={this.props.name} value={this.props.value} />
                    <InputText
                        label={this.props.label}
                        value={value}
                        readOnly={true}
                        required={true}
                    />
                </React.Fragment>
            )
        }

        if (arrayOfData.length != 0) {
            return (
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                        <select
                            name={this.props.name}
                            value={this.props.value || ''}
                            onChange={this.props.handleChange}
                            className={'custom-select ' + classValidation + ' ' + requiredClass}>
                            <option value=''>{this.props.not_selected}</option>
                            {options}
                        </select>
                        <div className="invalid-feedback">
                            {this.props.invalid_message}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                        <input type="text"
                            name={this.props.name}
                            value={this.props.value || ''}
                            onChange={this.props.handleChange}
                            className={'form-control ' + classValidation + ' ' + requiredClass}
                        />
                        <div className="invalid-feedback">
                            {this.props.invalid_message}
                        </div>
                    </div>
                </div>
            );
        }

    }
}
