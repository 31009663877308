import React, { Component } from 'react';
import SelectDate from '../common/SelectDate';
import InputText from '../common/InputText';
import SelectCitySayt from "../common/SelectCitySayt";

export default class DetailsCons extends Component {

    constructor(props) {
        super(props);
        this.state = {
            OWNERTYPE_PRIVATECITIZEN: "PrivateCitizen",
            OWNERTYPE_FREELANCE: "Freelance",
            OWNERTYPE_BUSINESS: "Business",
            OWNERTYPE_NONCOMMERCIALENTITIES: "NonCommercialEntities",
            OWNERTYPE_PA: "PA",
        };
    }

    render() {
        return (
            <React.Fragment>

                {/** Pec da attivare per privato o azienda, si compila in automatico server side
                {
                    (this.props.clientTypeId === this.state.OWNERTYPE_BUSINESS
                        || this.props.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                        || this.props.clientTypeId === this.state.OWNERTYPE_PA)
                    && (

                        <React.Fragment>

                            <InputText
                                value={this.props.detailsCons.ownerEmail}
                                name="detailsCons_ownerEmail"
                                handleChange={this.props.handleChange}
                                label="Indirizzo email"
                                invalid_message={this.props.errorMessages.detailsCons_ownerEmail}
                                maxLength={this.props.config.PEO_MAX_LENGTH}
                                required={true}
                            />

                            <InputText
                                value={this.props.detailsCons.ownerPec}
                                name="detailsCons_ownerPec"
                                handleChange={this.props.handleChange}
                                label="Posta certificata"
                                invalid_message={this.props.errorMessages.detailsCons_ownerPec}
                                maxLength={this.props.config.PEC_MAX_LENGTH}
                                required={true}
                            />

                            <div className="form-group">
                                <InputText
                                    value={this.props.detailsCons.businessPec}
                                    name="detailsCons_businessPec"
                                    handleChange={this.props.handleChange}
                                    label="Posta certificata azienda"
                                    invalid_message={this.props.errorMessages.detailsCons_businessPec}
                                    maxLength={this.props.config.PEC_MAX_LENGTH}
                                    required={true}
                                />
                            </div>

                        </React.Fragment>
                    )
                }
                */}

                { this.props.clientTypeId === this.state.OWNERTYPE_FREELANCE && (
                    <React.Fragment>

                        <SelectDate
                            name="detailsCons_birthDate"
                            selected={this.props.detailsCons.birthDate}
                            onChange={this.props.handleBirthDateChange}
                            invalid_message={this.props.errorMessages.detailsCons_birthDate}
                            label={this.props.config.dettaglio_data_nascita}
                            required={true}
                            readOnly={true}
                        />
                        <SelectCitySayt
                            name="detailsCons_birthCity"
                            value={this.props.detailsCons.birthCity}
                            label={this.props.config.dettaglio_luogo_nascita}
                            valueProvince={this.props.detailsCons.birthProvince}
                            nameProvince="detailsCons_birthProvince"
                            labelProvince={this.props.config.dettaglio_provincia_nascita}
                            handleChange={this.props.handleChange}
                            not_selected={this.props.config.selezionare_citta}
                            invalid_message={this.props.errorMessages.detailsCons_birthCity}
                            required={true}
                            readOnly={true}
                            hide_province={true}
                        />
                        <input type={"hidden"} name={"detailsCons_birthZip"} value={this.props.detailsCons.birthZip} />

                    </React.Fragment>
                )}

            </React.Fragment>
        );
    }

}
