import React, { Component } from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input/input'
import { getNumberType } from 'libphonenumber-js'
import it from 'react-phone-number-input/locale/it.json'
import { isUndefined } from 'lodash';

export default class InputPhone extends Component {
    constructor(props) {
        super(props);

        let showError = false
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            showError = true
        }

        this.state = {
            phone: this.props.value.replace(/ /g, ''),
            showError: showError,
            errorText: this.props.invalid_message
        };

        this.handlePhoneChange = this.handlePhoneChange.bind(this); // Binding di this
    }

    handlePhoneChange = (value) => {
        let errorText = value

        if(value){
            if(isValidPhoneNumber(value)){
                let numberType = getNumberType(value, 'IT')
                if(this.props.onlyMobile && numberType !== 'MOBILE'){
                    errorText = 'Inserire un numero di telefono cellulare'
                } else {
                    errorText = undefined
                }
            } else {
                errorText = 'Numero di telefono non valido'
            }
        } else {
            errorText = 'Inserire un numero di telefono'
        }

        let showError = isUndefined(errorText) ? false : true

        // Rimuovi tutto tranne i numeri dopo il prefisso e limita la lunghezza a 10 cifre
        this.setState({ phone: value });
        this.setState({ showError: showError }); // Nasconde l'errore quando il numero non è vuoto
        this.setState({ errorText: errorText });
    };

    render() {
        var classValidation = '';
        var requiredClass = '';

        // Controlla la validità
        if (this.state.showError) {
            classValidation = 'is-invalid';
        }

        // Verifica su required
        if (this.props.required) {
            requiredClass = 'nam_required';
        }

        return (
            <div className="form-group row">
                <div className={"col-sm-12"}>
                    <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                    <div className="input-group">
                        <PhoneInput
                            value={this.state.phone}
                            onChange={this.handlePhoneChange}
                            className={'form-control ' + classValidation + ' ' + requiredClass}
                            label={this.props.cellulare}
                            country="IT"
                            international={true}
                            withCountryCallingCode={true}
                            readOnly={this.props.readOnly}
                            maxLength={this.props.maxLength}
                            required={true}
                        />
                        <input type="hidden" name={this.props.name} value={this.state.phone} readOnly/>

                        {/* Condizione per mostrare il messaggio di errore */}
                        {this.state.showError && (
                        <div className="invalid-feedback" data-testid="message">
                            {this.state.errorText}
                        </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

