import React, { Component } from 'react';
import InputText from '../common/InputText';
import InputTextMobile from '../common/InputTextMobile';
import SelectCity from '../common/SelectCity';
import SelectProvince from '../common/SelectProvince';
import SelectRegion from '../common/SelectRegion';
import SelectCitySayt from "../common/SelectCitySayt";

export default class DetailsPecBusiness extends Component {

    constructor(props) {
        super(props);

        // Indica se il dato è in modifica o creazione
        var dataModify = false;
        if (this.props.detailsPec.idOwner != 0) {
            dataModify = true;
        }

        this.state = {
            dataModify: dataModify,
            OWNERTYPE_PRIVATECITIZEN: "PrivateCitizen",
            OWNERTYPE_FREELANCE: "Freelance",
            OWNERTYPE_BUSINESS: "Business",
            OWNERTYPE_NONCOMMERCIALENTITIES: "NonCommercialEntities",
            OWNERTYPE_PA: "PA",
        };

        //TODO debug:rimuovere
        console.log("formType: PecBusiness");
    }

    render() {

        return (
            <React.Fragment>

                <InputText
                    value={this.props.detailsPec.businessName}
                    name="detailsPec_businessName"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_ragione_sociale}
                    invalid_message={this.props.errorMessages.detailsPec_businessName}
                    maxLength={this.props.config.BUSINESSNAME_MAX_LENGTH}
                    required={true}
                    readOnly={this.state.dataModify}
                />

                <InputText
                    value={this.props.detailsPec.vatNumber}
                    name="detailsPec_vatNumber"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_partita_iva}
                    invalid_message={this.props.errorMessages.detailsPec_vatNumber}
                    maxLength={this.props.config.VATNUMBER_MAX_LENGTH}
                    readOnly={this.state.dataModify}
                />

                {
                    (this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_BUSINESS
                        || this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                        || this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_PA)
                    && (

                        <React.Fragment>
                            <InputText
                                value={this.props.detailsPec.taxCodeCompany}
                                name="detailsPec_taxCodeCompany"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_codice_fiscale_azienda}
                                invalid_message={this.props.errorMessages.detailsPec_taxCodeCompany}
                                maxLength={this.props.config.TAXCODECOMPANY_MAX_LENGTH}
                                readOnly={this.state.dataModify}
                            />

                            <InputText
                                value={this.props.detailsPec.businessStreet}
                                name="detailsPec_businessStreet"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_indirizzo}
                                invalid_message={this.props.errorMessages.detailsPec_businessStreet}
                                maxLength={this.props.config.STREET_MAX_LENGTH}
                                required={true}
                            />
                            <SelectCitySayt
                                name="detailsPec_businessCity"
                                value={this.props.detailsPec.businessCity || this.props.detailsPec.city || ''}
                                label={this.props.config.dettaglio_city}
                                nameProvince="detailsPec_businessProvince"
                                valueProvince={this.props.detailsPec.businessProvince || this.props.detailsPec.province || ''}
                                labelProvince={this.props.config.provincia}
                                nameRegion="detailsPec_businessRegion"
                                not_selected={this.props.config.selezionare_citta}
                                invalid_message={this.props.errorMessages.detailsPec_city}
                                required={true}
                                hide_province={true}
                            />
                            <InputText
                                value={this.props.detailsPec.businessZip}
                                name="detailsPec_businessZip"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_zip}
                                invalid_message={this.props.errorMessages.detailsPec_businessZip}
                                maxLength={this.props.config.ZIP_MAX_LENGTH}
                                required={true}
                            />

                            <InputText
                                value={this.props.detailsPec.businessEmail}
                                name="detailsPec_businessEmail"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_extd_email_azienda}
                                invalid_message={this.props.errorMessages.detailsPec_businessEmail}
                                maxLength={this.props.config.PEO_MAX_LENGTH}
                                helpText={this.props.config.dettaglio_extd_email_azienda_help}
                                required={true}
                            />

                            <InputTextMobile
                                value={this.props.detailsPec.businessMobileNumber|| '+39'}
                                name="detailsPec_businessMobileNumber"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_cellulare}
                                placeHolder={this.props.config.phone_number_no_prefix}
                                invalid_message={this.props.errorMessages.detailsPec_businessMobileNumber}
                                maxLength={this.props.config.CELL_MAX_LENGTH}
                                helpText={this.props.config.dettaglio_cellulare_help}
                                required={true}
                            />

                            {/* <InputText
                                value={this.props.detailsPec.businessMobileNumber}
                                name="detailsPec_businessMobileNumber"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_cellulare}
                                placeHolder="+39"
                                invalid_message={this.props.errorMessages.detailsPec_businessMobileNumber}
                                maxLength={this.props.config.CELL_MAX_LENGTH}
                                helpText={this.props.config.dettaglio_cellulare_help}
                                required={true}
                            />                             */}

                            {
                                this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_PA && (
                                    <React.Fragment>
                                        <InputText
                                            value={this.props.detailsPec.IPACode}
                                            name="detailsPec_IPACode"
                                            handleChange={this.props.handleChange}
                                            label={this.props.config.dettaglio_codice_ipa}
                                            invalid_message={this.props.errorMessages.detailsPec_IPACode}
                                            maxLength={this.props.config.IPACODE_MAX_LENGTH}
                                            readOnly={this.state.dataModify}
                                        />
                                    </React.Fragment>
                                )
                            }

                            <h5>{this.props.config.rappresentante_legale}</h5>

                            <InputText
                                value={this.props.detailsPec.name}
                                name="detailsPec_name"
                                label={this.props.config.dettaglio_nome}
                                handleChange={this.props.handleChange}
                                invalid_message={this.props.errorMessages.detailsPec_name}
                                maxLength={this.props.config.NAME_LENGTH_MAX}
                                required={true}
                                readOnly={this.state.dataModify}
                            />

                            <InputText
                                value={this.props.detailsPec.surname}
                                name="detailsPec_surname"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_cognome}
                                invalid_message={this.props.errorMessages.detailsPec_surname}
                                maxLength={this.props.config.SURNAME_MAX_LENGTH}
                                required={true}
                                readOnly={this.state.dataModify}
                            />

                            <InputText
                                value={this.props.detailsPec.taxCode}
                                name="detailsPec_taxCode"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_codice_fiscale}
                                invalid_message={this.props.errorMessages.detailsPec_taxCode}
                                maxLength={this.props.config.TAXCODE_MAX_LENGTH}
                                required={true}
                                readOnly={this.state.dataModify}
                            />

                            <input type="hidden" name="detailsPec_businessCountry" value={this.props.detailsPec.businessCountry} />
                        </React.Fragment>
                    )
                }

            </React.Fragment>
        );
    }
}
