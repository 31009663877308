import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import EmailInput from '../common/EmailInput';
import { checkValidEmailName } from '../common/email-validation';
import ReCAPTCHA from "react-google-recaptcha";

export default class ResetPasswordEmail extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef()

        // Recupero i dati di configurazione
        this.config = this.props.config;

        // Gestisce il messaggio di errore del captcha
        var captcha_not_valid = false;
        if (this.props.errors.includes("captcha_not_valid")) {
            captcha_not_valid = true;
        }

        var tokenCaptcha = '';
        if (this.props.captchaKey === '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI') {
            tokenCaptcha = 'fake_for_test';
        }

        this.state = {
            email: '',
            status: this.props.status,
            showSpinnerCheck: false,
            showMessageCredentialsNotValid: false,
            submitDisabled: true,
            popOverMessage: '',
            token: token,
            tokenCaptcha: tokenCaptcha,
            captcha_not_valid: captcha_not_valid,
            captchaKey: this.props.captchaKey,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }

    handleChange(event) {
        // Se il messaggio è visibile nascondilo
        if (this.state.showMessageCredentialsNotValid === true) {
            this.setState({ showMessageCredentialsNotValid: false, });
        }

        // Recupero nome e valore dall'evento
        const value = event.target.value;
        //const sumbitDisabledValue = !this.isSubmitButtonEnabled(value, this.state.tokenCaptcha);

        this.setState({
            email: value,
            status: ''
        });

        if (!this.isSubmitButtonEnabled(value, this.state.tokenCaptcha) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }
    }

    hideMessageCaptcha() {
        this.setState({ captcha_not_valid: false, });
    }

    onChangeCaptcha(value) {
        if (value == null) {
            value = '';
        }

        this.setState({ tokenCaptcha: value, });

        if (!this.isSubmitButtonEnabled(this.state.email, value) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }

    }

    /**
     * Ritorna true se i valori email e password sono corretti
     *
     * @param {*} myEmail
     */
    isSubmitButtonEnabled(email, tokenCaptcha) {
        if (checkValidEmailName(email) && tokenCaptcha != '') {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit(event) {
        if (this.isSubmitButtonEnabled(this.state.email, this.state.tokenCaptcha)) {
            event.preventDefault();
            this.myForm.current.submit();
        } else {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            return false;
        }
    }

    /**
     * Nasconde il messaggio di avvertimento credenziali non valide.
     * Il metodo è necessario per permettere di gestire il bottone di chiusura dentro il componente MessageCredentialsNotValid
     *
     */
    hideMessage() {
        this.setState({ showMessageCredentialsNotValid: false, });
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {

        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.props.config.reset_password}</h5>
                            <div>

                                {this.state.status === 'email_sent' && (
                                    <div className="reset-password-email-sent">
                                        <div>
                                            {this.props.config.abbiamo_inviato_istruzioni}
                                        </div>
                                        <div className="mb-2 mt-2">
                                            {this.props.config.contatta_helpdesk} <a className="view_link" href={this.props.config.helpdesk_url} target="_blank">helpdesk</a>
                                        </div>
                                    </div>
                                )}

                                {this.state.status === 'email_fail' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.errore_in_spedizione_messaggio}
                                    </div>
                                )}

                                {this.state.status === 'invalid_email' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.indirizzo_non_valido}
                                    </div>
                                )}

                                {this.state.status === 'email_throttled' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.email_gia_inviata}
                                    </div>
                                )}

                                {this.state.captcha_not_valid && (
                                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        {this.props.config.verifica_captcha_ko}
                                        <button type="button" className="close" onClick={this.hideMessageCaptcha} aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                )}

                                {this.state.status !== 'email_sent' && (
                                    <form action="/password/email" method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                        <input type="hidden" name="_token" value={this.state.token} />
                                        <input type="hidden" name="_token_captcha" value={this.state.tokenCaptcha} />

                                        <EmailInput handleChange={this.handleChange}
                                            value={this.state.email}
                                            config={this.props.config}
                                            maxLength={this.props.config.MAIL_PEO_LENGTH_MAX} />

                                        <ReCAPTCHA
                                            sitekey={this.state.captchaKey}
                                            onChange={this.onChangeCaptcha}
                                            hl={this.props.config.locale}
                                        />

                                        <div className="text-center">

                                            <button type="submit" id="popover-submit"
                                                className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                                data-toggle="popover"
                                                data-placement="top"
                                                data-animation="true"
                                                data-content={this.state.popOverMessage}
                                                data-trigger="hover"
                                                data-template={this.getPopoverCustomTemplate()}
                                                tabIndex="3">
                                                {this.props.config.invia_messaggio_reset}
                                            </button>
                                        </div>

                                    </form>
                                )}

                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

if (document.getElementById('form_reset_password_email')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<ResetPasswordEmail status={status} captchaKey={captchaKey} errors={errors} config={config} />, document.getElementById('form_reset_password_email'));
}
