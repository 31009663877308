import React, { Component } from 'react';


export default class InputText extends Component {
    constructor(props) {
        super(props);
        this.toUpper = this.toUpper.bind(this);
    }
    toUpper(event) {
        if (this.props.forceUpper) {
            event.target.value=event.target.value.toString().toUpperCase()
        }
        this.props.handleChange(event)
    }


    render() {
        var classValidation = '';
        var requiredClass = '';
        var fieldType = 'text';
        var forceUpper = '';
        // Controlla la validità
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }
        if (this.props.forceUpper) {
            forceUpper = 'to-upper';
        }

        // Verifica su required
        if (this.props.required) {
            requiredClass = 'nam_required';
        }
        if (this.props.fieldType != undefined  && this.props.fieldType !=='') {
            fieldType=this.props.fieldType
        }

        // Verifica se mostrare l'help
        var showHelp = false;
        if (this.props.helpText != undefined && this.props.helpText !== '') {
            showHelp = true;
        }

        return (
            <div className="form-group row">
                <div className="col-sm-12">
                    <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                    <input type={fieldType}
                        name={this.props.name}
                        value={this.props.value||''}
                        onChange={this.toUpper}
                        data-testid="input"
                        className={'form-control ' + classValidation + ' ' + requiredClass + ' ' + forceUpper}
                        readOnly={this.props.readOnly}
                        maxLength={this.props.maxLength}
                        placeholder={this.props.placeHolder}
                    />
                    {showHelp && (
                        <div className="form-text" style={{ fontSize: '0.8em' }}>
                            {this.props.helpText}
                        </div>
                    )}
                    <div className="invalid-feedback" data-testid="message">
                        {this.props.invalid_message}
                    </div>
                </div>
            </div>
        );
    }
}

