import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PecInfos from '../common/PecInfos';
import { csrfToken } from '../common/csrf-loader';


export default class FormImportPecConfirm extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        this.state = {
            status: status,
            submitDisabled: false,
            token: token,
            confirmation_code: '',
            popOverMessage: '',
            showSpinnerWaitingImport: false,
            labelSubmitButton: props.config.label_importa_casella,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {

        // Recuperare nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // Assegna il valore del campo
        this.setState({ confirmation_code: value });

        if (!this.isSubmitButtonEnabled(value) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }

    }

    /**
     * Ritorna true se il form è completo (lasciato per eventuali liberatorie)
     */
    isSubmitButtonEnabled() {
        return true;
    }

    handleSubmit(event) {
        if (this.isSubmitButtonEnabled() === true ) {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: "",
                showSpinnerWaitingImport: true,
                submitDisabled: true,
                labelSubmitButton: "Stiamo registrando la tua casella PEC ..."
            }));

            this.myForm.current.submit();
        } else {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            return false;
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {
        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.props.config.title_conferma_dati_casella}</h5>
                            <h5 className="card-title">{this.props.email}</h5>

                            <div className="alert alert-success py-2" role="alert">
                                <p className="pt-3">
                                    {this.props.config.conferma_dati_casella}
                                </p>
                            </div>

                            <PecInfos info={this.props.pecInfos} config={this.props.config} />

                            <form action={this.props.config.url_verify_proceed} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                <input type="hidden" name="_token" value={this.state.token} />

                                <div className="form-group py-4">
                                    <button type="submit" id="popover-submit"
                                        className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-animation="true"
                                        data-content={this.state.popOverMessage}
                                        data-trigger="hover"
                                        data-template={this.getPopoverCustomTemplate()}
                                        disabled={this.state.submitDisabled}
                                        tabIndex="3">
                                        <SpinnerWaitingImport show={this.state.showSpinnerWaitingImport} />&nbsp;{this.state.labelSubmitButton}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe

/**
 * Mostra uno spinner boostrap o non mostra niente in base al valore di <i>props.show</i>
 *
 */
 class SpinnerWaitingImport extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.show === true) {
            return (
                <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
            );
        } else {
            return null;
        }
    }
} // Fine classe SpinnerWaitingImport


if (document.getElementById('form_confirm_import')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<FormImportPecConfirm config={config} email={pec} pecInfos={pecInfos} />, document.getElementById('form_confirm_import'));
}
