import React, { Component } from 'react';
import InputText from '../common/InputText';
import InputPhone from '../common/InputPhone';
import SelectCitySayt from "../common/SelectCitySayt";
import ReactTooltip from "react-tooltip";

export default class DetailsPecBusiness extends Component {

    constructor(props) {
        super(props);

        // Indica se il dato è in modifica o creazione
        var dataModify = false;
        if (this.props.detailsPec.idOwner != 0) {
            dataModify = true;
        }

        this.state = {
            dataModify: dataModify,
            detailsPec_inCostituzione: this.props.detailsPec.inCostituzione || false, // Stato per il checkbox
            OWNERTYPE_PRIVATECITIZEN: "PrivateCitizen",
            OWNERTYPE_FREELANCE: "Freelance",
            OWNERTYPE_BUSINESS: "Business",
            OWNERTYPE_NONCOMMERCIALENTITIES: "NonCommercialEntities",
            OWNERTYPE_PA: "PA",
        };

        // console.log("formType: PecBusiness");
    }

    handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        this.setState({ [name]: checked });
        this.props.handleChange(event);
    }

    render() {

        return (
            <React.Fragment>

                <InputText
                    value={this.props.detailsPec.businessName}
                    name="detailsPec_businessName"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_ragione_sociale}
                    invalid_message={this.props.errorMessages.detailsPec_businessName}
                    maxLength={this.props.config.BUSINESSNAME_MAX_LENGTH}
                    required={true}
                    readOnly={this.state.dataModify}
                />

                {
                    (this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_BUSINESS)
                    && (
                        <React.Fragment>
                            <div className="custom-control custom-switch">
                                <input
                                    id="detailsPec_inCostituzione"
                                    name="detailsPec_inCostituzione"
                                    checked={this.state.detailsPec_inCostituzione}
                                    onChange={this.handleCheckboxChange}
                                    type="checkbox"
                                    role="switch"
                                    className='custom-control-input'
                                    disabled={this.props.disabled}
                                    readOnly={this.state.dataModify}
                                />
                                <label className="custom-control-label col-form-label-sm" htmlFor="detailsPec_inCostituzione">
                                    L'azienda è in costituzione&nbsp;
                                    <a href="#"
                                        data-place="bottom"
                                        data-class="border-dark"
                                        data-type="light"
                                        data-multiline="true"
                                        data-html={false}
                                        data-for="anagrafica"
                                        data-tip={"Se non disponi ancora della partita iva perchè l'impresa<br>è in fase di costituzione, seleziona questa opzione.<br>Potrai procedere all'attivazione della casella<br>e comunicarci la partita iva non appena disponibile."}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                                        </svg>
                                    </a>
                                    <ReactTooltip id="anagrafica" /><br />
                                </label>
                            </div>
                        </React.Fragment>
                    )
                }

                <InputText
                    value={this.state.detailsPec_inCostituzione ? '' : this.props.detailsPec.vatNumber}
                    name="detailsPec_vatNumber"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_partita_iva}
                    invalid_message={this.props.errorMessages.detailsPec_vatNumber}
                    maxLength={this.props.config.VATNUMBER_MAX_LENGTH}
                    readOnly={this.state.dataModify || this.state.detailsPec_inCostituzione}
                />

                {
                    (this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_BUSINESS
                        || this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                        || this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_PA)
                    && (

                        <React.Fragment>
                            <InputText
                                value={this.state.detailsPec_inCostituzione ? '' : this.props.detailsPec.taxCodeCompany}
                                name="detailsPec_taxCodeCompany"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_codice_fiscale_azienda}
                                invalid_message={this.props.errorMessages.detailsPec_taxCodeCompany}
                                maxLength={this.props.config.TAXCODECOMPANY_MAX_LENGTH}
                                readOnly={this.state.dataModify || this.state.detailsPec_inCostituzione}
                            />

                            <InputText
                                value={this.props.detailsPec.businessStreet}
                                name="detailsPec_businessStreet"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_indirizzo}
                                invalid_message={this.props.errorMessages.detailsPec_businessStreet}
                                maxLength={this.props.config.STREET_MAX_LENGTH}
                                required={true}
                            />

                            <SelectCitySayt
                                name="detailsPec_businessCity"
                                value={this.props.detailsPec.businessCity || this.props.detailsPec.city || ''}
                                label={this.props.config.dettaglio_city}
                                nameProvince="detailsPec_businessProvince"
                                valueProvince={this.props.detailsPec.businessProvince || this.props.detailsPec.province || ''}
                                labelProvince={this.props.config.provincia}
                                nameRegion="detailsPec_businessRegion"
                                not_selected={this.props.config.selezionare_citta}
                                invalid_message={this.props.errorMessages.detailsPec_city}
                                required={true}
                                hide_province={true}
                            />

                            <InputText
                                value={this.props.detailsPec.businessZip}
                                name="detailsPec_businessZip"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_zip}
                                invalid_message={this.props.errorMessages.detailsPec_businessZip}
                                maxLength={this.props.config.ZIP_MAX_LENGTH}
                                required={true}
                            />

                            <InputText
                                value={this.props.detailsPec.businessEmail}
                                name="detailsPec_businessEmail"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_extd_email_azienda}
                                invalid_message={this.props.errorMessages.detailsPec_businessEmail}
                                maxLength={this.props.config.PEO_MAX_LENGTH}
                                helpText={this.props.config.dettaglio_extd_email_azienda_help}
                                required={true}
                            />

                            <InputPhone
                                value={this.props.detailsPec.businessMobileNumber}
                                name="detailsPec_businessMobileNumber"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_cellulare}
                                placeHolder={this.props.config.phone_number_no_prefix}
                                invalid_message={this.props.errorMessages.detailsPec_businessMobileNumber}
                                maxLength={this.props.config.CELL_MAX_LENGTH}
                                helpText={this.props.config.dettaglio_cellulare_help}
                                required={true}
                                onlyMobile={true}
                            />

                            {
                                this.props.detailsPec.clientTypeId === this.state.OWNERTYPE_PA && (
                                    <React.Fragment>
                                        <InputText
                                            value={this.props.detailsPec.IPACode}
                                            name="detailsPec_IPACode"
                                            handleChange={this.props.handleChange}
                                            label={this.props.config.dettaglio_codice_ipa}
                                            invalid_message={this.props.errorMessages.detailsPec_IPACode}
                                            maxLength={this.props.config.IPACODE_MAX_LENGTH}
                                            readOnly={this.state.dataModify}
                                        />
                                    </React.Fragment>
                                )
                            }

                            <h5>{this.props.config.rappresentante_legale}</h5>

                            <InputText
                                value={this.props.detailsPec.name}
                                name="detailsPec_name"
                                label={this.props.config.dettaglio_nome}
                                handleChange={this.props.handleChange}
                                invalid_message={this.props.errorMessages.detailsPec_name}
                                maxLength={this.props.config.NAME_LENGTH_MAX}
                                required={true}
                                readOnly={this.state.dataModify}
                            />

                            <InputText
                                value={this.props.detailsPec.surname}
                                name="detailsPec_surname"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_cognome}
                                invalid_message={this.props.errorMessages.detailsPec_surname}
                                maxLength={this.props.config.SURNAME_MAX_LENGTH}
                                required={true}
                                readOnly={this.state.dataModify}
                            />

                            <InputText
                                value={this.props.detailsPec.taxCode}
                                name="detailsPec_taxCode"
                                handleChange={this.props.handleChange}
                                label={this.props.config.dettaglio_codice_fiscale}
                                invalid_message={this.props.errorMessages.detailsPec_taxCode}
                                maxLength={this.props.config.TAXCODE_MAX_LENGTH}
                                required={true}
                                readOnly={this.state.dataModify}
                            />

                            <input type="hidden" name="detailsPec_businessCountry" value={this.props.detailsPec.businessCountry} />
                        </React.Fragment>
                    )
                }

            </React.Fragment>
        );
    }
}
