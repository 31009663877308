import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import InputText from '../common/InputText';
import Document from '../create_pec/Document';
import DetailsPecPrivateFreelance from '../create_pec/DetailsPecPrivateFreelance';
import DetailsPecBusiness from '../create_pec/DetailsPecBusiness';
import DetailsCons from '../create_pec/DetailsCons';
import DetailsRdc from '../create_pec/DetailsRdc';
import OwnerTypeField from '../create_pec/OwnerTypeField';
import SelectRdcType from '../common/SelectRdcType';
import { buildMessagesFromStructure } from '../common/validation_buildErrorMessages';
import CodiceFiscale from "codice-fiscale-js";

export default class FormRdC extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        // Crea l'elenco degli errori
        // Controllo degli errori ricevuti dal server e assegnamo i messaggi di errore
        //var errorMessages = buildMessages(this.props.errors, this.props.config);

        // Crea l'elenco degli errori
        var errorMessages = buildMessagesFromStructure(this.props.errors);

        // Trasforma le date da stringa a Date
        if (this.props.detailsPec.birthDate !== null && this.props.detailsPec.birthDate != '') {
            const dateParts = (this.props.detailsPec.birthDate + "").split("/");
            this.props.detailsPec.birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            this.handleTaxCode(this.props.detailsPec.taxCode,this.props.detailsPec)
        }
        if (this.props.detailsCons) {
            if (this.props.detailsCons.birthDate !== null && this.props.detailsCons.birthDate != '') {
                const dateParts = (this.props.detailsCons.birthDate + "").split("/");
                this.props.detailsCons.birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                this.handleTaxCode(this.props.detailsPec.taxCode,this.props.detailsCons)
            }
        }
        if (this.props.detailsRdC) {
            if (this.props.detailsRdC.birthDate !== null && this.props.detailsRdC.birthDate != '') {
                const dateParts = (this.props.detailsRdC.birthDate + "").split("/");
                this.props.detailsRdC.birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
                this.handleTaxCode(this.props.detailsPec.taxCode,this.props.detailsRdC)
            }
        }

        if (this.props.documentPec) {
            if (this.props.documentPec.issueDate != null && this.props.documentPec.issueDate != '') {
                const dateParts = (this.props.documentPec.issueDate + "").split("/");
                this.props.documentPec.issueDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
            if (this.props.documentPec.expiryDate != null && this.props.documentPec.expiryDate != '') {
                const dateParts = (this.props.documentPec.expiryDate + "").split("/");
                this.props.documentPec.expiryDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            }
        }

        // Indica se il dato è in modifica o creazione
        var dataModify = false;
        if (this.props.detailsPec.idOwner != 0) {
            //dataModify = true;
        }

        this.state = {
            detailsPec: this.props.detailsPec,
            detailsCons: this.props.detailsCons ? this.props.detailsCons : {},
            detailsRdC: this.props.detailsRdC ? this.props.detailsRdC : {},
            document: this.props.documentPec ? this.props.documentPec : {},
            token: token,
            errorMessages: errorMessages,
            dataModify: dataModify,

            OWNERTYPE_PRIVATECITIZEN: "PrivateCitizen",
            OWNERTYPE_FREELANCE: "Freelance",
            OWNERTYPE_BUSINESS: "Business",
            OWNERTYPE_NONCOMMERCIALENTITIES: "NonCommercialEntities",
            OWNERTYPE_PA: "PA",
        };
        // console.log(this.state)
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFocus = this.handleFocus.bind(this);

        // detailsPec
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
        // detailsCons
        this.handleBirthDateConsChange = this.handleBirthDateConsChange.bind(this);
        // detailsRdC
        this.handleBirthDateRdcChange = this.handleBirthDateRdcChange.bind(this);

        this.handleIssueDateChange = this.handleIssueDatedChange.bind(this);
        this.handleExpiryDateChange = this.handleExpiryDateChange.bind(this);
    }

    /**
     * Assegna il valore del componente per la data di nascita in detailsPec
     *
     * @param {*} date
     */
    handleBirthDateChange(date) {
        const { detailsPec, errorMessages } = { ...this.state };
        detailsPec.birthDate = date;
        errorMessages.detailsPec_birthDate = '';
        this.setState({ detailsPec: detailsPec, errorMessages: errorMessages });
    }

    handleBirthDateConsChange(date) {
        const { detailsCons, errorMessages } = { ...this.state };
        detailsCons.birthDate = date;
        errorMessages.detailsCons_birthDate = '';
        this.setState({ detailsCons: detailsCons, errorMessages: errorMessages });
    }

    handleBirthDateRdcChange(date) {
        const { detailsRdC, errorMessages } = { ...this.state };
        detailsRdC.birthDate = date;
        errorMessages.detailsRdC_birthDate = '';
        this.setState({ detailsRdC: detailsRdC, errorMessages: errorMessages });
    }

    handleIssueDatedChange(date) {
        const { document, errorMessages } = { ...this.state };
        document.issueDate = date;
        errorMessages.document_issueDate = '';
        this.setState({ document: document, errorMessages: errorMessages });
    }

    handleExpiryDateChange(date) {
        const { document, errorMessages } = { ...this.state };
        document.expiryDate = date;
        errorMessages.document_expiryDate = '';
        this.setState({ document: document, errorMessages: errorMessages });
    }

    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {

        // Recuper nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (this.state.detailsPec.idOwner != 0) {
            //this.state.dataModify = true;
        }

        // Assegna i valori per i sotto oggetti.
        // React non permette l'assegnamento a propietà di sotto oggetti
        // si deve clonare l'oggetto, modificare il valore e assegnare l'intero oggetto
        if (name.startsWith("detailsCons")) {
            // Assegna i valori all'oggetto detailsCons
            var varName = name.substring(12);

            const { detailsCons, errorMessages } = { ...this.state };
            detailsCons[varName] = value;
            errorMessages[name] = '';

            this.setState({ detailsCons: detailsCons, errorMessages: errorMessages });

        } else if (name.startsWith("detailsPec")) {
            // Assegna i valori all'oggetto detailsPec
            var varName = name.substring(11);

            // Assegna il valore
            const { detailsPec, detailsCons, errorMessages } = { ...this.state };
            detailsPec[varName] = value;
            if (varName=="taxCode") {
                this.handleTaxCode(value,detailsPec)
                this.handleTaxCode(value,detailsCons)
            }
            errorMessages[name] = '';

            // Se sto inserendo il codice fiscale e sono arrivato alla 16 cifra (Ma se ho codice straniero? uso onBlur) allora carica
            /* if (varName === 'taxCode') { if (value.length == 16) { console.log("CHIAMA IL SERVIZIO GetInformationCli"); } }*/

            this.setState({ detailsPec: detailsPec, detailsCons: detailsCons, errorMessages: errorMessages });
        } else if (name.startsWith("detailsRdC")) {
            var varName = name.substring(11);

            const { detailsRdC, errorMessages } = { ...this.state };
            detailsRdC[varName] = value;

            errorMessages[name] = '';
            if (varName=="taxCode") {
                this.handleTaxCode(value,detailsRdC)
            }

            this.setState({ detailsRdC: detailsRdC, errorMessages: errorMessages });

        } else if (name.startsWith("document")) {
            var varName = name.substring(9);

            const { document, errorMessages } = { ...this.state };
            document[varName] = value;
            errorMessages[name] = '';

            this.setState({ detailsRdC: document, errorMessages: errorMessages });
        } else {
            this.setState({ [name]: value });
        }
    }
    handleTaxCode(value,details) {
        if (value && value.length === 16) {
            details['birthRegion'] = 99
            try {
                details['taxCode']=value.toString().toUpperCase()
                const cf = new CodiceFiscale(value)
                details['gender'] = cf.gender
                details['birthCity'] = cf.birthplace.nome
                details['birthDate'] = cf.birthday
                details['birthProvince'] = cf.birthplace.prov || 'EE'
                details['birthZip'] = 99999
            } catch (e) {
                console.log(e.toString())
            }
        }
    }
    /**
     *
     *
     * @param {*} event
     */
    handleFocus(event) {
        // console.log("preso il focus");
    }

    handleSubmit(event) {
        // Applica la validazione
        //this.validationName();
        //event.preventDefault();
        // console.log(this.myform.current)
        return
        // Invia la form
        this.myForm.current.submit();
    }

    render() {
        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <div className="container">
                                <h4 className="card-title">Attivazione del servizio di conservazione a norma</h4>
                                <h5 className="card-title">Dati del responsabile della Conservazione</h5>

                                <div className="alert alert-success py-2" role="alert">
                                    <p className="pt-2">
                                        Inserisci con attenzione i dati del <i>responsabile della conservazione a norma</i> dei messaggi della
                                        casella <strong>{this.props.config.pec}</strong>, verranno riportati nel contratto di attivazione del servizio.
                                    </p>
                                </div>

                                <form action={this.props.config.url_rdc_save} method="post" className="" onSubmit={this.handleSubmit} ref={this.myForm}>
                                    <input type="hidden" name="_token" value={this.state.token} />

                                    <OwnerTypeField
                                        dataModify={this.state.dataModify}
                                        value={this.state.detailsPec.clientTypeId}
                                        handleChange={this.handleChange}
                                        label={this.props.config.forma_giuridica}
                                        config={this.props.config} />

                                    {/* Titolare della casella non privato */}
                                    {
                                        (this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_FREELANCE)
                                        && (
                                            <React.Fragment>
                                                <DetailsPecBusiness
                                                    detailsPec={this.state.detailsPec}
                                                    handleChange={this.handleChange}
                                                    errorMessages={this.state.errorMessages}
                                                    config={this.props.config}
                                                />
                                            </React.Fragment>
                                        )
                                    }

                                    {/* Titolare della casella privato o libero professionista */}
                                    {
                                        (this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_PRIVATECITIZEN
                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_FREELANCE)
                                        && (
                                            <React.Fragment>
                                                <DetailsPecPrivateFreelance
                                                    detailsPec={this.state.detailsPec}
                                                    handleChange={this.handleChange}
                                                    handleBirthDateChange={this.handleBirthDateChange}
                                                    errorMessages={this.state.errorMessages}
                                                    config={this.props.config}
                                                    forceUpper={true}
                                                />
                                            </React.Fragment>
                                        )
                                    }

                                    {/* Responsabile della conservazione per utenti non privati */}
                                    {
                                        (
                                            this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_FREELANCE
                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_BUSINESS
                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                                            || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_PA
                                        ) && (
                                            <React.Fragment>

                                                <input type="hidden" name="detailsCons_sameAsOwner" value='0' />

                                                {/* I dati Cons del form sono sempre richiesti in caso di conservazione */}
                                                <DetailsCons
                                                    detailsCons={this.state.detailsCons}
                                                    clientTypeId={this.state.detailsPec.clientTypeId}
                                                    onChange={this.handleChange}
                                                    handleChange={this.handleChange}
                                                    errorMessages={this.state.errorMessages}
                                                    config={this.props.config}
                                                    handleBirthDateChange={this.handleBirthDateConsChange}
                                                    dataModify={this.state.dataModify}
                                                />

                                                {/* I dati del RdC sono richiesti se diversi da quelli del titolare della casella
                                                        si include comunque il modulo perchè anche se i dati sono uguali è necessario
                                                        integrare i dati di nascita */}
                                                {
                                                    (this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_BUSINESS
                                                        || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_NONCOMMERCIALENTITIES
                                                        || this.state.detailsPec.clientTypeId === this.state.OWNERTYPE_PA
                                                    )
                                                    && (
                                                        <React.Fragment>

                                                            <DetailsRdc
                                                                detailsRdC={this.state.detailsRdC}
                                                                detailsCons={this.state.detailsCons}
                                                                detailsPec={this.state.detailsPec}
                                                                clientTypeId={this.state.detailsPec.clientTypeId}
                                                                onChange={this.handleChange}
                                                                handleChange={this.handleChange}
                                                                errorMessages={this.state.errorMessages}
                                                                config={this.props.config}
                                                                handleDataNascitaRdcChange={this.handleBirthDateRdcChange}
                                                                dataModify={this.state.dataModify}
                                                            />

                                                        </React.Fragment>
                                                    )
                                                }

                                            </React.Fragment>
                                        )
                                    }

                                    <div className="text-center">
                                        <button type="submit" name="action" value="sign" className="btn btn-primary btn-lg" >
                                            AVANTI
                                        </button>
                                    </div>

                                    {/*
                                    <div className="mt-3 mb-3">
                                        <a href={this.props.config.url_select_page}>&laquo; TORNA ALLA PAGINA DI ATTIVAZIONE</a>
                                    </div>
                                    */}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe FormRdC


if (document.getElementById('form_rdc')) {
    var props = {
        config: config,
        errors: errors,
        detailsPec: detailsPec,
        detailsCons: detailsCons,
        detailsRdC: detailsRdC,
        documentPec: documentPec
    };

    var formRdC = React.createElement(FormRdC, props);
    ReactDOM.render(formRdC, document.getElementById('form_rdc'))
}
