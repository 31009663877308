import React, { Component } from 'react';
import InputText from '../common/InputText';


export default class SelectCity extends Component   {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
        };
        this.provinceInUse = this.props.valueProvince;
    }

    componentDidMount() {
        // Chiama il servizio rest e assegna i dati allo stato
        this.loadCities();
    }

    componentDidUpdate(){
        if(this.props.valueProvince != this.provinceInUse){
            this.loadCities();
            this.provinceInUse = this.props.valueProvince;
        }
    }

    /**
     * Chiamata al servzio '/api/list_cities/' e caricamento dei dati nello stato
     *
     */
    async loadCities(){
        try {
            if(this.props.valueProvince == ''){
                this.setState({ cities: [] });
            } else {
                const response = await fetch('/api/list_cities/' + this.props.valueProvince);
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                const json = await response.json();
                this.setState({ cities: json });
            }

        } catch (error) {
            console.log(error);
        }
    }

    render() {
        if (this.props.readOnly) {
            return (
                <React.Fragment>
                    <input type="hidden" name={this.props.name} value={this.props.value} />
                    <InputText
                        label={this.props.label}
                        value={this.props.value}
                        readOnly={true}
                        required={true}
                    />
                </React.Fragment>                
            )
        }
        // Controlla la validità
        var classValidation = '';
        // cambia la classe di validazione

        // console.log("controlla valore per " + this.props.name + " messaggio invalido " + this.props.invalid_message);

        if(this.props.invalid_message != undefined && this.props.invalid_message !== ''){
            classValidation = 'is-invalid';
        }

        var requiredClass = '';
        if(this.props.required){
            requiredClass = 'nam_required';
        }

        let arrayOfData = this.state.cities;

        if(arrayOfData.length != 0){
            let options = arrayOfData.map((data) =>
                <option key={data}  value={data}>
                    {data}
                </option>
            );

            return (
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                        <select
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            className={'custom-select ' + classValidation + ' ' + requiredClass}>
                                <option value='' key='none'>{this.props.not_selected}</option>
                                {options}
                        </select>
                        <div className="invalid-feedback">
                            {this.props.invalid_message}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                        <input type="text"
                            name={this.props.name}
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            className={'form-control ' + classValidation + ' ' + requiredClass}
                            />
                        <div className="invalid-feedback">
                            {this.props.invalid_message}
                        </div>
                    </div>
                </div>
            );
        }

    }
}
