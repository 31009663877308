import React, { Component } from 'react';
import SelectDate from '../common/SelectDate';
import InputText from '../common/InputText';

export default class Document extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <React.Fragment>
                <div className="form-group">
                    <select name="document_type"
                        value={this.props.document.type}
                        onChange={this.props.handleChange}
                        className="custom-select">
                        <option key="CI" value="CI">Carta d'identità</option>
                        <option key="PG" value="PG">Patente di guida</option>
                        <option key="P" value="P">Passaporto</option>
                    </select>
                </div>

                <div className="form-group">
                    <InputText
                        value={this.props.document.issuer}
                        name="document_issuer"
                        handleChange={this.props.handleChange}
                        label='Rilasciato da'
                        placeHolder='Ente che ha rilasciato il documento'
                        maxLength={this.props.config.RILASCIATODA_MAX_LENGTH}
                        invalid_message={this.props.errorMessages.document_issuer}
                    />
                </div>

                <div className="form-group">
                    <InputText
                        value={this.props.document.number}
                        name="document_number"
                        handleChange={this.props.handleChange}
                        //placeHolder='numero del documento'
                        label='Numero documento'
                        maxLength={this.props.config.NUMERODOCUMENTO_MAX_LENGTH}
                        invalid_message={this.props.errorMessages.document_number}
                    />
                </div>

                <SelectDate
                    name="document_issueDate"
                    selected={this.props.document.issueDate}
                    onChange={this.props.onChangeIssueDate}
                    label='Data di rilascio'
                    invalid_message={this.props.errorMessages.document_issueDate}
                />

                <SelectDate
                    name="document_expiryDate"
                    selected={this.props.document.expiryDate}
                    onChange={this.props.onChangeExpiryDate}
                    label='Data di scadenza'
                    invalid_message={this.props.errorMessages.document_expiryDate}
                />
            </React.Fragment>
        );
    }

}
