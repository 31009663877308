import { validate } from 'validate.js';


function checkValidEmailName(email, pattern=''){
    // Ritorna undefined se l'email è valida, altrimenti un messaggio di errore

    if(pattern != ''){
        validate.validators.email.PATTERN = pattern;
    }

    const result = validate({from: email}, {from: { email: true } });
    //console.log(JSON.stringify(result));
    return !(result !== undefined);
}

export { checkValidEmailName };
