import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';


export default class VerifyForm extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        this.state = {
            email: this.props.email,
            status: status,
            submitDisabled: true,
            token: token,
            confirmation_code: '',
            popOverMessage: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {

        // Recuperare nome e valore dall'evento
        const target = event.target;
        const value = target.value;
        const name = target.name;

        // Assegna il valore del campo
        this.setState({ confirmation_code: value });

        if (!this.isSubmitButtonEnabled(value) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }

    }

    /**
     * Ritorna true se i valori email e password sono corretti
     *
     * @param {*} confirmation_code
     */
    isSubmitButtonEnabled(confirmation_code) {
        if (confirmation_code.length == this.props.config.EMAIL_VERIFICATION_TOKEN_LENGTH) {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit(event) {
        if (this.isSubmitButtonEnabled(this.state.confirmation_code) === true ) {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: "",
            }));
            this.myForm.current.submit();
        } else {
            event.preventDefault();
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            return false;
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    render() {
        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title">{this.props.config.title_conferma_account}</h5>

                            <p>{this.props.config.conferma_registrazione}</p>

                            <form action={this.props.config.url_verify_code} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                <input type="hidden" name="_token" value={this.state.token} />

                                <div className="row justify-content-md-center">
                                    <div className="form-group col-md-auto">
                                        <input type="text"
                                            name="confirmation_code"
                                            value={this.state.confirmation_code}
                                            onChange={this.handleChange}
                                            maxLength={this.props.config.EMAIL_VERIFICATION_TOKEN_LENGTH}
                                            className="form-control mb-2 mr-sm-2 verify-code " placeholder={this.props.config.codice} />
                                    </div>
                                </div>

                                {this.state.status === 'code_no_match' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.codice_non_corretto}
                                    </div>
                                )}

                                {this.state.status === 'code_not_found' && (
                                    <div className="alert alert-warning" role="alert">
                                        {this.props.config.codice_non_trovato}
                                    </div>
                                )}

                                <div className="form-group py-4">
                                    <button type="submit" id="popover-submit"
                                        className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-animation="true"
                                        data-content={this.state.popOverMessage}
                                        data-trigger="hover"
                                        data-template={this.getPopoverCustomTemplate()}
                                        tabIndex="3">
                                        {this.props.config.conferma}
                                    </button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

if (document.getElementById('form_verify')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<VerifyForm config={config} email={email} />, document.getElementById('form_verify'));
}
