import React, { Component } from 'react';


export default class PrivacyField extends Component   {

    constructor(props) {
        super(props);

        const config = props.config;
    }

    render() {
        return (
            <div className="form-group form-check text-left">
                <input id="privacy" name="privacy" className="form-check-input" onChange={this.props.handleChange} checked={this.props.value} type="checkbox" />
                <label htmlFor="privacy" className="form-check-label">
                {config.privacy_description}<a href={this.props.url} className="pdf-link" target="_blank">{config.privacy_policy}</a>.
                </label>
            </div>
        );
    }

} // Fine classe PrivacyField
