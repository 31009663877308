import React, { Component } from 'react';
import InputText from '../common/InputText';

export default class OwnerTypeField extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        // Controlla se il dato è in modifica o creazione
        if (this.props.dataModify) {
            // Se il dato è in modifica mostra un campo non modificabile e invia i dati con un campo hidden
            var label_fg = this.props.config[this.props.value];

            return (
                <React.Fragment>
                    <input type="hidden" name="detailsPec_clientTypeId" value={this.props.value} />
                    <InputText
                        label={this.props.config.forma_giuridica}
                        value={label_fg}
                        readOnly={true}
                        required={true}
                    />
                </React.Fragment>
            );
        } else {
            return (
                // Se il dato è in creazione mostra una select
                // Non è stata ancora implementata la distinzione tra ditta individuale e
                // società ente/associazione perché non è stato definito come fare a disntiguerle
                // in caso di ricezione dei dati dai ws Namirial
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                        <select
                            value={this.props.value}
                            name="detailsPec_clientTypeId"
                            data-testid="select_owner_type"
                            onChange={this.props.handleChange}
                            className="custom-select">
                            <option key="PrivateCitizen" value="PrivateCitizen">{this.props.config.PrivateCitizen}</option>
                            <option key="Freelance" value="Freelance">{this.props.config.Freelance}</option>
                            <option key="Business" value="Business">{this.props.config.Business}</option>
                            <option key="NonCommercialEntities" value="NonCommercialEntities">{this.props.config.NonCommercialEntities}</option>
                            <option key="PA" value="PA">{this.props.config.PA}</option>
                        </select>
                    </div>
                </div>
            );
        }

    }

} // Fine classe OwnerTypeField
