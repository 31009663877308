import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from './common/csrf-loader';
import AlertWarning from './common/AlertWarning';
import { checkValidEmailName } from './common/email-validation';
import ReCAPTCHA from "react-google-recaptcha";

export default class LoginForm extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef()

        var title = this.props.config.title_login;
        if (this.props.order_in_progress === true) {
            title = this.props.config.title_crea_una_casella + '<br>' + this.props.config.pecToBuy;
        }

        // ReCaptcha Disabilitato
        //var tokenCaptcha = '';
        var tokenCaptcha = 'disabled';

        if (this.props.captchaKey === '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI') {
            tokenCaptcha = 'fake_for_test';
        }

        this.state = {
            email: '',
            password: '',
            title: title,
            showSpinnerCheck: false,
            showMessageCredentialsNotValid: false,
            submitDisabled: true,
            popOverMessage: '',
            token: token,
            order_in_progress: this.props.order_in_progress,
            tokenCaptcha: tokenCaptcha,
            captchaKey: this.props.captchaKey,
            showLoginForm: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideMessage = this.hideMessage.bind(this);

        // ReCaptcha Disabilitato
        //this.onChangeCaptcha = this.onChangeCaptcha.bind(this);
    }

    /**
     * Ritorna true se i valori email e password sono corretti
     *
     * @param {*} myEmail
     * @param {*} myPassword
     */
    isSubmitButtonEnabled(email, password, tokenCaptcha) {
        if (checkValidEmailName(email) && this.checkValidPassword(password) && tokenCaptcha != '') {
            //console.log('isSubmitButtonEnabled ' + email + ' ' + password + ' ' + 'true');
            return true;
        } else {
            //console.log('isSubmitButtonEnabled ' + email + ' ' + password + ' ' + 'false');
            return false;
        }
    }

    /**
     * Ritorna true se il il valore di password è valido
     *
     * @param {*} password
     */
    checkValidPassword(password) {
        if (password !== undefined && password.length >= this.props.config.LOGIN_PAGE_PASSWORD_LENGTH_MIN) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Nasconde il messaggio di avvertimento credenziali non valide.
     * Il metodo è necessario per permettere di gestire il bottone di chiusura dentro il componente MessageCredentialsNotValid
     *
     */
    hideMessage() {
        this.setState({ showMessageCredentialsNotValid: false, });
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    handleChange(event) {
        // console.log('handleChange ' + event.target.name + ' ' + event.target.value);

        // Se il messaggio è visibile nascondilo
        if (this.state.showMessageCredentialsNotValid === true) {
            this.setState({ showMessageCredentialsNotValid: false, });
        }

        // Recupero nome e valore dall'evento
        const value = event.target.value;
        const name = event.target.name;

        // setState è ascincrono, quindi devo considerare il valore separatamente
        var myEmail = this.state.email;
        var myPassword = this.state.password;

        if (name == "email") {
            myEmail = value;
        } else if (name == "password") {
            // è stato modificata la password
            myPassword = value;
        }

        this.setState({
            [name]: value,
        });

        if (!this.isSubmitButtonEnabled(myEmail, myPassword, this.state.tokenCaptcha) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }

        //this.setState({email: event.target.value});
    }

    handleSubmit(event) {
        //console.log('handleSubmit ' + this.state.popOverMessage);
        var email = this.state.email;
        var password = this.state.password;

        if (this.isSubmitButtonEnabled(email, password, this.state.tokenCaptcha) ) {
            event.preventDefault();
            // Chiama il servizio per il controllo sulla disponibilità
            this.checkCredentials();
        } else {
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            event.preventDefault();
            return false;
        }
    }

    /* ReCaptcha Disabilitato
    onChangeCaptcha(value) {
        if (value == null) {
            value = '';
        }
        this.setState({ tokenCaptcha: value, });
        const sumbitDisabledValue = !this.isSubmitButtonEnabled(this.state.email, this.state.password, value);
        this.setState({ submitDisabled: sumbitDisabledValue });
    }
    */

    /**
     * Chiama il servizio per il controllo della disponibilità
     */
    async checkCredentials() {
        this.setState({ showSpinnerCheck: true, });

        try {
            const data = {
                email: this.state.email,
                password: this.state.password,
            };

            // Chiama il servizio
            const response = await fetch(this.props.config.url_check_credentials, {
                method: 'POST',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw Error(response.statusText);
            }

            const json = await response.json();

            // Controlla il risultato
            if (json.result == 0) {
                // Le credenziali non sono valide, mostra il messaggio
                this.setState({ showMessageCredentialsNotValid: true, });
            } else {
                // Invia i dati al server usando il form
                this.myForm.current.submit();
            }

        } catch (error) {
            console.log(error);
        }

        // Nascondi lo spinner
        this.setState({ showSpinnerCheck: false, });
    }

    render() {
        //console.log('Render POPOVER ' + this.state.popOverMessage);
        let fragment_order_in_progress = '';
        if (this.state.order_in_progress === true) {
            fragment_order_in_progress = (
                <p className="card-text">{this.props.config.autenticati_per_acquisto}</p>
            );
        }

        const alertWarningMessage = (
            <span>
                I dati di accesso non risultano corretti. Prova a&nbsp;
                <a className="view_link" href={this.props.config.url_password_reset_page}>recuperare la password</a>
                &nbsp;oppure&nbsp;
                <a className="view_link" href={this.props.config.url_register}>registrati</a>
            </span>
        );

        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">
                            <h5 className="card-title" dangerouslySetInnerHTML={{ __html: this.state.title }}></h5>
                            {fragment_order_in_progress}
                            <div>
                            {this.state.showLoginForm && <form action={this.props.config.url_login} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                    <input type="hidden" name="_token" value={this.state.token} />
                                    {/* ReCaptcha Disabilitato <input type="hidden" name="_toke_captcha" value={this.state.tokenCaptcha} />*/}

                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm">
                                                <label htmlFor="email" className="col-form-label font-weight-bold">{this.props.config.indirizzo_email}</label>
                                            </div>
                                            <div className="col-sm text-right mt-2">
                                                <a className="view_link" href={this.props.config.url_password_reset_page}>{this.props.config.password_dimenticata}</a>
                                            </div>
                                        </div>
                                        <input type="text" name="email" value={this.state.email} onChange={this.handleChange} className="form-control" tabIndex="1" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password" className="col-form-label font-weight-bold">{this.props.config.password}</label>
                                        <input type="password" name="password" onChange={this.handleChange} className="form-control" tabIndex="2" />
                                    </div>

                                    {/* ReCaptcha Disabilitato
                                    <ReCAPTCHA
                                        sitekey={this.state.captchaKey}
                                        onChange={this.onChangeCaptcha}
                                        hl={this.props.config.locale}
                                    />
                                    */}

                                    <div className="form-group py-4">
                                        <button type="submit" id="popover-submit"
                                            className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-animation="true"
                                            data-content={this.state.popOverMessage}
                                            data-trigger="hover"
                                            data-template={this.getPopoverCustomTemplate()}
                                            tabIndex="3">
                                            <SpinnerCheckEmail show={this.state.showSpinnerCheck} />&nbsp;{this.props.config.accedi}
                                        </button>
                                    </div>
                                </form>}
                                <hr></hr>
                                <p>Utilizza il tuo account <b><a href={this.props.config.url_auth_servicedesk} target="_blank">MyNamirial</a></b> e potrai accedere a tutti i servizi Namirial con un unico account!</p>
                                <p><u>Non dovrai più memorizzare una password diversa per ogni portale.</u></p>
                                <p>Inoltre un account <b><a href={this.props.config.url_auth_servicedesk} target="_blank">MyNamirial</a></b> è più sicuro perché ti consentirà di abilitare l'autenticazione a due fattori! Significa che prima di poter accedere ad un portale con il tuo account <b><a href={this.props.config.url_auth_servicedesk} target="_blank">MyNamirial</a></b>, ti verrà richiesto di inserire un codice di verifica che potrai decidere di ricevere anche tramite e-mail.</p>
                                <hr></hr>
                                <a href={this.props.config.url_auth_redirect} className="btn btn-primary btn-block mb-2 mt-3 submit-popover btn-lg">{this.props.config.accedi_con_mynamirial}</a>
                                <hr></hr>
                            </div>

                            <AlertWarning customMessage={alertWarningMessage} show={this.state.showMessageCredentialsNotValid} hideClick={this.hideMessage} />

                            <div className="row">
                                <div className="col-sm">
                                    <div className="text-center">
                                        <div className="mt-3 mb-2">{this.props.config.non_hai_un_account}</div>
                                        <p className="mb-3"><a className="view_link" href={this.props.config.url_auth_register}>{this.props.config.registrati_adesso}</a></p>
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="text-center">
                                        {/* <a className="view_link" href={this.props.config.url_auth_reset}>{this.props.config.password_dimenticata}</a> */}
                                        <div className="mt-3 mb-2">{this.props.config.password_dimenticata}</div>
                                        <p className="mb-3"><a className="view_link"
                                        target='_blank'
                                        href={this.props.config.url_auth_reset}>{this.props.config.ripristina_password_dimenticata}</a></p>
                                    </div>
                                </div>
                                {/* <div className="mt-3 mb-2">{this.props.config.non_hai_un_account}</div>
                                <p className="mb-3"><a className="view_link" href={this.props.config.url_register}>{this.props.config.registrati_adesso}</a></p> */}
                            </div>


                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe LoginForm

class SpinnerCheckEmail extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.show === true) {
            return (
                <span className="spinner-border spinner-border-sm mr-2" role="status"></span>
            );
        } else {
            return null;
        }
    }
} // Fine classe SpinnerCheckEmail

if (document.getElementById('form_login')) {

    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<LoginForm config={config} order_in_progress={order_in_progress} captchaKey={captchaKey} />, document.getElementById('form_login'));
}
