import React, { Component } from 'react';
import InputText from '../common/InputText';
import SelectCitySayt from '../common/SelectCitySayt';
import ReactTooltip from "react-tooltip";
import InputPhone from '../common/InputPhone';
import InputMail from "../common/InputMail";

export default class DetailsPecSpidmail extends Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const niceDate = (new Intl.DateTimeFormat("it-IT", {
            year: "numeric",
            month: "long",
            day: "2-digit"
        })).format(this.props.detailsPec.birthDate)

        return (
            <React.Fragment>
                Stai attivando SpidMail a nome di <a href="#="
                    data-place="bottom"
                    data-class="border-dark"
                    data-type="light"
                    data-multiline="true"
                    data-html={true}
                    data-for="anagrafica"
                    data-tip={"Luogo e data di nascita</br><b>" + this.props.detailsPec.birthCity + " " + niceDate + "</b>"}
                >{this.props.detailsPec.name} {this.props.detailsPec.surname} ({this.props.detailsPec.taxCode})</a>
                <ReactTooltip id="anagrafica" /><br />
                {this.props.detailsPec.email && false &&
                    <>
                        <b>{this.props.detailsPec.email}</b> è l'indirizzo email su cui ti invieremo le comunicazioni importanti per la gestione della casella, per esempio
                        l'avvenuta attivazione di SpidMail e la notifica di ricezione nuovi messaggi PEC
                    </>
                }

                <p>Inserisci o verifica i dati richiesti per procedere</p>

                <InputMail
                    value={this.props.detailsPec.email || ''}
                    name="detailsPec_email"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_email ?? 'Email'}
                    invalid_message={this.props.errorMessages.detailsPec_email}
                    helpText={this.props.config.dettaglio_email_help}
                    required={true}
                    readOnly={this.props.emailReadOnly}
                />

                <InputPhone
                    value={this.props.detailsPec.mobileNumber || ''}
                    name={"detailsPec_mobileNumber"}
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_cellulare}
                    placeHolder={"Inserisci il numero di cellulare"}
                    invalid_message={this.props.errorMessages.detailsPec_mobileNumber}
                    maxLength={this.props.config.CELL_MAX_LENGTH}
                    helpText={this.props.config.dettaglio_cellulare_help}
                    required={true}
                    onlyMobile={true}
                />

                <InputText
                    value={this.props.detailsPec.street || ''}
                    name="detailsPec_street"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_indirizzo}
                    invalid_message={this.props.errorMessages.detailsPec_street}
                    maxLength={this.props.config.STREET_MAX_LENGTH}
                    required={true}
                />

                <SelectCitySayt
                    name="detailsPec_city"
                    value={this.props.detailsPec.city || ''}
                    label={this.props.config.dettaglio_city}
                    nameProvince="detailsPec_province"
                    valueProvince={this.props.detailsPec.province || ''}
                    labelProvince={this.props.config.provincia}
                    nameRegion="detailsPec_region"
                    not_selected={this.props.config.selezionare_citta}
                    invalid_message={this.props.errorMessages.detailsPec_city}
                    required={true}
                    hide_province={true}
                />

                <InputText
                    value={this.props.detailsPec.zip || ''}
                    name="detailsPec_zip"
                    handleChange={this.props.handleChange}
                    label={this.props.config.dettaglio_zip}
                    invalid_message={this.props.errorMessages.detailsPec_zip}
                    maxLength={this.props.config.ZIP_MAX_LENGTH}
                    required={true}
                />
            </React.Fragment>
        );
    }
}
