import React, { Component } from 'react';

/**
 * Componente che carica un URL contenente HTML SICURO (!) e lo inserisce nel DOM di un DIV html
 *
 * Per info su dangerouslySetInnerHTML
 * https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
 *
 */
export default class LoadUrlDescription extends Component {

    /**
     * Propos deve contenere l'attributo url (props.url)
     *
     * @param {*} props
     */
    constructor(props) {
      super(props);
      this.state = {
        __html:""
      };
    }

    /**
     * Viene chiamato dopo il render
     */
    componentDidMount() {

      /*
      headers: {
        'Access-Control-Allow-Origin':'*'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      }
      */

      fetch(this.props.url,{
        //mode: 'cors', // no-cors, *cors, same-origin

      }).then(response => {
            return response.text();
          }).then(data => {
            // Carica i dati nello stato, questo provoca il refresh grafico
            this.setState({
                __html:data
            });
            this.render();
          });

    }

    componentDidUpdate(prefProps) {
        if(prefProps.url != this.props.url) this.componentDidMount();
    }

    /**
     * Importa l'HTML dentro un tag DIV
     */
    render() {
      return (
          <React.Fragment>
         <div dangerouslySetInnerHTML={ this.state } />
            {this.props.message && (this.props.message) || ""}
          </React.Fragment>
      );
    }
  }
