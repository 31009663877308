import React, { Component } from 'react';
import InputText from '../common/InputText';

export default class ClientTypeField extends Component {

    constructor(props) {
        super(props);
        this.state = {
            default: this.props.default
        };
    }

    mapOptions(all) {
        let original = this.props.default
        return this.props.config.owners.map(function (value) {
            if (all || (value.key != 'PrivateCitizen' && value.key != 'Freelance')) {
                return (<option key={value.id} value={value.key} defaultValue={original === value.key}>{value.description}</option>)
            } else {
                return ""
            }
        })
    }

    render() {

        // Contorlla se il dato è in modifica o creazione
        if (this.props.dataModify) {
            // Se il dato è in modifica mostra un campo non modificabile e invia i dati con un campo hidden
            let label_fg = this.props.config.ownerDescription[this.props.value];
            if ((
                this.props.value === "Business" ||
                this.props.value === "NonCommercialEntities" ||
                this.props.value === "PA"
            ) && this.props.classeCom === '') {
                return (
                    // Se il dato è in creazione mostra una select
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <label htmlFor="billingData_clientType" className="col-form-label font-weight-bold">{this.props.config.tipologia_acquirente}</label>
                            <select
                                value={this.props.value}
                                name="billingData_ownerType"
                                data-testid="select_client_type"
                                onChange={this.props.handleChange}
                                className="custom-select">
                                {
                                    this.mapOptions(false)
                                }
                            </select>
                        </div>
                    </div>
                );

            } else {
                return (
                    <React.Fragment>
                        <input type="hidden" name="billingData_ownerType" value={this.props.value} />

                        <InputText
                            label={this.props.config.tipologia_acquirente}
                            value={label_fg}
                            readOnly={true}
                            required={true}
                        />
                    </React.Fragment>
                );
            }
        } else {
            return (
                // Se il dato è in creazione mostra una select
                <div className="form-group row">
                    <div className="col-sm-12">
                        <label htmlFor="billingData_clientType" className="col-form-label font-weight-bold">{this.props.config.tipologia_acquirente}</label>
                        <select
                            value={this.props.value}
                            name="billingData_ownerType"
                            data-testid="select_client_type"
                            onChange={this.props.handleChange}
                            className="custom-select">
                            {
                                this.mapOptions(true)
                            }
                        </select>
                    </div>
                </div>
            );
        }

    }

} // Fine classe ClientTypeField
