import React, { Component } from 'react';


export default class MessageEmailInUse extends Component   {

    constructor(props) {
        super(props);

        const config = props.config;
    }

    render() {

        if(this.props.show === true){
            return (
                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                    {config.username_gia_usato}
                    <button type="button" className="close" onClick={this.props.hideMessage} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        } else {
            return null;
        }
    }

} // Fine classe MessageCredentialsNotValid
