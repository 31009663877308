import React, { Component } from 'react';

export default class AlertSuccess extends Component   {

    constructor(props) {
        super(props);
    }

    render() {

        if(this.props.show === true){
            var customMessage = '';
            if (this.props.customMessage !== undefined){
                customMessage = this.props.customMessage;
            }

            var message = '';
            if (this.props.message !== undefined){
                message = this.props.message;
            }

            return (
                <div className="alert alert-success alert-dismissible fade show" role="alert">
                    {customMessage !== '' && (
                        customMessage
                    )}

                    {message.length > 0 && (
                        message.split('\n').map((item, key) => {
                            return <span key={key}>{item}<br /></span>
                        })
                    )}

                    <button type="button" className="close" onClick={this.props.hideClick} aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        } else {
            return null;
        }
    }

}
