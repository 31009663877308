import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';
import { checkValidEmailName } from '../common/email-validation';
import EmailInput from '../common/EmailInput';
import AlertWarning from '../common/AlertWarning';
import PrivacyField from './PrivacyField';
import { buildMessagesFromStructure } from '../common/validation_buildErrorMessages';
import Dropzone from 'react-dropzone';


export default class FormImportPecSelect extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        var errorMessages = buildMessagesFromStructure(this.props.errors);
        var showNotImportableMessageError = false;
        var notImportableMessage = '';

        if (typeof errorMessages.email_is_not_importable !== 'undefined'){
            showNotImportableMessageError = true;
            notImportableMessage = errorMessages.email_is_not_importable;
        }

        if (typeof errorMessages.email !== 'undefined'){
            showNotImportableMessageError = true;
            notImportableMessage = errorMessages.email;
        }

        this.state = {
            email: this.props.email,
            privacy: true,
            submitDisabled: true,
            popOverMessage: '',
            token: token,
            errorMessages: errorMessages,
            notImportableMessage: notImportableMessage,
            showNotImportableMessageError: showNotImportableMessageError,
            file: null,
            fileContent: [],
            filteredContent: []
        };

        this.hideNotImportableMessage = this.hideNotImportableMessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        // --- DropZone
        // this.onDrop = (files) => {
        //     this.setState({files})
        // };

   }

    hideNotImportableMessage() {
        this.setState({ showNotImportableMessageError: false, });
    }

    handleChange(event) {

        // Nasconde i messaggi di errore
        if (this.state.showNotImportableMessageError === true) {
            this.setState({ showNotImportableMessageError: false, });
        }

        // Recupera nome e valore dall'evento
        const target = event.target;
        const value = target.name === 'privacy' ? target.checked : target.value;
        const name = target.name;

        // setState è ascincrono, quindi devo considerare il valore separatamente
        var myEmail = this.state.email;
        var myPrivacy = this.state.privacy;

        // Aggiorno il valore attualmente modificato
        if (name == "email") {
            myEmail = value;
        } else if (name == "privacy") {
            myPrivacy = value;
        }

        // Assegna il valore del campo
        this.setState({ [name]: value });

        if (!this.isSubmitButtonEnabled(myEmail, myPrivacy) === true) {
            this.setState({
                submitDisabled: true,
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            });
        } else {
            this.setState({
                submitDisabled: false,
                popOverMessage: "",
            });
        }
    }

    /**
     * Ritorna true se i valori email e privacy sono corretti
     */
    isSubmitButtonEnabled(email, privacy) {
        // Email valida, privacy checked
        if (checkValidEmailName(email) && privacy ) {
            return true;
        } else {
            return false;
        }
    }

    handleSubmit(event) {
        //console.log('handleSubmit ' + this.state.popOverMessage);
        var email = this.state.email;

        if (!this.isSubmitButtonEnabled(this.state.email, this.state.privacy) ) {
            this.setState((state) => ({
                popOverMessage: this.props.config.completa_il_form_per_proseguire,
            }));
            event.preventDefault();
            return false;
        }
    }

    getPopoverCustomTemplate() {
        return '<div class="popover bg-danger" role="tooltip"><div class="arrow"></div><h3 class="popover-header text-white"></h3><div class="popover-body text-white"></div></div>';
    }

    //--- DropZone
    handleFileChange = (acceptedFiles) => {
        const file = acceptedFiles[0];
        this.setState({ file });
        this.readFileContent(file);
    };

    readFileContent = (file) => {
        const reader = new FileReader();


        reader.onload = (event) => {
            const content = event.target.result;
            let arrContent = [];
            let filteredContent = [];

            if(content){
                arrContent = content.split(/[\/\r/\n ;/_]/)
                // arrContent = content.split(";")
                filteredContent = arrContent.filter(function (el) {
                    return (el!= "" && el!= null);
                  });
                this.setState({ setArrFileContent: filteredContent});
                this.setState({ filteredContent: filteredContent });
            }
            this.setState({ fileContent: content });
            console.log(filteredContent)
        };

        // reader.readAsText(file);
        reader.readAsBinaryString(file);
        //reader.readAsDataURL(file);
    };


    render() {

        // const files = this.state.files.map(file => (
        //     <li key={file.name}>
        //       {file.name} - {file.size} bytes
        //     </li>
        //   ));


        const { file, fileContent } = this.state;
        const { arrFileContent, setArrFileContent } = this.state;


        return (
            <React.Fragment>

                <div className="choose_email_container">
                    <div className="card mx-auto">
                        <div className="card-body">

                            <h5 className="card-title text-uppercase">{this.props.config.label_title_importa_account}</h5>

                            {/* Descrizione flusso di import */}
                            <div>
                                <div className="py-1 text-justify">{this.props.config.descrizione_import_1}</div>
                                <div className="py-1 pb-3 text-justify">{this.props.config.descrizione_import_2}</div>
                            </div>

                            <form action={this.props.config.url_register_import_single} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                                <input type="hidden" name="_token" value={this.state.token} />

                                <EmailInput
                                    handleChange={this.handleChange}
                                    value={this.state.email}
                                    config={this.props.config} />

                                {/*
                                <PrivacyField
                                    value={this.state.privacy}
                                    handleChange={this.handleChange}
                                    url={this.props.config.url_pdf_privacy}
                                    config={this.props.config}
                                />
                                */}

                                <AlertWarning message={this.state.notImportableMessage} show={this.state.showNotImportableMessageError} hideClick={this.hideNotImportableMessage} />

                                <div className="form-group py-4">
                                    <button type="submit" id="popover-submit"
                                        className="btn btn-primary btn-block mb-2 mt-3 text-uppercase submit-popover"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-animation="true"
                                        data-content={this.state.popOverMessage}
                                        data-trigger="hover"
                                        data-template={this.getPopoverCustomTemplate()}
                                        tabIndex="3">
                                        {this.props.config.label_importa_la_pec}
                                    </button>
                                </div>
                            </form>

                            {/* DropZone */}
                            {/* <Dropzone
                                onDrop={this.onDrop}>
                                {({getRootProps, getInputProps}) => (
                                <section className="container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                    <input {...getInputProps()} />
                                    <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <aside>
                                    <h4>Files</h4>
                                    <ul>{files}</ul>
                                    </aside>
                                </section>
                                )}
                            </Dropzone> */}

                            <form>
                                <div className='dropzoneParent'>
                                    <div className="dropzoneBadges">
                                        <span className="badge badge-dark mx-3">.TXT</span>
                                        <span className="badge badge-dark">.CSV</span>
                                    </div>
                                    <div className="dropzone">

                                    <Dropzone onDrop={this.handleFileChange}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="dropzone-box">
                                                <input {...getInputProps()} />
                                                <div className='text-center my-2'><svg style={{ fontSize: 30 }} className='text-lead' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM216 408c0 13.3-10.7 24-24 24s-24-10.7-24-24V305.9l-31 31c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l72-72c9.4-9.4 24.6-9.4 33.9 0l72 72c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-31-31V408z"/></svg></div>
                                                <p>Trascina qui il tuo file o premi per selezionarlo</p>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>
                                </div>
                            </form>
                            {file && (
                                <div>
                                    <h3>File caricato:</h3>
                                    <p>{file.name}</p>
                                    <h3>Contenuto del file:</h3>
                                    <pre>{this.state.filteredContent}</pre>
                                    {/* Print del contenuto di pagina */}
                                    <ul>
                                        {this.state.filteredContent && this.state.filteredContent.map(function(email, index){
                                            return <li key={ index }>{email}</li>;
                                        })}
                                    </ul>
                               </div>
                            )}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }

} // fine classe FormImportPecSelect

if (document.getElementById('form_import_massive')) {
    $(function () {
        $('.submit-popover').popover({
            container: 'body'
        })
    })

    ReactDOM.render(<FormImportPecSelect config={config} locale={locale} email={email} errors={errors} />, document.getElementById('form_import_massive'));
}

