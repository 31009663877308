import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { formPost } from './common/form-post-utility';
import { csrfToken } from './common/csrf-loader';
import LoadUrlDescription from './common/LoadUrlDescription';

export default class LandingPageConvenzioni extends Component {

    constructor(props) {
        super(props);

        this.state = {
            inLoading: true,
        }
    }

    /**
     * Metodo chiamato dopo il render
     *
     * Carica i prodotti e modifica lo stato
     *
     */
    componentDidMount() { }

    renderConventionProducts() {
        if (this.props.products.length == 0) {
            // Se non ci sono prodotti mostrare un messaggio
            return <div>{this.props.config.nessuna_promozione_trovata}</div>;
        } else {
            // Visualizzazione prodotti
            return (
                <div className="convenzioni_card">
                    {this.props.products.map(prodottoInLinea => {
                        return (
                            <ProductConvention url_select_product={this.props.config.url_select_product}
                                product={prodottoInLinea}
                                convention={this.props.convention}
                                config={this.props.config}/>
                        );
                    })}
                </div>
            );
        }
    } // Fine render convention products

    render() {
        return (
            <React.Fragment>
                <div className="card mx-auto">
                    {this.renderConventionProducts()}
                </div>
            </React.Fragment>
        );
    }

}

class ProductConvention extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <React.Fragment>
                <div className="container p-0 m-0">
                    {/* Aggiunta del componente descrizione */}
                    <LoadUrlDescription url={this.props.convention.url_description} />
                </div>

                {/* Pulsante di acquisto per il prodotto */}
                {this.props.config.show_button == 1 && (
                    <div className="convenzioni_product_button pb-5">
                        {/* Aggiunta del componente bottone */}
                        <BuyProductButton
                            url_select_product={this.props.config.url_select_product}
                            id_product={this.props.product.id}
                            convention={this.props.convention}
                            config={this.props.config} />
                    </div>
                )}

            </React.Fragment>
        );
    }

}

class BuyProductButton extends Component {

    /**
     * Propos deve contenere l'attributo url (props.url)
     *
     * @param {*} props
     */
    constructor(props) {
        super(props);
        this.selectProduct = this.selectProduct.bind(this);
        //const config = props.config;
    }

    selectProduct(event) {
        // Recupera il token per l'invio della form
        const token = csrfToken();
        formPost(this.props.url_select_product, { 'id_product': this.props.id_product, 'id_convenzione': this.props.convention.convention_id, '_token': token });
    }

    render() {
        // Preparato per un'evenutale selezione del prodotto in GET
        // Da decidere in base alle politiche SEO
        // let urlBuy = "/select/" + this.props.id_product ;

        return (
            <React.Fragment>
                <a href="#" onClick={this.selectProduct} className="btn btn-dark btn-primary" role="button" aria-pressed="true">{config.acquista}</a>
            </React.Fragment>
        );
    }

}

// Crea un componente a sostiutuzione di un div
if (document.getElementById('convenzioni_card')) {
    ReactDOM.render(<LandingPageConvenzioni config={config} convention={convention} products={products} />, document.getElementById('convenzioni_card'));
}
