import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import InputText from '../common/InputText';
import "react-datepicker/dist/react-datepicker.css";

export default class SelectDate extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // Controlla la validità
        var classValidation = '';
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }
        if (this.props.readOnly) {
            let dtstring=this.props.selected
            try {
                dtstring=this.props.selected.toLocaleDateString(
                    'it-it',{year: "numeric",month:"2-digit",day:"2-digit"}
                )
            }
            catch(e) {
                console.log(this.props.selected)
            }
            return (
                <React.Fragment>
                    <input type="hidden" name={this.props.name} value={dtstring} />
                <InputText
                    label={this.props.label}
                    value={dtstring}
                    readOnly={true}
                    required={true}
                />
                </React.Fragment>
            )
        }
        return (
            <div className="form-group row">
                <div className="col-sm-12">
                    <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                    <br />
                    <DatePicker
                        dateFormat="dd/MM/yyyy"
                        name={this.props.name}
                        selected={this.props.selected}
                        onChange={this.props.onChange}
                        defaultDate={new Date('2000-01-01')}
                        className={'form-control ' + classValidation}>
                    </DatePicker>
                    <div className={classValidation}></div>
                    <div className="invalid-feedback">
                        {this.props.invalid_message}
                    </div>
                </div>
            </div>
        );
    }
}
