import React, { Component } from 'react';


export default class InputMail extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var classValidation = '';
        var requiredClass = '';

        // Controlla la validità
        if (this.props.invalid_message != undefined && this.props.invalid_message !== '') {
            classValidation = 'is-invalid';
        }

        // Verifica su required
        if (this.props.required) {
            requiredClass = 'nam_required';
        }

        // Verifica se mostrare l'help
        var showHelp = false;
        if (this.props.helpText != undefined && this.props.helpText !== '') {
            showHelp = true;
        }

        return (
            <div className="form-group row">
                <div className="col-sm-12">
                    <label htmlFor={this.props.name} className="col-form-label font-weight-bold">{this.props.label}</label>
                    {showHelp && (
                        <div className="form-text" style={{ fontSize: '0.8em' }}>
                            {this.props.helpText}
                        </div>
                    )}
                    <input type="text"
                        name={this.props.name}
                        value={this.props.value||''}
                        onChange={this.props.handleChange}
                        data-testid="input"
                        className={'form-control ' + classValidation + ' ' + requiredClass}
                        readOnly={this.props.readOnly}
                        maxLength={this.props.maxLength}
                        placeholder={this.props.placeHolder} />
                    <div className="invalid-feedback" data-testid="message">
                        {this.props.invalid_message}
                    </div>
                </div>
            </div>
        );
    }
}

