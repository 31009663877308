

/**
 * Cerca tra i tag META quello con il valore del token CSRF
 * 
 */
export const csrfToken = ()=>{
  const metas = document.getElementsByTagName('meta');
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === 'csrf-token') {
      return metas[i].getAttribute('content');
    }
  }
  return '';
} 

