import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { csrfToken } from '../common/csrf-loader';


export default class FormSelectOwner extends Component {

    constructor(props) {
        super(props);

        // Recupera il token per l'invio della form
        const token = csrfToken();

        // Crea il riferimento alla form per l'invio via javascript
        this.myForm = React.createRef();

        this.state = {
            token: token,
            owner: '',
            owners: this.props.owners,
            submitDisabled: true,
            data: [],
            loading: true,
            counter: this.props.owners.length,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        await Promise.all(this.props.owners.map((owner) => {
            return this.fetchData(owner);
        }))

        this.setState({
            loading: false,
        });
    }

    fetchData = async (owner) => {
        try {
            const response = await fetch('/pec/get_owner/' + owner.id); // Replace with your route
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            this.setState((currentStatus) => {
                return {
                    data: currentStatus.data.concat(result),
                }
            });

        } catch (error) {
            // provo a richiamare il servizio usando lo stesso OWNER
            this.fetchData(owner);
            // print a console dell'errore
            //console.error('Error:', error);

        }
    };

    /**
     * Metodo che gestisce il cambio di valore nei componenti form.
     * Assegna il valore allo stato, controlla se il bottone di invio deve essere attivato o disattivato.
     *
     * @param {*} event
     */
    handleChange(event) {
        // Recupero nome e valore dall'evento
        const target = event.target;
        const value = target.value;

        if (value == '') {
            this.setState({
                owner: value,
                submitDisabled: true,
            });

        } else {
            this.setState({
                owner: value,
                submitDisabled: false,
            });
        }
    }

    handleSubmit(event) {
        this.myForm.current.submit();
    }

    render() {

        const { data, loading } = this.state;

        return (

            <div className="card mx-auto">
                <div className="card-body">
                    <div className="container">
                        <h4 className="card-title">Dati titolare casella Pec</h4>

                        <form action={this.props.config.url_owner_select} method="post" onSubmit={this.handleSubmit} ref={this.myForm}>
                            <input type="hidden" name="_token" value={this.state.token} />

                            {this.state.owners.length == 0 ? (
                                <h5 className="card-subtitle text-muted mt-5 mb-5">Nessuna anagrafica presente</h5>
                            ) : (
                                <React.Fragment>
                                    <h5 className="card-subtitle text-muted mt-3 mb-4">Seleziona quale anagrafica associare alla tua casella Pec</h5>

                                    {(this.state.counter && this.state.counter > 0) &&
                                        <hr />
                                    }
                                    <div className="form-group">
                                        {/* Contatore anagrafiche trovate */}
                                        {/* {(this.state.counter && this.state.counter > 0)  && <p >
                                            <p>Anagrafiche trovate: {this.state.counter}</p>
                                        </p>} */}
                                        <OptionsOwners
                                            url_owner_modify={this.props.config.url_owner_modify}
                                            owners={this.state.owners}
                                            value={this.state.data}
                                            handleChange={this.handleChange}
                                            non_assegnato={this.props.config.non_assegnato}
                                        />
                                        {this.state.loading && <div className='text-center'>
                                            <div className="spinner-border m-3" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>}
                                    </div>

                                    <div className="form-group mt-4 text-center">
                                        <button type="submit" name="action" value="old" className="btn btn-primary" disabled={this.state.submitDisabled}>
                                            Attiva la casella
                                        </button>
                                    </div>

                                    <div className="form-group mt-3 mb-3 text-center">
                                        oppure
                                    </div>
                                </React.Fragment>
                            )}

                            <div className="form-group text-center">
                                <button
                                    type="submit"
                                    name="action"
                                    value="new"
                                    disabled={this.state.loading}
                                    className="btn btn-primary">
                                    Inserisci nuova anagrafica
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        );
    }

} // fine classe FormBuyProduct

class OptionsOwners extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let arrayOfData = this.props.value;
        let options = arrayOfData.map((data) => {
            if(data.code !== this.props.non_assegnato) {
                return (
                    <div key={data.id}>
                        <div className="form-check text-left mb-2 d-flex justify-content-between">
                            <input className="form-check-input"
                                type="radio"
                                name="owner"
                                id={'owner' + data.id}
                                onChange={this.props.handleChange}
                                value={data.id} />
                            <label className="form-check-label" htmlFor={'owner' + data.id}>
                                {(data.code == this.props.non_assegnato) && (
                                    <React.Fragment>
                                        <span className="badge rounded-pill bg-warning">dati da aggiornare</span>&nbsp;
                                    </React.Fragment>
                                )}
                                <strong>{data.code}</strong> - <strong>{data.name}</strong><br /><small>{data.address}</small>
                            </label>
                            <a href={this.props.url_owner_modify + "/" + data.id} className="ml-2">
                                <button type="button" className="btn btn-outline-secondary py-0 mt-1">MODIFICA</button>
                            </a>
                        </div>
                        <hr className='my-1'></hr>
                    </div>
                );
            } else {
                return;
            }
        });

        return options;
    }

} // Fine classe  OptionsDomain

if (document.getElementById('form_select_owner')) {
    ReactDOM.render(<FormSelectOwner owners={owners} config={config} />, document.getElementById('form_select_owner'));
}
