import React, { Component } from 'react';
import { isValidPassword } from './strength-password';


export default class PasswordInput extends Component {
    constructor(props) {
        super(props);

        const config = props.config;
    }

    render() {

        // Controlla la validità
        var classValidation = '';
        // cambia la classe di validazione
        if (this.props.value === '') {
            // Non mostrare nessun messaggio
            classValidation = '';
        } else if (isValidPassword(this.props.value, this.props.length_min, this.props.length_max)) {
            classValidation = 'is-valid';
        } else {
            classValidation = 'is-invalid';
        }

        var classValidationP2 = '';
        // cambia la classe di validazione per la seconda password
        if (this.props.value2 === '') {
            // Non mostrare nessun messaggio
            classValidationP2 = '';
        } else if (this.props.value === this.props.value2) {
            classValidationP2 = 'is-valid';
        } else {
            classValidationP2 = 'is-invalid';
        }

        return (
            <React.Fragment>
                <div className="form-group">
                    <label htmlFor="password" className="col-form-label font-weight-bold">{config.password}</label>
                    <input type="password"
                        name="password"
                        onChange={this.props.handleChange}
                        value={this.props.value}
                        data-testid="pwd1"
                        className={'form-control mb-2 mr-sm-2 ' + classValidation}
                    />
                    <div className="invalid-feedback">
                        {config.password_rules}
                    </div>
                    <div className="valid-feedback">
                        {config.password_valida}
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="password_confirmation" className="col-form-label font-weight-bold">{config.ripeti_password}</label>
                    <input type="password"
                        name="password_confirmation"
                        onChange={this.props.handleChange}
                        value={this.props.value2}
                        data-testid="pwd2"
                        className={'form-control mb-2 mr-sm-2 ' + classValidationP2}
                    />
                    <div className="invalid-feedback">
                        {config.password_non_coincidenti}
                    </div>
                </div>
            </React.Fragment>
        );
    }

}
